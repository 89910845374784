import { IActorAddress } from './actor-address.model';
import { ISOCountryCode } from './country.model';

export enum IndividualActorType {
  CONTRACTUAL_PARTNER = 'CONTRACTUAL_PARTNER',
  UBO = 'UBO',
}

/**
 * Details of a person that acts as an actor in an order
 */
export interface IIndividualActorDetails {
  /** First name of person */
  firstName: string;
  /** Last name of person */
  lastName: string;
  /** Date of birth in format `yyyy-mm-dd` */
  dateOfBirth: string;
  /** Place of birth */
  placeOfBirth: string;
  /** Nationalities of the person */
  nationalities: ISOCountryCode[];
  /**
   * Optional address of the individual actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship)
   */
  address?: IActorAddress;
  // TODO: Remove properties in https://keb.atlassian.net/browse/KYC-722
  /** Whether the person is the actual contract partner */
  isActualContractPartner: boolean;
  // someone not present will be the owner. E.g perhaps gets a "gift"
  isBeneficialOwner: boolean;
  /** Description text to specify the actual contract partner */
  isActualContractPartnerDescription?: string;
  /** Whether the person is acting on own economical interest */
  isActingOnOwnEconomicalInterest: boolean;
  /** Description text to specify the economical interest */
  isActingOnOwnEconomicalInterestDescription?: string;
  /** Whether the person is not a politically exposed person */
  isNotPep: boolean;
  /** Description text to specify the politically exposed person */
  isNotPepDescription?: string;
  /** If `true` user information has been manually verified by user or a third party * */
  verified?: boolean;
}

/**
 * Details of a person that acts as an actor in an order
 */
export class IndividualActorDetails implements IIndividualActorDetails {
  /** First name of person */
  public firstName: string;

  /** Last name of person */
  public lastName: string;

  /** Date of birth in format `yyyy-mm-dd` */
  public dateOfBirth: string;

  /** Place of birth */
  public placeOfBirth: string;

  /** Nationalities of the person */
  public nationalities: ISOCountryCode[];

  /**
   * Optional address of the individual actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship)
   */
  public address?: IActorAddress;

  /** Whether the person is the actual contract partner */
  public isActualContractPartner = false;

  // someone not present will be the owner. E.g perhaps gets a "gift"
  public isBeneficialOwner = false;

  /** Whether the person is acting on own economical interest */
  public isActingOnOwnEconomicalInterest = true;

  /** Whether the person is not a politically exposed person */
  public isNotPep = true;

  /** Whether user information as been confirmed by user */
  public verified = false;
}
