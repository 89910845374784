import { RouteUrl } from './route.url';

/** Base order details url */
const orderOverviewUrl = ['order', ':orderId', 'overview'];

/** Params for order details urls */
type ORDER_PARAMS = {
  orderId: string;
};

/** All order details urls */
export const ORDER_OVERVIEW_URLS = {
  summary: new RouteUrl<ORDER_PARAMS>([...orderOverviewUrl, 'summary']),
  actorDetails: new RouteUrl<ORDER_PARAMS & { actorId: string }>([...orderOverviewUrl, 'actor', ':actorId']),
  relatedActorDetails: new RouteUrl<ORDER_PARAMS & { actorId: string; relatedActorId: string }>([
    ...orderOverviewUrl,
    'actor',
    ':actorId',
    'relatedActor',
    ':relatedActorId',
  ]),
  documentPreview: new RouteUrl<ORDER_PARAMS & { actorId: string; documentId: string }>([
    ...orderOverviewUrl,
    'actor',
    ':actorId',
    'document',
    ':documentId',
  ]),
  relatedActorDocumentPreview: new RouteUrl<
    ORDER_PARAMS & { actorId: string; relatedActorId: string; documentId: string }
  >([...orderOverviewUrl, 'actor', ':actorId', 'relatedActor', ':relatedActorId', 'document', ':documentId']),
};
