import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserPlugin } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { Toast } from '@capacitor/toast';
import { environment } from '@environment';
import { IActionSheetOption, LibConstants, LoaderOverlayService, Logger } from '@kerberos-compliance/kerberos-fe-lib';
import {
  ICheck,
  IdentMethodType,
  IdentProvider,
  IdentResultPreliminary,
  IOrderFile,
  LOCAL_ID_PREFIX,
  OrderStatus,
  OrderType,
} from '@models';
import { ORDER_URLS } from '@navUrls';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService, OrderService } from '@services';
import { OrderUrl } from '@serviceUrls';
import { ActionSheetOverlayService } from '@shared/components/action-sheet-overlay/action-sheet-overlay.service';
import { AppConfigService } from '@shared/config/app-config.service';
import { ServiceProvider } from '@shared/enums/service-provider.enum';
import { OrderFileService } from '@utilityServices';
import { ToolsService } from '@utilityServices/tools.service';

enum OrderListItemStatus {
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ARCHIVED = 'ARCHIVED',
}

@Component({
  selector: 'app-order-list-item',
  templateUrl: './order-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListItemComponent {
  /** Whether the indicator dot should be shown */
  public isUnread: boolean;

  /** Order file data */
  public orderFile: IOrderFile;

  /** Order status */
  public orderStatus: OrderListItemStatus;

  /** Reference to the order list item status to be able to use it in the template */
  public OrderListItemStatus: typeof OrderListItemStatus = OrderListItemStatus;

  /** Order file that should be displayed */
  @Input()
  set orderData(orderFile: IOrderFile) {
    this.orderFile = orderFile;

    this.isUnread = ToolsService.isOrderUnread(orderFile.order);

    switch (orderFile.order.status) {
      case OrderStatus.SUBMITTED_FOR_REVIEW:
      case OrderStatus.IN_AUTOMATED_REVIEW:
      case OrderStatus.IN_MANUAL_REVIEW:
        this.orderStatus = OrderListItemStatus.PROCESSING;
        break;
      case OrderStatus.INCOMPLETE:
        this.orderStatus = this.checkForPendingIdentResult(orderFile);
        break;
      case OrderStatus.RESULTS_PROVIDED:
        this.orderStatus = this.orderFile.order.isArchived
          ? OrderListItemStatus.ARCHIVED
          : OrderListItemStatus.COMPLETED;
        break;
      default:
        break;
    }
  }

  get isOrderIncomplete() {
    return this.orderFile?.order?.status === OrderStatus.INCOMPLETE;
  }

  get orderFileName() {
    return this.orderService.getOrderTitleFromOrderFile(this.orderFile);
  }

  constructor(
    public readonly LC: LibConstants,
    public readonly orderFileService: OrderFileService,
    private readonly orderService: OrderService,
    private readonly router: Router,
    private readonly authService: AuthService,
    @Inject('BrowserApi') private readonly browserApi: BrowserPlugin,
    private readonly actionSheetOverlayService: ActionSheetOverlayService,
    private readonly translocoService: TranslocoService,
    private readonly loadingOverlayService: LoaderOverlayService,
    private readonly appConfigService: AppConfigService
  ) {}

  /**
   * Checks an incomplete order for a pending ident result
   * @param orderFile Order file
   */
  private checkForPendingIdentResult(orderFile: IOrderFile): OrderListItemStatus {
    const isAnyCheckPending = orderFile.checks.some((check) => check.result === IdentResultPreliminary.REVIEW_PENDING);

    return isAnyCheckPending ? OrderListItemStatus.PENDING : OrderListItemStatus.DRAFT;
  }

  public async showActions(event: Event): Promise<void> {
    event.stopImmediatePropagation();
    const options = this.actionSheetOptions;
    const selectedOption = await this.actionSheetOverlayService.open({
      title: this.orderService.getOrderTitleFromOrderFile(this.orderFile),
      options,
    });
    switch (selectedOption.index) {
      case 0:
        if (await this.userDeleteConfirmation()) {
          await this.deleteOrder();
        }
        break;
      case 1:
        await this.openReportPdf();
        break;
      case 2:
        await this.openOrder();
        break;
      case 3:
        await this.archiveOrder();
        break;
    }
  }

  private get actionSheetOptions(): IActionSheetOption[] {
    return [
      {
        title: this.translocoService.translate('order.actions.deleteOrder'),
        disabled: !this.isOrderDeletable(),
      },
      {
        title: this.translocoService.translate('order.actions.downloadOrder'),
        disabled:
          this.orderFile.order?.status !== OrderStatus.RESULTS_PROVIDED ||
          (this.orderFile.order.type === OrderType.CORPORATE && !this.orderFile.order.hasManualKYCReport),
      },
      {
        title: this.translocoService.translate('order.actions.openOrder'),
      },
      {
        title: this.translocoService.translate('order.actions.archiveOrder'),
        disabled: this.orderFile.order?.status !== OrderStatus.RESULTS_PROVIDED || this.orderFile.order?.isArchived,
      },
    ];
  }

  /**
   * An order is deletable if: it's state is INCOMPLETE and there's no checks performed with VIDEO_IDENT NOR ID_NOW
   */
  private isOrderDeletable(): boolean {
    const isVideoIdent = (check: ICheck) => check.identMethodType === IdentMethodType.VIDEO_IDENT;
    const isIdNow = (check: ICheck) => check.identificationProvider === IdentProvider.ID_NOW;
    const isOrderIncomplete = (orderFile: IOrderFile) => orderFile.order.status === OrderStatus.INCOMPLETE;
    const checks = this.orderFile.checks || [];
    return isOrderIncomplete(this.orderFile) && !checks.some((check) => isVideoIdent(check) || isIdNow(check));
  }

  private async userDeleteConfirmation(): Promise<boolean> {
    const { value } = await Dialog.confirm({
      title: this.translocoService.translate('order.actions.deleteOrderConfirmation.title'),
      message: this.translocoService.translate('order.actions.deleteOrderConfirmation.message'),
    });

    return value;
  }

  private async deleteOrder(): Promise<void> {
    try {
      this.loadingOverlayService.open();
      const { orderId } = this.orderFile.order;
      if (orderId.startsWith(LOCAL_ID_PREFIX)) {
        await this.orderFileService.deleteOrderFile(orderId);
        this.showToastMessage(true);
      } else {
        const { wasDeleted } = await this.orderService.deleteOrder(orderId);
        this.showToastMessage(wasDeleted);
      }
    } catch (e) {
      this.showToastMessage(false);
    } finally {
      this.loadingOverlayService.close();
    }
  }

  private showToastMessage(wasDeleted: boolean): void {
    const text = wasDeleted
      ? this.translocoService.translate('order.actions.deletedEndpointResponse.positive')
      : this.translocoService.translate('order.actions.deletedEndpointResponse.negative');
    Toast.show({ text, duration: 'long' });
  }

  private async openOrder(): Promise<void> {
    const { orderId } = this.orderFile.order;
    await this.router.navigate(ORDER_URLS.detail.getUrlSegments({ params: { orderId } }));
  }

  /**
   * Loads the PDF report and displays it in a new browser window
   */
  private async openReportPdf(): Promise<void> {
    if (this.orderFile.order.status !== OrderStatus.RESULTS_PROVIDED) {
      return;
    }

    const { companyId } = this.orderFile.order;
    const { orderId } = this.orderFile.order;
    const serviceProvider = this.appConfigService.config.isLegeartis
      ? ServiceProvider.Legeartis
      : ServiceProvider.Kerberos;

    const reportUri = `${environment.backendUrl}api/${OrderUrl.getGeneratedReport(companyId, orderId).join('/')}`;
    try {
      this.authService.createSharedSession(reportUri).subscribe(async (authorizedReportUri) => {
        const authorizedReportUriWithTheme = authorizedReportUri + `&theme=${serviceProvider}`;
        // open the window async (needed for compatibility with safari iOS)
        setTimeout(() => {
          window.open(authorizedReportUriWithTheme, '_blank');
        });
      });
    } catch (error) {
      Logger.error(`:: OrderListItemComponent :: openReportPdf :: Failed to open report. [error]: `, error);
    }
  }

  private async archiveOrder(): Promise<void> {
    try {
      this.loadingOverlayService.open();
      const { orderId } = this.orderFile.order;
      const order = await this.orderService.archiveOrder(orderId);
      await this.orderFileService.updateOrderData(orderId, { order }, { deepMerge: true });
      this.showArchiveToastMessage(order.isArchived);
    } catch (e) {
      this.showArchiveToastMessage(false);
    } finally {
      this.loadingOverlayService.close();
    }
  }

  private showArchiveToastMessage(wasDeleted: boolean): void {
    const text = wasDeleted
      ? this.translocoService.translate('order.actions.archivedEndpointResponse.positive')
      : this.translocoService.translate('order.actions.archivedEndpointResponse.negative');
    Toast.show({ text, duration: 'long' });
  }
}
