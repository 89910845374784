import { Directive, HostListener, Input } from '@angular/core';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IRouteParams } from '@navUrls';
import { GraphService } from '@utilityServices';

/**
 * Directive that triggers the given action when clicked
 */
@Directive({
  selector: '[action]',
})
export class ActionDirective {
  /** Action that should be triggered */
  @Input()
  public action: string;

  /** Optional params for url */
  @Input()
  public actionUrlParams: IRouteParams;

  /** Click listener that triggers the action */
  @HostListener('click')
  public handleClick(): void {
    if (typeof this.actionUrlParams === 'string') {
      Logger.error(`'ACTION DIRECTIVE ERROR: Url params '${this.actionUrlParams}' are invalid'`);
      this.actionUrlParams = null;
    }

    this.graphService.triggerAction(this.action, this.actionUrlParams).catch();
  }

  constructor(private readonly graphService: GraphService) {}
}
