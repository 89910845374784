import { IActorAddress } from './actor-address.model';
import { IActor } from './actor.model';
import { CorporateLegalType } from './corporate-legal-type.model';
import { IPoliticallyExposedPerson } from './politically-exposed-person.model';

/**
 * Details of a corporate that acts as an actor in a order
 */
export interface ICorporateActorDetails {
  /** Name of the corporate */
  name: string;
  /** Legal type of the corporate */
  legalType: CorporateLegalType;
  /** Other Legal type (optional) */
  otherLegalType?: string;
  /** Registration number */
  registrationNumber: string;
  /** VAT number */
  vatNumber: string;
  /** Tax id number */
  taxIdNumber: string;
  /** Purpose of the corporate */
  corporatePurpose: string;
  /** Date when the corporate was founded */
  foundationDate: string;
  /** City the corporate was founded in */
  foundationCity: string;
  /**
   * Optional address of the corporate actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship).
   */
  address?: IActorAddress;
  /** Id of the actor that is the actual contract partner */
  actualContractPartnerActorId: string;
  /** Actor that is the actual contract partner */
  actualContractPartner?: IActor;
  /** List of politically exposed persons that are related to the corporate */
  politicallyExposedPersons: IPoliticallyExposedPerson[];
  /** Free text for internal notes */
  internalNotice: string;
}

/**
 * Details of a corporate that acts as an actor in a order
 */
export class CorporateActorDetails implements ICorporateActorDetails {
  /** Name of the corporate */
  public name: string;
  /** Legal type of the corporate */
  public legalType: CorporateLegalType;
  /** Other Legal type (optional) */
  public otherLegalType?: string;
  /** Registration number */
  public registrationNumber: string;
  /** VAT number */
  public vatNumber: string;
  /** Tax id number */
  public taxIdNumber: string;
  /** Purpose of the corporate */
  public corporatePurpose: string;
  /** Date when the corporate was founded */
  public foundationDate: string;
  /** City the corporate was founded in */
  public foundationCity: string;
  /**
   * Optional address of the corporate actor.
   * Do not use it for the main actor! Instead, use the address in the check of the order (actor to order relationship)
   */
  public address?: IActorAddress;
  /** Id of the actor that is the actual contract partner */
  public actualContractPartnerActorId: string;
  /** Actor that is the actual contract partner */
  public actualContractPartner: IActor;
  /** List of politically exposed persons that are related to the corporate */
  public politicallyExposedPersons: IPoliticallyExposedPerson[];
  /** Free text for internal notes */
  public internalNotice: string;
}
