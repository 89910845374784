import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { IModalComponent, IModalResult } from './modal.types';

/**
 * Service to deal with the action sheet overlay state
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private readonly overlay: Overlay) {}

  public open<ModalComponent extends IModalComponent<IModalResult, ModalOptions>, ModalOptions = unknown>(
    componentType: ComponentType<ModalComponent>,
    modalConfig: Partial<OverlayConfig> = {},
    modalOptions: ModalOptions = null
  ): Promise<IModalResult> {
    return new Promise<IModalResult>((resolve) => {
      // Returns an OverlayRef (which is a PortalHost)
      const overlayConfig = { ...this.getOverlayConfig(this.overlay), ...modalConfig };
      const overlayRef = this.overlay.create(overlayConfig);

      // Create ComponentPortal that can be attached to a PortalHost
      const componentPortal = new ComponentPortal(componentType);
      // Attach ComponentPortal to PortalHost
      const componentRef = overlayRef.attach(componentPortal);
      const component = componentRef.instance;
      component.modalOptions = modalOptions;

      overlayRef.backdropClick().subscribe(async () => {
        await component.onBackdrop();
        overlayRef.detach();
        resolve({ aborted: true });
      });

      component.closed.subscribe((result: IModalResult) => {
        overlayRef.detach();
        resolve({ ...result, aborted: false });
      });
    });
  }

  private getOverlayConfig(overlay: Overlay): OverlayConfig {
    const positionStrategy = new GlobalPositionStrategy();
    positionStrategy.left(`${document.body.offsetWidth}px`);
    positionStrategy.centerHorizontally();
    positionStrategy.centerVertically();
    positionStrategy.apply();
    return new OverlayConfig({
      positionStrategy,
      disposeOnNavigation: true,
      hasBackdrop: true, // Should close when user clicks
      scrollStrategy: overlay.scrollStrategies.block(),
      panelClass: `keb-modal`,
      height: '100vh',
      width: '100vw',
    });
  }
}
