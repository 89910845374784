import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { CapacitorPlatform, KebRouteData } from '@models';

/**
 * Services that updates the style of the status bar depending on route settings
 */
@Injectable({
  providedIn: 'root',
})
export class AppStatusbarService {
  /** Simple element that covers the content around the notch and behind the status bar */
  private notchCoverElement: HTMLDivElement;

  /** Because the renderer cannot be injected directly, we have to create one and save the reference */
  private readonly renderer: Renderer2;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Initiates the statusbar service
   */
  public init(): void {
    // If capacitor plugin is not available do nothing
    if (!Capacitor.isPluginAvailable('StatusBar')) {
      return;
    }

    // Check if the current platform is Android -> due to a bug the app cannot run in overlay mode (status bar is placed on top of the content).
    // See the todo below for more information
    const isAndroid = Capacitor.getPlatform() === CapacitorPlatform.ANDROID;

    // Create element that is placed behind the status bar/notch to cover scrolling content
    this.notchCoverElement = this.renderer.createElement('div');
    this.renderer.addClass(this.notchCoverElement, 'keb-navbar-notch');
    this.renderer.appendChild(document.body, this.notchCoverElement);

    // Subscribe to router events to update statusbar style depending on colors on each page (can be set via `data` in the routing files)
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel), // Only on end or cancel of navigation
        map(() => this.activatedRoute), // Inject the activated route
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'), // Only primary router outlets will be checked
        mergeMap((route) => route.data)
      )
      .subscribe((data: KebRouteData) => {
        // TODO: (Steffen) Can be removed when https://github.com/apache/cordova-plugin-statusbar/pull/134 is merged.
        //  The MR adds a function to calculate the statusbar height -> not working `env(safe-area-inset-top)` can be fixed in SCSS.
        //  Until then the style of the status bar should be the system default
        if (isAndroid) {
          return;
        }

        StatusBar.setStyle({ style: data?.statusBarOptions?.statusBarStyle || Style.Light }).catch();

        if (data?.statusBarOptions?.hideNotchCover) {
          this.renderer.addClass(this.notchCoverElement, 'keb-navbar-notch--hidden');
          return;
        }

        this.renderer.removeClass(this.notchCoverElement, 'keb-navbar-notch--hidden');
        this.renderer.setStyle(
          this.notchCoverElement,
          'background-color',
          data?.statusBarOptions?.notchCoverColor || 'white'
        );
      });
  }
}
