/** Type definition for urls */
export type KebUrl = (string | number)[];

/**
 * Available backend services
 */
export enum BackendService {
  AUTH = 'auth',
  CUSTOMER_SERVICE = 'customer-service',
  STORAGE_SERVICE = 'storage-service',
}

/**
 * The BasicUrl provides a default api version and a function to get the complete url with version number
 */
export abstract class BasicUrl {
  /**
   * Default API version
   * Can be overwritten by any inheriting class
   */
  protected static apiVersion: number = 1;
  /** Prefix of API version */
  private static readonly versionPrefix: string = 'v';

  /**
   * Returns the complete url with API version
   * @param service Backend service that is used for this request
   * @param url Url
   * @param apiVersion Version of API if deviating from default API version
   */
  protected static getCompleteUrl(service: BackendService, url: string | KebUrl, apiVersion?: number): KebUrl {
    if (Array.isArray(url)) {
      return [this.versionPrefix + (apiVersion || this.apiVersion), service, ...url];
    } else {
      return [this.versionPrefix + (apiVersion || this.apiVersion), service, url];
    }
  }
}
