import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { PUBLIC_URLS } from '@navUrls';

export abstract class BaseResolver<T> implements Resolve<T> {
  protected constructor(protected readonly router: Router, private readonly implementation: string) {}

  abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> | Promise<T> | T;

  protected async handleError(error) {
    Logger.error(`:: ${this.implementation} :: [error]: ${JSON.stringify(error)}`);
    if (error?.code === 404) {
      await this.navigateToNotFound();
    } else if (!this.router.navigated) {
      await this.navigateToRoot();
    }
  }

  protected navigateToRoot() {
    return this.router.navigate(['']);
  }

  protected navigateToNotFound(skipLocationChange = true) {
    return this.router.navigate(PUBLIC_URLS.page404.getUrlSegments(), { skipLocationChange });
  }
}
