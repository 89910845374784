// SEE: https://capacitorjs.com/docs/guides/mocking-plugins
// tslint:disable-next-line:variable-name
export const Dialog = {
  async alert(options: unknown): Promise<void> {
    return Promise.resolve(undefined);
  },

  async confirm(options: unknown): Promise<{ value: boolean }> {
    return Promise.resolve({ value: true });
  },

  async prompt(options: unknown): Promise<undefined> {
    return Promise.resolve(undefined);
  },
};
