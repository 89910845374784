/**
 * @see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @see: https://mimetype.io
 */
export enum MIME_TYPE {
  PDF = 'application/pdf',
  GIF = 'image/gif',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  CSV = 'text/csv',
  PLAIN_TEXT = 'text/plain',
  MP4 = 'video/mp4',
  XML = 'application/xml',
  ZIP = 'application/zip',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
}
