import { PlatformLocation } from '@angular/common';
import { ApplicationRef, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environment';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { deepCopy } from '@shared/utils';
import { isLegeartis } from '@shared/utils/app.utils';
import deepmerge from 'deepmerge';
import { appConfig, legeartisAppConfig } from 'environments/app-config';
import { ITranslations, translationTexts } from 'environments/i18n';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IFirebaseRemoteConfig } from './app-config.types';

@Injectable({
  providedIn: 'root',
  useFactory: AppConfigService.singleton,
  deps: [ApplicationRef, PlatformLocation],
})
export class AppConfigService {
  private readonly _fireBaseRemoteConfig: BehaviorSubject<IFirebaseRemoteConfig>;

  private readonly _translations: BehaviorSubject<ITranslations>;

  private static _instance;

  private readonly defaultConfig: IFirebaseRemoteConfig;

  private readonly defaultTranslations: ITranslations;

  public static get instance(): AppConfigService {
    return this._instance;
  }

  get config(): IFirebaseRemoteConfig {
    return this._fireBaseRemoteConfig.value;
  }

  get config$(): Observable<IFirebaseRemoteConfig> {
    return this._fireBaseRemoteConfig;
  }

  get translations(): ITranslations {
    return this._translations.value;
  }

  get translations$(): Observable<ITranslations> {
    return this._translations;
  }

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly pl: PlatformLocation,
    private readonly firebase: FirebaseX
  ) {
    // adjust defaultConfig if isLegeartis
    let baseConfig = appConfig;
    if (isLegeartis(this.pl.hostname)) {
      baseConfig = legeartisAppConfig;
    }
    this.defaultConfig = {
      ...deepCopy(baseConfig),
      env: environment.env,
      production: environment.production,
      loginUrl: environment.loginUrl,
      redirectUrl: environment.redirectUrl,
      backendUrl: environment.backendUrl,
      sso: deepCopy(environment.sso),
    };
    this.defaultTranslations = translationTexts;
    this._fireBaseRemoteConfig = new BehaviorSubject(this.defaultConfig);
    this._translations = new BehaviorSubject(this.defaultTranslations);
    this.setUpFirebaseSettings();
  }

  public static singleton(ref: ApplicationRef, pl: PlatformLocation, firebaseX: FirebaseX = null): AppConfigService {
    if (!AppConfigService._instance) {
      const firebaseXSingleton = firebaseX ?? new FirebaseX();
      AppConfigService._instance = new AppConfigService(ref, pl, firebaseXSingleton);
    }

    return AppConfigService._instance;
  }

  public async fetchRemoteConfig(): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    try {
      await this.firebase.fetch(0);
      await this.firebase.activateFetched();
      await this.updateConfig();
      await this.updateTranslations();
    } catch (err) {
      Logger.error(`FETCHING FB CONFIG: ${JSON.stringify(err)}`);
      throw err;
    }
  }

  private async updateConfig(): Promise<void> {
    try {
      await this.publishJsonConfig(this._fireBaseRemoteConfig, this.defaultConfig, 'config');
    } catch (error) {
      Logger.error('failed updating appConfig', error);
      throw error;
    }
  }

  private async updateTranslations(): Promise<void> {
    try {
      await this.publishJsonConfig(this._translations, this.defaultTranslations, 'texts');
    } catch (error) {
      Logger.error('failed updating translations', error);
      throw error;
    }
  }

  private async publishJsonConfig(
    subject: Subject<unknown>,
    defaultState: Record<string, unknown>,
    firebaseKey: string
  ): Promise<void> {
    const firebaseValue = await this.firebase.getValue(firebaseKey);
    if (firebaseValue === '' || !firebaseValue) {
      return;
    }
    const remoteConfig = JSON.parse(firebaseValue);
    const newState = deepmerge(deepCopy(defaultState), remoteConfig);
    subject.next(newState);
    this.appRef.components.forEach((c) => c.changeDetectorRef.markForCheck());
  }

  private setUpFirebaseSettings(): void {
    this.config$.subscribe(async (config) => {
      if (!config.enableAnalytics) {
        Logger.info('Analytics was disabled', config.enableAnalytics);
        return;
      }
      await this.firebase.setAnalyticsCollectionEnabled(true);
      await this.firebase.setCrashlyticsCollectionEnabled(true);
      await this.firebase.setPerformanceCollectionEnabled(true);
    });
  }
}
