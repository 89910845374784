import { ElementRef, Injectable } from '@angular/core';
import { BffService } from '@http/bff.service';
import { MIME_TYPE, mimeTypesFileExtensions } from '@models';
import { FileUrl } from '@services/service-urls/file.url';
import { map } from 'rxjs/operators';

/**
 * Service for some general file functions
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly bffService: BffService) {}

  /**
   * Opens the native file picker to select an existing PDF file
   */
  public async selectFile(fileInput: ElementRef<HTMLInputElement>): Promise<File> {
    // Trigger a cancel event to resolve possible previous Promise
    fileInput.nativeElement.dispatchEvent(new CustomEvent('cancel'));
    FileService.removeInputEventListeners(fileInput.nativeElement);

    await new Promise<void>((resolve) => {
      fileInput.nativeElement.addEventListener('change', () => {
        resolve();
      });
      fileInput.nativeElement.addEventListener('cancel', () => {
        resolve();
      });
      fileInput.nativeElement.click();
    });

    // If the file input was canceled, the file array is empty
    if (fileInput.nativeElement.files.length === 0) {
      return null;
    }

    // Take the first and only file from the file list
    const file: File = fileInput.nativeElement.files.item(0);

    // Remove event listeners, because we do not need the events anymore
    FileService.removeInputEventListeners(fileInput.nativeElement);

    return file;
  }

  /**
   * Removes all event listeners of the file input by cloning the fileInput without the event listeners
   */
  private static removeInputEventListeners(fileInput: HTMLInputElement): void {
    fileInput?.replaceWith(fileInput?.cloneNode(true));
  }

  /**
   * Verifies if the given file type is one of the allowed ones and for that file type verifies if the file extension
   * also matches.
   * Examples:
   * 1. Given a pdf file expects the file type to be 'application/pdf' and the extension to be '.pdf'.
   * 2. Given a jpeg image expects the file type to be 'image/jpeg' and the extension to be either '.jpg' or '.jpeg'.
   */
  public static isFileValid(file: File, allowedMimeTypes: MIME_TYPE[]): boolean {
    return allowedMimeTypes.some(
      (mimeType) =>
        file.type === mimeType &&
        mimeTypesFileExtensions[mimeType].some((fileExtension) => file.name.endsWith(`.${fileExtension.toLowerCase()}`))
    );
  }

  public static async convertBlobToBase64(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  public static b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    b64Data = b64Data.replace(`data:${contentType};base64,`, '');

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public uploadImage(base64File: string, name: string, ext: string): Promise<string> {
    const formData = new FormData();

    const blobData = FileService.b64toBlob(base64File, `image/${ext}`);

    formData.append('file', blobData, `${name}.${ext}`);
    formData.append('name', name);

    return this.sendBinaryFile(formData);
  }

  public uploadFile(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    return this.sendBinaryFile(formData);
  }

  /**
   *
   * @param content form data content
   * @param url specific endpoint to request. It will call file controller as default
   */
  public async sendBinaryFile(content: FormData, url?: string): Promise<string> {
    return this.bffService
      .post({ url: url || FileUrl.upload(), body: content })
      .pipe(map((response) => response.data as string))
      .toPromise();
  }

  /**
   * Converts a given base64 string to an objectUrl
   * @param base64 Base64 string
   */
  public static convertBase64ToObjectUrl(base64: string, type: MIME_TYPE = MIME_TYPE.PDF): string {
    const index = base64.indexOf(',') + 1;
    const base64String = base64.substring(index);

    const byteCharacters = atob(base64String);
    const byteNumbers: number[] = Array.from({ length: byteCharacters.length });

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const data = new Blob([byteArray], { type });
    return window.URL.createObjectURL(data);
  }
}
