import { GraphEdge } from './graph-edge';
import { GraphNode } from './graph.node';
import { GraphNodeId, IGraphEdgeData, IGraphNodeData } from './graph.types';

/**
 * Graph
 */
export class Graph {
  /** List of all nodes in the graph */
  private readonly nodes: Map<GraphNodeId, GraphNode> = new Map<GraphNodeId, GraphNode>();

  /** List of all edges in the graph */
  private readonly edges: Map<GraphNodeId, GraphEdge[]> = new Map<GraphNodeId, GraphEdge[]>();

  /**
   * Adds an node to the graph
   * @param id Id of the node
   * @param data Data that is stored in the node
   */
  public addNode(id: GraphNodeId, data: IGraphNodeData): GraphNode {
    const node = new GraphNode(id, data, this);

    this.nodes.set(id, node);
    // Initializes the array of edges for the new node
    this.edges.set(id, []);

    return node;
  }

  /**
   * Adds edge between two nodes
   * @param startNodeId Id of starting node
   * @param endNodeId Id of ending node
   * @param data Data that is stored in the edge
   */
  public addEdge(startNodeId: GraphNodeId, endNodeId: GraphNodeId, data: IGraphEdgeData): void {
    this.edges.set(startNodeId, [...this.edges.get(startNodeId), new GraphEdge(startNodeId, endNodeId, data, this)]);
  }

  /**
   * Returns the graph in the standardized DOT format
   * @url https://en.wikipedia.org/wiki/DOT_(graph_description_language)
   */
  public getDotFormat(): string {
    let dotString = 'dinetwork {';

    Array.from(this.nodes.entries()).forEach(([id, node]) => {
      dotString += '\n';
      dotString += `  "${id}" [label="${node.data.label}"]\n`;

      const edges = this.edges.get(id);

      edges.forEach((edge) => {
        dotString += `    "${id}" -> "${edge.endId}" [label="${edge.data.label}"];\n`;
      });
    });

    dotString += `}`;

    return dotString;
  }
}
