import { NgModule } from '@angular/core';
import {
  KebActionSheetModule,
  KebButtonModule,
  KebCardButtonModule,
  KebCardItemModule,
  KebCheckboxModule,
  KebChipModule,
  KebHeaderModule,
  KebHighlightPipeModule,
  KebIconListItemModule,
  KebIconModule,
  KebIncludeFilterPipeModule,
  KebInfoboxModule,
  KebInputModule,
  KebListItemModule,
  KebLoaderModule,
  KebLuxonPipeModule,
  KebMenuModule,
  KebSearchInputModule,
  KebSelectModule,
  KebSignatureModule,
  KebToggleModule,
} from '@kerberos-compliance/kerberos-fe-lib';

/** List of library modules that should be provided. It is exported so it can be used by the testing shared module */
export const LIB_MODULES = [
  KebActionSheetModule,
  KebButtonModule,
  KebCardButtonModule,
  KebCardItemModule,
  KebCheckboxModule,
  KebChipModule,
  KebHeaderModule,
  KebHighlightPipeModule,
  KebIconListItemModule,
  KebIconModule,
  KebIncludeFilterPipeModule,
  KebInfoboxModule,
  KebInputModule,
  KebListItemModule,
  KebLoaderModule,
  KebLuxonPipeModule,
  KebMenuModule,
  KebSearchInputModule,
  KebSelectModule,
  KebSignatureModule,
  KebToggleModule,
];

@NgModule({
  imports: LIB_MODULES,
  exports: LIB_MODULES,
})
export class LibModule {}
