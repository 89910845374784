import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

import { OrderService, UserService } from '@services';
import { OrderFileService } from '@utilityServices';
import { BaseOrderGuard } from './base-order.guard';
import { OrderGraphPathResolver } from './utility/order-graph-path-resolver.service';

/**
 * Guard for basic order route of the OrderComponent
 */
@Injectable()
export class OrderGuard extends BaseOrderGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(
    protected readonly orderFileService: OrderFileService,
    protected readonly orderService: OrderService,
    protected readonly userService: UserService,
    protected readonly router: Router,
    protected readonly graphPathResolver: OrderGraphPathResolver
  ) {
    super(orderFileService, orderService, userService, router, graphPathResolver);
  }

  /**
   * Checks if given route can be visited
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // The order guard is triggered if the user navigates from any page to any order page. This happens before the OrderDetailsGuard is triggered.
    // The previous order url is set to undefined, because the user comes from a non-order page
    BaseOrderGuard.previousOrderPageUrl = undefined;

    return this.checkOrder(route, state);
  }

  /**
   * If the order flow is left, reset the current order id
   */
  public async canDeactivate(): Promise<boolean> {
    this.orderService.setCurrentOrderId(null);
    await this.orderFileService.setCurrentOrderId(null).catch();
    return true;
  }
}
