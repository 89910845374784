import { IActorAddress } from './actor-address.model';
import { IActorDocument } from './actor-document.model';
import { IActor } from './actor.model';
import { IMetaModel } from './meta.model';
import { IOrder } from './order.model';

/**
 * Final ident results
 */
export enum IdentResultFinal {
  SUCCESS = 'SUCCESS',
  SUCCESS_DATA_CHANGED = 'SUCCESS_DATA_CHANGED',
  FRAUD_SUSPICION_CONFIRMED = 'FRAUD_SUSPICION_CONFIRMED',
  ABORTED = 'ABORTED',
  CANCELED = 'CANCELED',
}

/**
 * Preliminary ident results
 */
export enum IdentResultPreliminary {
  REVIEW_PENDING = 'REVIEW_PENDING',
  FRAUD_SUSPICION_PENDING = 'FRAUD_SUSPICION_PENDING',
  CHECK_PENDING = 'CHECK_PENDING',
}

/**
 * Available ident providers
 */
export enum IdentProvider {
  ID_NOW = 'ID_NOW',
}

/**
 * Available ident methods
 */
export enum IdentMethodType {
  AUTO_IDENT = 'AUTO_IDENT',
  VIDEO_IDENT = 'VIDEO_IDENT',
}

export const NO_IDENT = 'NO_IDENT';

export enum IDNowCheckResult {
  SUCCESS = 'SUCCESS',
  SUCCESS_DATA_CHANGED = 'SUCCESS_DATA_CHANGED',
  FRAUD_SUSPICION_CONFIRMED = 'FRAUD_SUSPICION_CONFIRMED',
  ABORTED = 'ABORTED',
  REVIEW_PENDING = 'REVIEW_PENDING',
  FRAUD_SUSPICION_PENDING = 'FRAUD_SUSPICION_PENDING',
}

/**
 * Check that stands for the relationship between an actor and an order
 */
export interface ICheck extends IMetaModel {
  /** Id of the check */
  checkId: string;
  /** Actor that is related to the order */
  actor: IActor;
  /** List of linked documents */
  documents: IActorDocument[];
  /** Address of the actor */
  actorAddress: IActorAddress;

  email?: string;

  // IDENT DATA
  /** id of external ident */
  externalIdentId?: string;
  /** Number of the transaction */
  transactionNumber?: string;
  /** Link to the ident process */
  identLink?: string;
  /** Ident result */
  result?: IdentResultFinal | IdentResultPreliminary;
  /** Provider of the ident process */
  identificationProvider?: IdentProvider;
  /** Method type of the ident */
  identMethodType?: IdentMethodType;
  /** Data that was extracted during the ident process */
  extractedIdentData?: any;
  order: IOrder;
}
