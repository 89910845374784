import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { BackgroundColor, KebRouteData } from '@models';

/**
 * This service extracts the desired background color for the body element from the route data and updates the CSS color variable
 */
@Injectable({
  providedIn: 'root',
})
export class AppBackgroundColorService {
  /** Because the renderer cannot be injected directly, we have to create one and save the reference */
  private readonly renderer: Renderer2;
  /** Current background color. Used to prevent DOM update when color has not changed */
  private currentBackgroundColor: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Initiates the statusbar service
   */
  public init(): void {
    // Create a style element of which the content can be changed every time the background color changes
    // This approach was chosen over changing the CSS variable directly,
    // since changing it via JS would appear as a style on the body element: `<body style="--background-color: var(--white)">`
    const styleElement: HTMLStyleElement = this.renderer.createElement('style');
    this.renderer.appendChild(this.document.head, styleElement);

    // Subscribe to router events to update statusbar style depending on colors on each page (can be set via `data` in the routing files)
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationCancel), // Only on end or cancel of navigation
        map(() => this.activatedRoute), // Inject the activated route
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'), // Only primary router outlets will be checked
        mergeMap((route) => route.data)
      )
      .subscribe((data: KebRouteData) => {
        const backgroundColor = data.backgroundColor || BackgroundColor.WHITE;

        // If current background color has not changed, stop here
        if (this.currentBackgroundColor === backgroundColor) {
          return;
        }
        this.currentBackgroundColor = backgroundColor;

        let innerHTML = `\n`;
        innerHTML += `  :root {\n`;
        innerHTML += `    --background-color: var(--${backgroundColor.toLowerCase().replace('_', '-')});\n`;
        innerHTML += `  }\n`;

        // Replace old content with new background color definition
        styleElement.innerHTML = innerHTML;
      });
  }
}
