import { Dict } from '../types';

type AnyType = string | number | object | boolean;
export function toMergedObject<T = AnyType>(mergedObj: Dict, value: T): Dict<T> {
  return { ...mergedObj, ...value } as Dict<T>;
}

export function deepCopy<T extends object>(data: T): T {
  let copy: object;
  try {
    copy = JSON.parse(JSON.stringify(data));
  } catch (e) {
    return data;
  }
  return copy as T;
}

export function isNestedArray(obj: Record<string, any>) {
  return Array.isArray(obj) && obj.length > 0 && obj[0] !== null && typeof obj[0] === 'object';
}

export function isNestedObject(obj: Record<string, any>) {
  return obj !== null && typeof obj === 'object';
}
