import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IActorDocument, ICheck, IOrder, LOCAL_ID_PREFIX } from '@models';

/**
 * Return type of the merge functions
 */
interface IMergeResponse<T> {
  data: T;
  success: boolean;
}

/**
 * Merger for order related data
 */
export class OrderMerger {
  /**
   * Merges the basic order data (excluding the nested checks data)
   * @param existingOrder The existing order data
   * @param newOrder The new order data
   */
  public static mergeOrder(existingOrder: IOrder, newOrder: IOrder): IMergeResponse<IOrder> {
    // If one of the orders are not present, cancel merging
    if (!existingOrder || !newOrder) {
      return { data: existingOrder, success: false };
    }

    // If the order ids are not the same
    if (existingOrder.orderId !== newOrder.orderId) {
      return { data: existingOrder, success: false };
    }

    // Replace the checks array with
    return { data: OrderMerger.getDeepCopy({ ...newOrder, checks: existingOrder.checks }), success: true };
  }

  /**
   * Merges the checks of the locally existing data with the new given data.
   * Not submitted checks are kept. Checks that are not included in the new array are removed from the local storage (not LocalStorage)
   * @param existingChecks Locally existing checks data
   * @param newChecks New checks data
   */
  public static mergeChecks(existingChecks: ICheck[], newChecks: ICheck[]): IMergeResponse<ICheck[]> {
    // If one of the checks arrays is not present, cancel merging
    if (!Array.isArray(existingChecks) || !Array.isArray(newChecks)) {
      return { data: existingChecks, success: false };
    }

    // Save local stored but not submitted order data to append these checks after merging
    const localChecks: ICheck[] = existingChecks.filter((check) => check.checkId.startsWith(LOCAL_ID_PREFIX));

    for (const newCheck of newChecks) {
      const existingCheck: ICheck = existingChecks.find((check) => check.checkId === newCheck.checkId);

      // If no matching check is found, no further merging is needed for this check
      if (!existingCheck) {
        continue;
      }

      // Merge the documents of the check
      const { data, success }: IMergeResponse<IActorDocument[]> = OrderMerger.mergeDocuments(
        existingCheck.documents ?? [],
        newCheck.documents ?? []
      );

      // If a document merge is not successful, stop here and return the unmodified existing checks
      if (!success) {
        return { data: existingChecks, success: false };
      }

      // Replace the documents of the new check with the merged documents data
      newCheck.documents = data;
      existingCheck.result = newCheck.result;
    }

    return { data: OrderMerger.getDeepCopy([...newChecks, ...localChecks]), success: true };
  }

  /**
   * Merges given documents
   * @param existingActorDocuments Locally existing actor documents
   * @param newActorDocuments New actor documents
   */
  private static mergeDocuments(
    existingActorDocuments: IActorDocument[],
    newActorDocuments: IActorDocument[]
  ): IMergeResponse<IActorDocument[]> {
    // If one of the document arrays is not present, cancel merging
    if (!Array.isArray(existingActorDocuments) || !Array.isArray(newActorDocuments)) {
      return { data: existingActorDocuments, success: false };
    }

    // Save local stored but not submitted document data to append these documents after merging
    const localDocuments: IActorDocument[] = existingActorDocuments.filter((document) =>
      document?.documentId?.startsWith(LOCAL_ID_PREFIX)
    );

    for (const newDocument of newActorDocuments) {
      const existingActorDocument = existingActorDocuments.find(
        (existingDocument) =>
          // Check if ids are not undefined and equal
          !!existingDocument?.documentId &&
          !!newDocument?.documentId &&
          existingDocument.documentId === newDocument.documentId
      );

      // If no matching document is found, no further merging is needed for this document
      if (!existingActorDocument) {
        continue;
      }

      if (existingActorDocument?.updatedAt === newDocument?.updatedAt) {
        // If updated at date has not changed, append the locally existing files to the new document
        newDocument.files = existingActorDocument?.files || [];
      } else {
        // Otherwise discard files data
        newDocument.files = [];
      }
    }

    return { data: OrderMerger.getDeepCopy([...newActorDocuments, ...localDocuments]), success: true };
  }

  /**
   * Returns a deep copy (copy without references) of the given object
   * @param data Data that should be deep copied
   */
  private static getDeepCopy<T extends object>(data: T): T {
    let copy: object;
    try {
      copy = JSON.parse(JSON.stringify(data));
    } catch (e) {
      Logger.error('getDeepCopy', { e });
      return data;
    }
    return copy as T;
  }
}
