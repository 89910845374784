import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { KebThemeService, Logger, Themes } from '@kerberos-compliance/kerberos-fe-lib';
import { TranslocoService } from '@ngneat/transloco';
import { AppConfigService } from '@shared/config/app-config.service';
import { PushNotificationService, TranslocoStatusService } from '@utilityServices';
import { ITranslations } from 'environments/i18n';
import { filter, take } from 'rxjs/operators';
import { AppBackgroundColorService } from './app-background-color.service';
import { AppStatusbarService } from './app-statusbar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  constructor(
    private readonly themeService: KebThemeService,
    private readonly router: Router,
    private readonly appStatusbarService: AppStatusbarService,
    private readonly appBackgroundColorService: AppBackgroundColorService,
    private readonly translocoService: TranslocoService,
    private readonly translocoStatusService: TranslocoStatusService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly appConfigService: AppConfigService
  ) {
    this.subscribeRemoteConfig();

    // Subscribe to events of the transloco service here to get all events and
    // update the status in the TranslocoStatusService which is then provided as BehaviorSubject
    this.translocoService.events$.subscribe((event) => {
      this.translocoStatusService.setStatus(false, event.payload.langName);
    });

    this.appStatusbarService.init();

    this.appBackgroundColorService.init();

    this.pushNotificationService.settings$
      .pipe(
        filter((pushNotificationsServiceSettings) => !!pushNotificationsServiceSettings),
        take(1)
      )
      .subscribe((pushNotificationsServiceSettings) => {
        // If the user has already been asked for permissions for push notifications,
        // we can now request a permission on every app launch.
        // If the user has not been asked yet, the permission will be requested later at an appropriate time,
        // since a confirmation dialog pops up the first time and this would be annoying at app startup.
        if (pushNotificationsServiceSettings.askedForPermission) {
          this.pushNotificationService.requestPermission().catch();
        }
      });
  }

  /**
   * Hide the splash screen when app is fully loaded
   */
  public ngAfterViewInit(): void {
    SplashScreen.hide().catch();
  }

  private subscribeRemoteConfig(): void {
    this.appConfigService.config$.subscribe((config) => {
      const themeToUse = config.isLegeartis ? Themes.Legeartis : Themes.Kerberos;

      this.themeService.setActive(themeToUse);
      this.themeService.patchBodyClasses();
    });
    this.appConfigService.translations$.subscribe((updatedTranslations) => {
      this.patchTranslations(updatedTranslations);
    });
  }

  private patchTranslations(translations: ITranslations): void {
    if (!translations) {
      return;
    }
    Object.keys(translations).forEach((langKey) => {
      Logger.info('patched lang from remoteConfig', langKey);
      this.translocoService.setTranslation(translations[langKey], langKey, {
        merge: true,
        emitChange: true,
      });
    });
  }
}
