import { IdentMethodType } from "@models";
import { AUTO_IDENT_URLS, IDENT_URLS, VIDEO_IDENT_URLS } from "@shared/urls/ident.urls";
import { RouteUrl } from "./route.url";

/** Base order url */
const orderUrl = ['order'];
const orderIdUrl = [...orderUrl, ':orderId'];
const uboUrl = [...orderIdUrl, 'ubo'];
const documentsUrl = [...orderIdUrl, 'documents'];
const continuousPartnershipQuestionnaireUrl = [...orderIdUrl, 'continuousPartnershipQuestionnaire'];
const partnershipStatusUrl = [...orderIdUrl, 'partnershipStatus'];
const corporateUrl = [...orderIdUrl, 'corporate'];

/** Params for order urls */
type OrderParams = {
  orderId: string;
};

/** All order urls */
export const ORDER_URLS = {
  // Order
  order: new RouteUrl(orderUrl),
  create: new RouteUrl([...orderUrl, 'create']),
  detail: new RouteUrl<OrderParams>(orderIdUrl),

  // Ident
  ident: new RouteUrl<OrderParams>([...orderIdUrl, 'ident']),

  // UBO
  ubo: new RouteUrl<OrderParams>([...uboUrl]),
  uboList: new RouteUrl<OrderParams>([...uboUrl, 'list']),
  uboCreate: new RouteUrl<OrderParams>([...uboUrl, 'create']),

  // Additional Documents
  documents: new RouteUrl<OrderParams>([...documentsUrl]),
  documentList: new RouteUrl<OrderParams>([...documentsUrl, 'list']),
  documentAdd: new RouteUrl<OrderParams>([...documentsUrl, 'add']),

  // continuous relationship
  continuousPartnershipQuestionnaire: new RouteUrl<OrderParams>([...continuousPartnershipQuestionnaireUrl]),
  continuousPartnership: new RouteUrl<OrderParams>([...continuousPartnershipQuestionnaireUrl, 'isContinuous']),

  // relationship status
  partnershipStatus: new RouteUrl<OrderParams>([...partnershipStatusUrl]),

  // Signature
  signature: new RouteUrl<OrderParams>([...orderIdUrl, 'signature']),

  // Complete
  complete: new RouteUrl<OrderParams>([...orderIdUrl, 'complete']),

  // Overview
  overview: new RouteUrl<OrderParams>([...orderIdUrl, 'overview']),

  // Corporate Flow
  corporate: new RouteUrl<OrderParams>([...corporateUrl]),

  getStatusUrl(identType: IdentMethodType): RouteUrl<{ orderId: string; checkId: string }> {
    switch (identType) {
      case IdentMethodType.AUTO_IDENT:
        return AUTO_IDENT_URLS.status;
      case null:
        return IDENT_URLS.actorData;
      case IdentMethodType.VIDEO_IDENT:
        return VIDEO_IDENT_URLS.status;
      default:
        throw Error(`Unsupported IDENT TYPE ${identType}.`);
    }
  },
};
