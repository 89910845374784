import { ActorType, IActor, ICheck, IOrder, IOrderFile, LOCAL_ID_PREFIX, OrderStatus, OrderType } from '@models';
import { SortingOption } from '@shared/enums/sorting.enum';
import { checkByActorType, deepCopy, sortByProp as sortArrayByProp } from '@shared/utils';
import { DateTime } from 'luxon';
import { ulid } from 'ulid';

/**
 * Service that bundles useful functions
 */
export class ToolsService {
  /**
   * The max file size, for now, is 10MB.
   */
  public static readonly MAX_UPLOAD_FILE_SIZE: number = 1024 * 1024 * 10;

  /**
   * Returns the n last elements from the given array
   * @param arr Array
   * @param n Number of items that should returned
   */
  public static takeRight<T>(arr: T[], n = 1): T[] {
    const length = Array.isArray(arr) ? arr.length : 0;

    if (!length) {
      return [];
    }

    n = length - n;
    return arr.slice(n < 0 ? 0 : n);
  }

  /**
   * Returns whether the order is unread or not
   * @param order Order
   */
  public static isOrderUnread(order: IOrder): boolean {
    // Show "New" status if the order is completed and the user has either not seen the last status update or
    // it is the first status update
    return (
      order.status === OrderStatus.RESULTS_PROVIDED &&
      ((order.statusViewedAt !== null && order.statusViewedAt < order.statusUpdatedAt) ||
        (order.statusViewedAt === null && order.statusUpdatedAt === order.orderUpdatedAt))
    );
  }

  /**
   * Returns main Check belonging to an Order.
   *
   * TODO: This should be inside domain object (once we no longer use `IOrderFile` is should be straight-forward)
   * @param orderType Type of Order that owns `checks`.
   * @param checks Checks belonging to a single Order.
   */
  public static getMainCheck(orderType: OrderType, checks: ICheck[]): ICheck | null {
    const checkCount = checks?.length ?? 0;
    if (!orderType || checkCount === 0) {
      return null;
    }

    const sortedChecks = checks.slice().sort(sortArrayByProp((c) => c.createdAt));
    switch (orderType) {
      case OrderType.INDIVIDUAL:
        return sortedChecks.filter(checkByActorType(ActorType.INDIVIDUAL))[0] || null;
      case OrderType.CORPORATE:
        return sortedChecks.filter(checkByActorType(ActorType.CORPORATE))[0] || null;
      default:
        return null;
    }
  }

  /**
   * Returns the first matching check of the given order file.
   *
   * @param orderFile Order file, containing the checks
   */
  public static getMainCheckFromOrderFile(orderFile: IOrderFile): ICheck | null {
    return this.getMainCheck(orderFile?.order?.type, orderFile?.checks);
  }

  /**
   * Returns the first matching check of the given order.
   *
   * @param order Order containing the checks
   */
  public static getMainCheckFromOrder(order: IOrder): ICheck | null {
    return this.getMainCheck(order.type, order.checks);
  }

  /**
   * Return main actor for given Checks belonging to single Order.
   *
   * @param orderType Order type of Order where `checks` belong.
   * @param checks Checks belonging to a single Order.
   */
  public static getMainActor(orderType: OrderType, checks: ICheck[]): IActor {
    return this.getMainCheck(orderType, checks)?.actor;
  }

  /**
   * Return main actor for given Checks belonging to single Order.
   * @see getMainActor
   */
  public static getMainActorFromOrder(order: IOrder): IActor {
    return this.getMainActor(order.type, order.checks);
  }

  /**
   * Return Actor name according to its type.
   */
  public static getActorName(actor: IActor): string {
    return (
      actor?.corporateDetails?.name || `${actor?.individualDetails?.firstName} ${actor?.individualDetails?.lastName}`
    );
  }

  /**
   * Sorts an array of objects by the given property name
   * @param prop Name of the property to sort by
   */
  public static sortByProp(
    prop: string
  ): (a: { [key: string]: number | string }, b: { [key: string]: number | string }) => number {
    return (a, b) => {
      if (typeof a[prop] === 'number' && typeof b[prop] === 'number') {
        return (a[prop] as number) - (b[prop] as number);
      }

      return (a[prop] as string)?.localeCompare(b[prop] as string);
    };
  }

  /**
   * Returns the date (YYYY-MM-DD) by subtracting a given number of years from the current date
   * @param years Amount of years to subtract
   */
  public static getTodayBeforeYears(years: number): string {
    return DateTime.local().minus({ years }).toFormat('yyyy-MM-dd');
  }

  /**
   * Checks if the two given values are equal
   * @link https://www.30secondsofcode.org/js/s/equals
   * @param a Value a
   * @param b Value b
   */
  // tslint:disable-next-line:no-any
  public static isEqual(a: any, b: any): boolean {
    if (a === b) {
      return true;
    }
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() === b.getTime();
    }
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
      return a === b;
    }
    if (a.prototype !== b.prototype) {
      return false;
    }
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) {
      return false;
    }
    return keys.every((k) => ToolsService.isEqual(a[k], b[k]));
  }

  /**
   * Checks if the file size is below the maximum allowed filesize.
   *
   * @param file The file to check
   * @param maxFileSize Maximum allowed filesize in Byte. Default is 10MB
   * @returns `true` if the file is smaller, `false` if the file is bigger
   */
  public static validateFileSize(file: File, maxFileSize: number = ToolsService.MAX_UPLOAD_FILE_SIZE): boolean {
    return file.size < maxFileSize;
  }

  /**
   * Checks if the original file size is below the maximum allowed filesize.
   *
   * @param base64 Either base64 or data url string
   * @param maxFileSize Maximum allowed filesize in Byte. Default is 10MB
   * @returns `true` if the file is smaller, `false` if the file is bigger
   */
  public static validateBase64FileSize(
    base64: string,
    maxFileSize: number = ToolsService.MAX_UPLOAD_FILE_SIZE
  ): boolean {
    return this.calculateBase64FileSize(base64) < maxFileSize;
  }

  /**
   * Calculates the original file size from a base64 or data url string.
   *
   * @param input Either base64 or data url string
   * @returns Filesize in Byte
   */
  public static calculateBase64FileSize(input: string): number {
    let base64 = input;

    /**
     * Checks for data url and removes it since it isn't part of the file size calculation.
     * It always looks like this: "data:<mediatype>;base64,<actualBase64>", so we can look for the comma.
     */
    if (base64.includes('data:')) {
      base64 = base64.substring(base64.indexOf(',') + 1);
    }

    /**
     * Base64 strings either end with none, one or two padding characters at the end, which are
     * always equal signs. Those need to be counted and subtracted when calculating the file size.
     */
    const paddingCharacters = base64.slice(-2).match('=')?.length || 0;

    /**
     * File size in Byte.
     * See the following source for an explanation of the formula:
     * https://blog.aaronlenoir.com/2017/11/10/get-original-length-from-base-64-string/
     */
    return 3 * (base64.length / 4) - paddingCharacters;
  }

  /**
   * We received an existing order from the Backend
   */
  public static orderToOrderFile(order: IOrder): IOrderFile {
    order = deepCopy(order);
    const nowISO = DateTime.local().toISO();
    const orderChecks = order.checks;

    if (order.checks) {
      delete order.checks;
    }

    // Set the initial content of the file
    const orderFile: IOrderFile = {
      meta: {
        createdTime: nowISO,
        syncedTime: nowISO,
        graphPath: order?.clientMetaData?.graphPaths || [],
      },
      order: order,
      checks: orderChecks || [],
    };

    return orderFile;
  }

  /**
   * User just clicked on New Order
   */
  public static createBlankOrderFile(): IOrderFile {
    const nowISO = DateTime.local().toISO();
    const order = ToolsService.createNewIncompleteOrder();

    // Set the initial content of the file
    const orderFile: IOrderFile = {
      meta: {
        createdTime: nowISO,
        syncedTime: nowISO,
        graphPath: [],
      },
      order: order as IOrder,
      checks: order.checks || [],
    };

    return orderFile;
  }

  public static orderFilehasTitle(orderFile: IOrderFile): boolean {
    const title = orderFile.order?.title;
    return !!title;
  }

  public static createNewIncompleteOrder(): Partial<IOrder> {
    const nowISO = DateTime.utc().toISO();
    return {
      orderId: LOCAL_ID_PREFIX + ulid(),
      status: OrderStatus.INCOMPLETE,
      createdAt: nowISO,
      statusUpdatedAt: nowISO,
      statusViewedAt: nowISO,
      orderUpdatedAt: nowISO,
      createdByUserId: null,
      osint: '',
    };
  }

  public static getCheck(order: IOrder, checkId: string): ICheck | undefined {
    return order?.checks?.find((check) => check.checkId === checkId);
  }

  public static getActorNameFromOrderFile(orderFile: IOrderFile): string {
    const orderFileActor = ToolsService.getMainCheckFromOrderFile(orderFile)?.actor;

    const detailsIndv = orderFileActor?.individualDetails;
    const detailsCorp = orderFileActor?.corporateDetails;

    return (detailsCorp?.name || `${detailsIndv?.firstName} ${detailsIndv?.lastName}` || '').toLocaleLowerCase();
  }

  public static getTitleOfOrderFile(orderFile: IOrderFile): string {
    return orderFile.order?.title?.toLocaleLowerCase();
  }

  public static getTitleOrNameOfOrderFile(orderFile: IOrderFile): string {
    const title = ToolsService.getTitleOfOrderFile(orderFile);

    if (!title) {
      return ToolsService.getActorNameFromOrderFile(orderFile);
    }
    return title;
  }

  public static compareOrderFilesByTitleAndFallbackByName(
    a: IOrderFile,
    b: IOrderFile,
    sortingOption = SortingOption.BY_NAME_ASC
  ) {
    const aValue = ToolsService.getTitleOrNameOfOrderFile(a);
    const bValue = ToolsService.getTitleOrNameOfOrderFile(b);
    const sortingFactor = sortingOption === SortingOption.BY_NAME_ASC ? 1 : -1;

    if (aValue < bValue) {
      return -1 * sortingFactor;
    } else {
      return 1 * sortingFactor;
    }
  }

  /**
   * Sorts an array of IOrderFiles by title in ascending or descending order.
   * If no title is present, use name of first actor instead
   */
  public static sortOrderFilesByTitleAndNames(files: IOrderFile[], sortingOption: SortingOption): IOrderFile[] {
    return [...files].sort((a, b) => ToolsService.compareOrderFilesByTitleAndFallbackByName(a, b, sortingOption));
  }
}
