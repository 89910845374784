import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { ApiError, IPushNotificationPayload, PushNotificationType } from '@models';
import { ORDER_URLS } from '@navUrls';
import { OrderService, UserService } from '@services';
import { AppConfigService } from '@shared/config/app-config.service';

/**
 * Class that handles the click on a push notification
 */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationHandler {
  constructor(
    private readonly router: Router,
    private readonly orderService: OrderService,
    private readonly userService: UserService,
    private readonly appConfigService: AppConfigService
  ) {}

  /**
   * Handles the given push notification by checking the type of the notification
   * @param payload Payload of the notification
   */
  public async handlePushNotification(payload: IPushNotificationPayload): Promise<void> {
    const orderId: string = payload?.orderId;
    switch (payload?.pushNotificationType) {
      case PushNotificationType.RELOAD_REMOTE_CONFIG:
        await this.appConfigService.fetchRemoteConfig();
        break;
      case PushNotificationType.AUTOIDENT_COMPLETED:
      case PushNotificationType.VIDEOIDENT_COMPLETED:
        if (!orderId) {
          PushNotificationHandler.logMissingOrderIdError();
          break;
        }

        // We have to wait until the user is logged in, to be able to navigate him to the correct page
        await this.waitForLogin();

        // On the Ident Status page, the order is automatically updated,
        // so no data needs to be loaded here and we can simply navigate to the order flow.
        // The further navigation to the correct page is handled by the order guard.

        await this.forceNavigation(() =>
          this.router.navigate(ORDER_URLS.detail.getUrlSegments({ params: { orderId } }))
        );

        break;
      case PushNotificationType.ORDER_STATUS_CHANGED:
        if (!orderId) {
          PushNotificationHandler.logMissingOrderIdError();
          break;
        }

        // We have to wait until the user is logged in, to be able to navigate him to the correct page
        await this.waitForLogin();

        // Force reload of the order data, to get orders that are not submitted from this device and also the newest data including the RESULTS_PROVIDED status
        await this.orderService.loadOrders(true);

        await this.forceNavigation(() =>
          this.router.navigate(ORDER_URLS.overview.getUrlSegments({ params: { orderId } }))
        );

        break;
      default:
        PushNotificationHandler.logInvalidNotificationTypeError(payload);
        break;
    }
  }

  /**
   * Waits until a valid user is logged in
   */
  private async waitForLogin(): Promise<void> {
    try {
      await this.userService.getCurrentUser(true);
      return;
    } catch (e) {
      Logger.error(`waitingForLogin(): error calling getCurrentUser(): ${e}`);
      // If the get user request failed (e.g. 401 = logged out), wait until new user data is received
      await this.userService.user$
        .pipe(
          filter((user) => !!user && !(user instanceof ApiError)),
          take(1)
        )
        .toPromise();
    }
  }

  /**
   * Handles the receiving of push notifications while the app is active (push notifications are not displayed to the user in this case)
   * @param payload Payload of the notification
   */
  public async handlePushNotificationReceive(payload: IPushNotificationPayload): Promise<void> {
    switch (payload?.pushNotificationType) {
      case PushNotificationType.AUTOIDENT_COMPLETED:
      case PushNotificationType.VIDEOIDENT_COMPLETED:
      case PushNotificationType.ORDER_STATUS_CHANGED:
        // Load the newest order data
        await this.orderService.loadOrders(true);
        break;
      default:
        PushNotificationHandler.logInvalidNotificationTypeError(payload);
        break;
    }
  }

  /**
   * Logs a generic error that no order id is provided
   */
  private static logMissingOrderIdError(): void {
    Logger.error('Notification payload does not contain an orderId.');
  }

  /**
   * Logs a generic error that no order id is provided
   */
  private static logInvalidNotificationTypeError(payload: IPushNotificationPayload): void {
    Logger.error('Notification payload does not contain a valid push notification type.', payload);
  }

  /** Ensure navigation lifecycle takes place and is never skipped. (Already on page) */
  private forceNavigation(navigationFn: () => void): Promise<boolean> {
    return this.router.navigateByUrl('/').finally(navigationFn);
  }
}
