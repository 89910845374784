import { supportsPassiveEventListeners } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * This service provides an observable with the current internet connection status
 */
@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  /** Whether the device has established an internet connection as BehaviorSubject */
  private readonly online: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);

  /** Whether the device has established an internet connection as Observable */
  public readonly online$: Observable<boolean> = this.online.asObservable();

  constructor() {
    window.addEventListener(
      'online',
      () => this.online.next(true),
      supportsPassiveEventListeners() ? { passive: true } : false
    );

    window.addEventListener(
      'offline',
      () => this.online.next(false),
      supportsPassiveEventListeners() ? { passive: true } : false
    );
  }
}
