import { IMetaModel } from './meta.model';
import { MIME_TYPE } from './mime-type';

/**
 * A file is a part of a document. A document can contain 1 or more files
 */
export interface IDocumentFile extends IMetaModel {
  /** Id of the file */
  fileId: string;
  /**
   * Index that defines where this file is in the document
   * e.g. ID card: front = 0, back = 1
   * e.g. multi-page document: index stands for the relative page number, starting with 0
   */
  index: number | FileIndex;
  /** MIME type of the file */
  mimeType: MIME_TYPE;
  /** File content as base64 string */
  base64: string;
  /** Timestamp when the file was created */
  createdAt: string;
}

/**
 * A file is a part of a document. A document can contain 1 or more files
 */
export class DocumentFile implements IDocumentFile {
  /** Id of the file */
  public fileId: string;
  /**
   * Index that defines where this file is in the document
   * e.g. ID card: front = 0, back = 1
   * e.g. multi-page document: index stands for the relative page number, starting with 0
   */
  public index: number | FileIndex;
  /** MIME type of the file */
  public mimeType: MIME_TYPE;
  /** File content as base64 string */
  public base64: string;
  /** Timestamp when the file was created */
  public createdAt: string;
}

/**
 * Indexes for different document types
 */
export enum FileIndex {
  INDIVIDUAL_ID_CARD_FRONT = 0,
  INDIVIDUAL_ID_CARD_BACK = 1,
}
