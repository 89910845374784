import de from '../../assets/i18n/de.json';
import el from '../../assets/i18n/el.json';
import en from '../../assets/i18n/en.json';

export const translationTexts: ITranslations = {
  de,
  en,
  el,
};

export interface ITranslations {
  de: Record<string, unknown>;
  en: Record<string, unknown>;
  el: Record<string, unknown>;
  [key: string]: Record<string, unknown>;
}
