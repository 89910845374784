import { RouteUrl } from './route.url';

/** Base account url */
const accountUrl = ['account'];

/**
 * All account urls<ORDER_PARAMS>([...orderUrl, 'order-type'])
 */
export const ACCOUNT_URLS = {
  account: new RouteUrl([...accountUrl]),
  languageSettings: new RouteUrl([...accountUrl, 'language']),
  pushNotifications: new RouteUrl([...accountUrl, 'push-notifications']),
};
