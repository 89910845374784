import { DateTime } from 'luxon';
import { KycFile } from './kyc-file/kyc-file.model';

import { ICheck } from './check.model';
import { IDecision } from './decision.model';
import { IMetaModel, MetaModel } from './meta.model';
import { OrderStatus } from './order-status.model';
import { Result } from './result.model';
import { IOrderClientMetaData } from './order/order-meta-data.type';
import { ISOCountryCode } from './country.model';
import { HIGH_RISK_COUNTRY_LIST_NAME } from '@shared/enums/high-risk-country.enum';

/**
 * Available order types
 */
export enum OrderType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}

export enum Partnership {
  CONTINUOUS_STANDARD_PURPOSE = 'CONTINUOUS_STANDARD_PURPOSE',
  CONTINUOUS_SPECIAL_PURPOSE = 'CONTINUOUS_SPECIAL_PURPOSE',
  TRANSACTION = 'TRANSACTION',
}

export enum PartnershipFlagType {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  NO_VALUE = 'NO_VALUE',
}

export enum OrderPart {
  ORDER = 'ORDER',
  CHECK = 'CHECK',
  ACTOR = 'ACTOR',
  ACTOR_ADDRESS = 'ACTOR_ADDRESS',
  ACTOR_DOCUMENT = 'ACTOR_DOCUMENT',
  KYCFILE = 'KYCFILE',
}

/**
 * An order is the core that is created by a user to check his contractor
 */
export interface IOrder extends IMetaModel {
  /** Id of the order */
  orderId: string;
  /** Type of the order */
  type: OrderType;

  typeOfPartnership: Partnership;

  partnershipPurposeBasedOn?: string;

  partnershipIsComplex?: PartnershipFlagType;

  partnershipHasHighRisk?: PartnershipFlagType;

  partnershipHasForeignConnection?: PartnershipFlagType;

  partnershipAffectedCountry?: ISOCountryCode;

  partnershipAffectedHitlists?: HIGH_RISK_COUNTRY_LIST_NAME[];

  /** Custom name of Order */
  title?: string;
  /** Id of the company */
  companyId: string;
  /** Id of the user that has created the order */
  createdByUserId: string;
  /** Actors that are related to this order */
  checks?: ICheck[];
  /** Current status of the order */
  status: OrderStatus;
  /** List of decisions. Sorted by creation date. Starting with the most recent decision */
  decisions?: IDecision[];
  /** Result of the order. Composed of the results of the decisions. */
  result?: Result;
  /** Timestamp of the last status update */
  statusUpdatedAt?: string;
  /** Timestamp when the order was viewed the last time */
  statusViewedAt?: string;
  /** Timestamp when the data was last updated, except view date */
  orderUpdatedAt?: string;

  clientMetaData?: IOrderClientMetaData;
  // Not needed in the user FE
  // assigneeUserId?: string;
  osint: string;
  /** Any additional files added to the order */
  kycFiles?: KycFile[];
  isArchived?: boolean;
  /** If the order has manual KYC report */
  hasManualKYCReport: boolean;
}

/**
 * An order is the core that is created by a user to check his contractor
 */
export class Order extends MetaModel implements IOrder, IMetaModel {
  clientMetaData?: IOrderClientMetaData;
  /** Id of the order */
  public orderId: string;

  /** Type of the order */
  public type: OrderType;

  public typeOfPartnership: Partnership;

  public partnershipPurposeBasedOn?: string;

  public partnershipIsComplex?: PartnershipFlagType;

  public partnershipHasHighRisk?: PartnershipFlagType;

  public partnershipHasForeignConnection?: PartnershipFlagType;

  public partnershipAffectedCountry?: ISOCountryCode;

  public partnershipAffectedHitlists?: HIGH_RISK_COUNTRY_LIST_NAME[];

  /** custom name of order */
  public title: string;

  /** Id of the company */
  public companyId: string;

  /** Id of the user that has created the order */
  public createdByUserId: string;

  /** Actors that are related to this order */
  public checks?: ICheck[];

  /** Current status of the order */
  public status: OrderStatus;

  /** List of decisions */
  public decisions?: IDecision[];

  /** Result of the order. Composed of the results of the decisions. */
  public result: Result | null;

  /** Timestamp of the last status update */
  public statusUpdatedAt?: string = DateTime.local().toISO();

  /** Timestamp when the order was viewed the last time */
  public statusViewedAt?: string = DateTime.local().toISO();

  /** Timestamp when the data was last updated, except view date */
  public orderUpdatedAt?: string = DateTime.local().toISO();

  public osint: string = '';

  /** Any additional files added to the order */
  public kycFiles?: KycFile[];

  public isArchived: boolean = false;

  /** If the order has manual KYC report */
  public hasManualKYCReport: boolean = false;
}
