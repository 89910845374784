import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BffService } from '@http/bff.service';
import { PUBLIC_URLS } from '@navUrls';
import { UserService } from '@services/user.service';
import { AuthUrl } from '@serviceUrls';
import { ICreateSharedSessionResponse } from './auth.types';

/**
 * The authService manages the authToken
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly bffService: BffService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {}

  /**
   * Exchange the SSO token with the backend to get an AuthToken
   * @param ssoCode Code from the SSO service
   * @param redirectUri Url the user should be redirected to
   */
  public exchangeToken(ssoCode: string, redirectUri: string): Observable<void> {
    // We cannot use the bffService here, because that would lead to a circular dependency
    return this.bffService
      .get<{ meta: { idToken: string; message: string } }>({
        url: AuthUrl.exchangeToken(),
        params: {
          code: encodeURIComponent(ssoCode),
          redirectUri,
        },
        withCredentials: true,
        headers: new HttpHeaders({ noToken: 'true' }),
      })
      .pipe(
        // Do not forward the response
        map(() => undefined)
      );
  }

  /**
   * Logs out the current user, by removing the session cookie (is removed by the backend response) and resetting the user data
   */
  public logout(): void {
    this.bffService
      .post<void>({
        url: AuthUrl.logout(),
      })
      .subscribe(async () => {
        // Reset the user data
        this.userService.invalidateUser();
        // Navigate to login screen
        await this.router.navigate(PUBLIC_URLS.authentication.getUrlSegments());
      });
  }

  /**
   * Makes BFF URL Authorized and shareable across browsers.
   */
  public createSharedSession(uriToShare: string): Observable<string> {
    return this.bffService
      .post<ICreateSharedSessionResponse>({
        url: AuthUrl.createSharedSession(),
      })
      .pipe(
        map((response) => {
          return `${uriToShare}?sessionId=${response.data.sessionId}&oneTimeToken=${response.data.oneTimeToken}`;
        })
      );
  }
}
