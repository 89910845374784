import { Injector, NgModule } from '@angular/core';

/**
 * Graph module that provides the Angular Injector to be able to get the Graph service in the class decorator
 */
@NgModule()
export class GraphModule {
  /** Injector to get provided services */
  public static injector: Injector;

  constructor(private readonly injector: Injector) {
    GraphModule.injector = injector;
  }
}
