import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { AppConfigService } from '@shared/config/app-config.service';

@Injectable()
export class ConfigResolver implements Resolve<void> {
  static configsPatched = false;

  constructor(private readonly appConfigService: AppConfigService) {}

  public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    // Disable logs only in web-apps in production environment
    if (ConfigResolver.configsPatched) {
      return;
    }

    Logger.info('APP RESOLVER: Fetching remote config');

    // not waiting on it to speed up startup time
    this.appConfigService
      .fetchRemoteConfig()
      .catch(Logger.error)
      .then(() => {
        ConfigResolver.configsPatched = true;
      });
  }
}
