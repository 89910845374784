import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for check requests
 */
export class CheckUrl extends BasicUrl {
  /**
   * Get check request url
   */
  public static getCheck(companyId: string, orderId: string, checkId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
    ]);
  }

  /**
   * Returns check creation url
   */
  public static createCheck(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'check']);
  }

  /**
   * Returns check deletion url
   */
  public static deleteCheck(companyId: string, orderId: string, checkId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
    ]);
  }

  /**
   * Returns check result url. Can be used to retrieve/change the check stored result
   */
  public static checkResult(companyId: string, orderId: string, checkId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'result',
    ]);
  }
}
