import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { LibModule } from '@shared/lib.module';
import { DocumentPreviewComponent } from './document-preview.component';

@NgModule({
  declarations: [DocumentPreviewComponent],
  imports: [CommonModule, LibModule, TranslocoModule, PdfViewerModule],
  exports: [DocumentPreviewComponent],
})
export class DocumentPreviewModule {}
