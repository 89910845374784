import {
  ActorType,
  AutoIdentDebuggingParam,
  IActorAddress,
  IdentMethodType,
  IIndividualActorDetails,
  NO_IDENT,
  VideoIdentDebuggingParam,
} from '@models';

/**
 * Options for debugging the ident process
 */
export interface IDebuggingOptions<T extends AutoIdentDebuggingParam | VideoIdentDebuggingParam> {
  /** Debugging path */
  path?: T;
  /** Whether mocked data should be used (skips the IDnow process completely) */
  mock?: boolean;
}

export enum CreateActorAs {
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
  CONTRACTUAL_PARTNER = 'CONTRACTUAL_PARTNER',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
}

type CreateAutoIdentDto = {
  type: IdentMethodType.AUTO_IDENT;
  debuggingOptions?: IDebuggingOptions<AutoIdentDebuggingParam>;
  orderId: string;
  createActorAs?: CreateActorAs[];
};

type CreateVideoIdentDto = {
  type: IdentMethodType.VIDEO_IDENT;
  mail: string;
  companyName: string;
  debuggingOptions?: IDebuggingOptions<VideoIdentDebuggingParam>;
  orderId: string;
  createActorAs?: CreateActorAs[];
};

type CreateNoIdentDto = {
  type: typeof NO_IDENT;
  actorType: ActorType;
  orderId: string;
  createActorAs?: CreateActorAs[];
};

export type CreateUboCheckDto = {
  orderId: string;
  actorDetails: Partial<IIndividualActorDetails>;
  actorAddress: Partial<IActorAddress>;
};

export type CreateCheckDto = CreateAutoIdentDto | CreateVideoIdentDto | CreateNoIdentDto;
