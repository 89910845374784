import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { environment } from '@environment';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { AppModule } from './app/app.module';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
  Logger.setProductionStatus();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // tslint:disable-next-line:no-console
  .catch((err) => console.error(err));

// Include PWA elements (e.g. camera overlay) for web version
defineCustomElements(window).catch((e) => Logger.error(e));
