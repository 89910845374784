import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for push notification requests
 */
export class PushNotificationUrl extends BasicUrl {
  /**
   * Registers the device for receiving push notifications
   */
  public static register(): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['notification', 'pushNotificationSettings']);
  }

  /**
   * Unregister the device from receiving push notifications
   * @param fcmToken Firebase Cloud Messaging token of the device
   */
  public static unregister(fcmToken: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['notification', 'pushNotificationSettings', fcmToken]);
  }
}
