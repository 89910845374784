import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Interface of the loading status
 */
export interface ITranslocoStatus {
  /** Whether it is currently loading */
  isLoading: boolean;
  /** Language that is loading/loaded */
  language: string;
}

/**
 * Helper service to get the current loading status of the transloco service.
 * This is needed because the transloco.events$ observable is not an BehaviorSubject, so the current status is not emitted
 */
@Injectable({
  providedIn: 'root',
})
export class TranslocoStatusService {
  /** Whether the transloco service is currently loading */
  private readonly status: BehaviorSubject<ITranslocoStatus> = new BehaviorSubject<ITranslocoStatus>({
    isLoading: true,
    language: null,
  });
  /** Whether the transloco service is currently loading */
  public readonly status$: Observable<ITranslocoStatus> = this.status.asObservable();

  /**
   * Sets a new status
   * @param isLoading Whether the service is currently loading
   * @param language Language that is loading/loaded
   */
  public setStatus(isLoading: boolean, language: string): void {
    this.status.next({ isLoading, language });
  }
}
