import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DocumentPreviewOverlayComponent } from '@shared/components/document-preview-overlay/document-preview-overlay.component';
import { IDocumentPreview } from '@shared/components/document-preview-overlay/document-preview.interface';

/**
 * Service to deal with the document preview overlay state
 */
@Injectable()
export class DocumentPreviewOverlayService {
  private readonly overlayConfig: OverlayConfig;
  constructor(private readonly overlay: Overlay) {
    this.overlayConfig = new OverlayConfig({
      positionStrategy: new GlobalPositionStrategy(),
      disposeOnNavigation: true,
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }

  public open(documentToPreview: IDocumentPreview): void {
    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.overlay.create(this.overlayConfig);

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(DocumentPreviewOverlayComponent);

    // Attach ComponentPortal to PortalHost
    const componentRef = overlayRef.attach(filePreviewPortal);
    componentRef.instance.documentToPreview = documentToPreview;

    componentRef.instance.close.subscribe((_) => overlayRef.detach());
  }
}
