import { ulid } from 'ulid';

import { IActorDocument } from './actor-document.model';
import { IActorToActor } from './actor-to-actor.model';
import { CorporateActorDetails, ICorporateActorDetails } from './corporate-actor-details.model';
import { IIndividualActorDetails, IndividualActorDetails } from './individual-actor-details.model';
import { LOCAL_ID_PREFIX } from './local-id.prefix';
import { IMetaModel, MetaModel } from './meta.model';

/**
 * Actors can be persons or companies
 */
export interface IActor extends IMetaModel {
  /** Id of the actor */
  actorId: string;
  /** Type of the actor */
  type: ActorType;
  /** Details of the actor if it is a corporate actor */
  corporateDetails?: ICorporateActorDetails;
  /** Details of the actor if it is a individual actor */
  individualDetails?: IIndividualActorDetails;
  /** List of actor to actor relationships */
  relatedActors: IActorToActor[];
  isPossibleDuplicate: boolean;
}

/**
 * Available actor types
 */
export enum ActorType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATE = 'CORPORATE',
}

/**
 * Actors can be persons or companies
 */
export class Actor extends MetaModel implements IActor {
  /** Id of the actor */
  public readonly actorId: string = LOCAL_ID_PREFIX + ulid();
  /** Corporate details of the actor */
  public corporateDetails?: ICorporateActorDetails;
  /** Individual details of the actor */
  public individualDetails?: IIndividualActorDetails;
  /** All related documents of this actor */
  public documents?: IActorDocument[];
  /** List of actor to actor relationships */
  public relatedActors: IActorToActor[] = [];
  public isPossibleDuplicate: boolean;

  /**
   * @param type Type of the actor
   */
  constructor(public readonly type: ActorType) {
    super();

    switch (type) {
      case ActorType.CORPORATE:
        this.corporateDetails = new CorporateActorDetails();
        break;
      case ActorType.INDIVIDUAL:
        this.individualDetails = new IndividualActorDetails();
        break;
    }
  }
}
