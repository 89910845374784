// tslint:disable:interface-name
import { Route } from '@angular/router';
import { Style } from '@capacitor/status-bar';

/**
 * Available background colors.
 * Only values with matching CSS-variables are working.
 *
 * If you add more colors, write the name in capital letters, omit the leading `--` and replace all `-` with `_` in the name
 * Example: `--grey-01` -> `GREY_01`
 */
export enum BackgroundColor {
  WHITE = 'WHITE',
  GREY_01 = 'GREY_01',
}

/** KebRoute array type */
export declare type KebRoutes = KebRoute[];

/**
 * KebRoute customizes the Angular Route for more type safety
 */
export interface KebRoute extends Route {
  /**
   * Additional developer-defined data provided to the component via
   * `ActivatedRoute`. By default, no additional data is passed.
   */
  data?: KebRouteData;
  /**
   * An array of child `Route` objects that specifies a nested route
   * configuration.
   */
  children?: KebRoutes;
}

/**
 * Data that can be provided within routes
 */
export interface KebRouteData {
  /** Page title */
  title?: string | string[];
  /** Options for status bar */
  statusBarOptions?: {
    /** Style of the status bar */
    statusBarStyle?: Style;
    /** Hide status bar */
    hideStatusBar?: boolean;
    /**
     * Whether the cover around the notch should be hidden.
     * If false (default), scrolling content will be covered and is not appearing beside the notch
     */
    hideNotchCover?: boolean;
    /** Color of notch cover */
    notchCoverColor?: string;
  };
  backgroundColor?: BackgroundColor;
}
// tslint:enable:interface-name
