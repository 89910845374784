import { environment } from '@environment';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { GraphService, IGraphConfig } from '@utilityServices';
import { GraphModule } from './graph.module';

/**
 * Class decorator that allows defining of a GraphNode and links to other Nodes
 * @param config Graph configuration
 */
export function GraphConfig<T extends string = string>(config: IGraphConfig<T>): ClassDecorator {
  return (constructor) => {
    const isProduction = environment.production;
    let graphService: GraphService;

    // Check if a karma object is available -> running in a testing environment
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (window?.['__karma__']) {
      // The GraphService is not initialized in the test environment, so we want to avoid logging many errors by returning here
      return;
    }

    // Try to get the injected GraphService from the GraphModule
    try {
      graphService = GraphModule.injector.get<GraphService>(GraphService);
    } catch (e) {
      Logger.error('GraphService is not initialized. Please import it in the AppModule');
      return;
    }

    // Reference of the component instance. Is only available after `ngOnInit` of the component is called!
    // Allows to call functions of the component with the correct `this` reference
    let instanceReference;

    config.urls.forEach((url) => {
      // Use the static absolute url as id for the node
      const id = url.staticUrlAbsoluteRaw;

      graphService.graph.addNode(id, { label: config.label || url.staticUrlAbsoluteRaw, url });

      // Add edges for all actions with urls
      Object.values(config.actions).forEach((action) => {
        if (action.url) {
          graphService.graph.addEdge(id, action.url.staticUrlAbsoluteRaw, { label: `${action.name}` });
        }

        if (!action.fn || typeof action.fn !== 'string') {
          return;
        }

        const matchingFunction = constructor.prototype[action.fn];

        if (!matchingFunction) {
          Logger.error(
            `GRAPH CONFIG ERROR: '${action.fn}' is not a valid function in '${constructor.name}'. Available functions:`,
            Object.getOwnPropertyNames(constructor.prototype).filter((property) => property !== 'constructor')
          );
          return;
        }

        // Replace the function name with the actual function
        action.fn = () => matchingFunction.call(instanceReference);
      });
    });

    // Save the existing ngOnInit function to be able to call it later
    const originalNgOnInit = constructor.prototype.ngOnInit;

    // Overwrite the ngOnInit function and call the original ngOnInit function afterwards
    constructor.prototype.ngOnInit = function (args: unknown): void {
      graphService.setActivePageConfig(config);

      // Set reference to the component instance
      instanceReference = this;

      // Call original if existent
      if (originalNgOnInit) {
        originalNgOnInit.apply(this, args);
      }
    };

    /**
     * Export graph in DOT format
     * @url https://en.wikipedia.org/wiki/DOT_(graph_description_language)
     */
    if (!isProduction) {
      localStorage.setItem('keb-order-graph', graphService.graph.getDotFormat());
    }
  };
}
