import { NgModule } from '@angular/core';

import { AddressPipe } from './address.pipe';
import { OrderCheckPipe } from './order-check.pipe';
import { PartPipe } from './part.pipe';

const PIPES = [AddressPipe, OrderCheckPipe, PartPipe];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class KebPipeModule {}
