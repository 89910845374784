import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for order requests
 */
export class OrderUrl extends BasicUrl {
  /**
   * Get all orders of company request url
   */
  public static getAll(companyId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order']);
  }

  /**
   * Get order details url
   */
  public static getOrder(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId]);
  }

  public static patchOrder(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId]);
  }

  /**
   * Create incomplete order request url
   */
  public static createIncompleteOrder(companyId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', 'incomplete']);
  }

  /**
   * Update an existing actor
   */
  public static updateActor(companyId: string, actorId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'actor', actorId]);
  }

  /**
   * Update the address of an actor
   */
  public static updateActorAddress(
    companyId: string,
    orderId: string,
    checkId: string,
    actorAddressId: string
  ): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'actorAddress',
      actorAddressId,
    ]);
  }

  /**
   * Provide documents for a check
   */
  public static provideDocuments(companyId: string, orderId: string, checkId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'document',
    ]);
  }

  /**
   * Submit an order for review
   */
  public static submitOrderForReview(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'status']);
  }

  /**
   * Update the viewed date of an order
   */
  public static updateOrderViewedDate(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'viewed']);
  }

  /**
   * Get all files of given document
   */
  public static getAllFilesOfDocument(companyId: string, orderId: string, checkId: string, documentId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'check',
      checkId,
      'document',
      documentId,
      'file',
    ]);
  }

  /**
   * Download the generated PDF report
   */
  public static getGeneratedReport(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'report.pdf']);
  }

  /**
   * Provide additional file to the order
   */
  public static createKycFile(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'kyc-file']);
  }

  /**
   * Get the order file
   */
  public static getKycFile(companyId: string, orderId: string, kycFileId): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, [
      'company',
      companyId,
      'order',
      orderId,
      'kyc-file',
      kycFileId,
    ]);
  }

  /**
   * Archive the order with the given orderId
   */
  public static archiveOrder(companyId: string, orderId: string): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['company', companyId, 'order', orderId, 'archive']);
  }
}
