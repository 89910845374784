import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for file requests
 */
export class FileUrl extends BasicUrl {
  /**
   * File upload
   */
  public static upload(): KebUrl {
    return this.getCompleteUrl(BackendService.STORAGE_SERVICE, ['file']);
  }
}
