import deepmerge from 'deepmerge';

/**
 * Custom array merge function, for more information see:
 * @link https://github.com/TehShrike/deepmerge#arraymerge
 *
 * @param target Target array
 * @param source Source array
 * @param mergeOptions Merge options
 */
export const COMBINE_MERGE = <T extends object>(
  target: T[],
  source: T[],
  mergeOptions: deepmerge.Options & {
    cloneUnlessOtherwiseSpecified: (value: T, options: deepmerge.Options) => T;
  }
) => {
  const destination = target.slice();

  source.forEach((sourceItem, sourceArrayIndex) => {
    if (typeof destination[sourceArrayIndex] === 'undefined') {
      destination[sourceArrayIndex] = mergeOptions.cloneUnlessOtherwiseSpecified(sourceItem, mergeOptions);
    } else if (mergeOptions.isMergeableObject(sourceItem)) {
      destination[sourceArrayIndex] = deepmerge(target[sourceArrayIndex], sourceItem, mergeOptions);
    } else if (target.indexOf(sourceItem) === -1) {
      destination.push(sourceItem);
    }
  });
  return destination;
};
