<ng-container *transloco="let t; read: 'general.documentTypes'">
  <div class="keb-ge__page keb-document-preview__content">
    <div *ngIf="isLoading" class="keb-document-preview__loading">
      <keb-loader></keb-loader>
      {{ 'documentPreview.loading' | transloco }}
    </div>

    <div
      class="keb-document-preview__image-container"
      [class.keb-document-preview__image-container--hidden]="isLoading || showError"
    >
      <ng-container *ngFor="let file of extendedActorDocument?.files">
        <h4 *ngIf="extendedActorDocument?.files?.length > 1" class="keb-document-preview__image-title">
          {{ t(extendedActorDocument?.type + '.withIndex', { index: file.index + 1 }) }}
        </h4>
        <img
          *ngIf="!showError && [MIME_TYPE.PNG, MIME_TYPE.JPG, MIME_TYPE.GIF].includes($any(file.mimeType))"
          class="keb-document-preview__image"
          [src]="file.base64"
          [alt]="
            extendedActorDocument?.files?.length > 1
              ? t(extendedActorDocument?.type + '.withIndex', { index: file.index + 1 })
              : t(extendedActorDocument?.type + '.general')
          "
          (load)="handleLoaded()"
          (error)="handleError()"
        />

        <pdf-viewer
          *ngIf="file.mimeType === MIME_TYPE.PDF"
          class="keb-document-preview__pdf-viewer"
          [class.keb-document-preview__pdf-viewer--relative]="positionPdfViewerRelative"
          [src]="file.objectUrl"
          [fit-to-page]="true"
          [show-all]="true"
          (after-load-complete)="handlePdfLoaded()"
          (error)="handleError($event)"
        ></pdf-viewer>
      </ng-container>
    </div>

    <ng-container *ngIf="showError">
      <div class="keb-document-preview__error-container">
        <keb-icon icon="{{ LC.ICON.name.ERROR_XL }}" size="extra-large"></keb-icon>

        <p class="keb-document-preview__error-text">{{ 'documentPreview.documentCouldNotBeLoaded' | transloco }}</p>

        <button kebButton color="secondary" (click)="reload()">
          <keb-icon icon="{{ LC.ICON.name.RELOAD_S }}" slot="start"></keb-icon>
          {{ 'documentPreview.reloadDocument' | transloco }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
