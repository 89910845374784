export enum FileExtensions {
  PDF = 'pdf',
  GIF = 'gif',
  JPG = 'jpg',
  JPE = 'jpe',
  JPEG = 'jpeg',
  PNG = 'png',
  CSV = 'csv',
  HEIC = 'heic',
  HEIF = 'heif',
  XML = 'xml',
  MP4 = 'mp4',
  TXT = 'txt',
  ZIP = 'zip',
}
