import { IMetaModel, MetaModel } from '../meta.model';
import { MIME_TYPE } from '../mime-type';
import { KycFileType } from './kyc-file.type';

export interface IKycFile extends IMetaModel {
  /** The kyc file id */
  kycFileId?: string;
  /** Id of the element that holds the file */
  itemId?: string;
  /** Type of file (Order, Document/etc) */
  fileType?: KycFileType;
  /** MIME type of the file */
  mimeType?: MIME_TYPE;
  /** File content URL. NOTICE: For now, is a base64 string */
  fileURL?: string;
  /** The name of the file */
  fileName: string;

  /** File content as binary */
  file?: File;
}

export class KycFile extends MetaModel implements IKycFile {
  public kycFileId?: string;
  public itemId?: string;
  public fileType?: KycFileType;
  public mimeType?: MIME_TYPE;
  public fileURL?: string;
  public fileName: string;
  public file?: File;
}
