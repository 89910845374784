import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for user requests
 */
export class UserUrl extends BasicUrl {
  /**
   * User get me request url
   */
  public static getMe(): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['user', 'me']);
  }

  public static getSlimUser(userId: string): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['users', userId, 'slim']);
  }

  public static updateUser(userId): KebUrl {
    return this.getCompleteUrl(BackendService.CUSTOMER_SERVICE, ['users', userId]);
  }
}
