import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import * as deepEqual from 'deep-equal';
import { keyValueArrayToObjectMapper, toMergedObject } from '../utils';

// Do not use the short import url, because this causes circular dependency warnings
// noinspection ES6PreferShortImport
import { ToolsService } from '../../core/services/utility-services/tools.service';

/**
 * Interface for route params
 */
export interface IRouteParams {
  [key: string]: number | string;
}

/**
 * Basic options for all urls
 */
interface IUrlOptions {
  /** Whether a slash should be added to the beginning of the url */
  leadingSlash?: boolean;
  /** Whether a slash should be added to the end of the url */
  trailingSlash?: boolean;
}

export interface IQueryParameter {
  [key: string]: string | number | (string | number)[];
}

/**
 * Additional options for urls with param placeholders
 */
interface IOptionsForUrlsWithParams<T extends IRouteParams> {
  /** Params that will replace the param placeholder in url */
  params: T;
}

/**
 * All options for urls with param placeholders
 * @extends IUrlOptions
 * @extends IOptionsForUrlsWithParams
 */
interface IUrlOptionsWithParams<T extends IRouteParams> extends IUrlOptions, IOptionsForUrlsWithParams<T> {}

/**
 * Url for routing.
 * It is capable of automatically replacing param placeholders (e.g. `:orderId`) in the url with given values.
 */
export class RouteUrl<T extends IRouteParams = null> {
  /** Segments of the url. Only available if url contains no parameters! */
  public readonly staticUrlSegments: (string | number)[] | null = null;

  /** Last segment of the url. If last segment is a parameter this parameter is not replaced by the value but just returned (e.g. ':userId') */
  public readonly staticUrlLastSegment: string | null = null;

  /** Absolute url as string. Only available if url contains no parameters! */
  public readonly staticUrlAbsolute: string | null = null;

  /** Absolute raw url as string. Variable parameters are not replaced! */
  public readonly staticUrlAbsoluteRaw: string | null = null;

  /**
   * Array that contains all parts and trailing subparts as strings
   * At index 0: complete url, e.g. `user/:userId/details`
   * At index 1: complete url minus first part, e.g. `:userid/details`
   * At index 2: complete url minus the first two parts, e.g. `details`
   *
   * -> Entire example array: ['user/:userId/details', ':userid/details', 'details']
   */
  public readonly staticUrlPartsRaw: string[] = [];

  /**
   * Array like `staticUrlPartsRaw` but inverted.
   * At index 0: only the last part, e.g. `details`
   * At index 1: the last two parts, e.g. `:userid/details`
   * At index 2: the last three parts (in this case the complete url), e.g. `user/:userId/details`
   *
   * -> Entire example array: ['details', ':userid/details', 'user/:userId/details']
   */
  public readonly staticUrlPartsRawInverted: string[] = [];

  /** Segments of routing url */
  private readonly urlSegments: (string | number)[] = [];

  constructor(
    private readonly url: string | (string | number)[],
    public readonly queryParams: IQueryParameter = {} as IQueryParameter
  ) {
    if (Array.isArray(url)) {
      this.urlSegments = url;
    } else {
      // Split string in segments and remove empty segments, caused by leading or trailing `/`
      this.urlSegments = url.split('/').filter((segment) => segment.length > 0);
    }

    // Set last url segment to be able to use it in routing files
    this.staticUrlLastSegment = `${this.urlSegments[this.urlSegments.length - 1]}`;

    this.staticUrlAbsoluteRaw = `/${this.urlSegments.join('/')}`;

    for (let i = 0; i < this.urlSegments.length; i++) {
      this.staticUrlPartsRaw.push(this.urlSegments.slice(i).join('/'));
    }

    // "Real" copy of the `staticUrlPartsRaw` array
    this.staticUrlPartsRawInverted = [...this.staticUrlPartsRaw].reverse();

    // If url contains variable parameters, do not create static versions of url
    if (this.urlSegments.find((item) => `${item}`.startsWith(':'))) {
      return;
    }

    // Set static urls for easier usage in kerberos-compliance/templates
    this.staticUrlSegments = this.urlSegments;
    // tslint:disable-next-line:no-any
    this.staticUrlAbsolute = this.getJoinedUrl(...([{ leadingSlash: true, trailingSlash: false }] as any));
  }

  /**
   * Returns the absolute url when transformed to string
   */
  public toString(): string {
    return this.staticUrlAbsolute;
  }

  /**
   * Get n elements of url from the right
   * @param segments Number of segments
   * @returns Joined url elements
   */
  public getLastSegment(segments: number = 1): string {
    return ToolsService.takeRight(this.urlSegments, segments).join('/');
  }

  /**
   * Returns complete url, joined to string
   * @param options Options for the url
   * @param options.leadingSlash Whether a slash should be added to url at the beginning
   * @param options.trailingSlash Whether a slash should be added to url at the end
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url as string with replaced parameters
   */
  public getJoinedUrl(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise `options` is optional and includes all values but `params`.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends null | undefined ? [IUrlOptions?] : [IUrlOptionsWithParams<T>]
  ): string {
    // Get options from the arguments array
    const _options: IUrlOptionsWithParams<IRouteParams> | undefined = options[0];

    const defaultOptions: IUrlOptions = { leadingSlash: false, trailingSlash: false };

    // Add default options as fallback. `options` will overwrite the default options if set
    const mergedOptions: IUrlOptionsWithParams<IRouteParams> = { ...defaultOptions, ..._options };

    let result: string = mergedOptions.leadingSlash ? '/' : '';

    // Parameter has to be an array and must be casted to `any` to avoid typescript errors.
    // tslint:disable-next-line:no-any
    result += this.getUrlSegments(...([mergedOptions] as any)).join('/');
    result += mergedOptions.trailingSlash ? '/' : '';
    result += this.queryParamsToString();

    return result;
  }

  public withQueryParams(queryParams: IQueryParameter): RouteUrl {
    return new RouteUrl(this.url, queryParams);
  }

  public withoutQueryParams(): RouteUrl {
    return new RouteUrl(this.staticUrlAbsoluteRaw.replace(/\?.*/g, ''));
  }

  public equals(other: RouteUrl, regardQueryParams: boolean = true): boolean {
    if (other.urlSegments.length !== this.urlSegments.length) {
      return false;
    }

    const allPathsEqual = other.urlSegments
      .map((otherSegment, index) => {
        const ownSegment = this.urlSegments[index];
        const isPlaceholder = ownSegment.toString().startsWith(':') || otherSegment.toString().startsWith(':');
        if (isPlaceholder) {
          return true;
        }
        return ownSegment === otherSegment;
      })
      .every((result) => result === true);

    const queryParamsEqual = !regardQueryParams || deepEqual(other.queryParams, this.queryParams);
    return allPathsEqual && queryParamsEqual;
  }

  /**
   * Returns url with replaced parameters
   * @param options Options for the url
   * @param [options.params] Url params that should replace the param placeholders in the url. Only available if type T is defined!
   * @returns Url in segments/array
   */
  public getUrlSegments(
    // Expects `params` value in options if `T` is not null and not undefined.
    // Otherwise no parameter is expected.
    // Unfortunately there is currently no other, more pleasant possibility to define parameters as optional if generic type is not defined.
    ...options: T extends null | undefined ? [] : [IOptionsForUrlsWithParams<T>]
  ): (string | number)[] {
    // Get params from the arguments array
    const params = options && options[0] && options[0].params;

    return this.urlSegments.map((segment: string | number) => {
      // Transforms to string
      const segmentAsString: string = `${segment}`;

      // If segment is placeholder for param
      if (segmentAsString.startsWith(':')) {
        // Get param name by removing ':'
        const param = segmentAsString.substring(1);

        // Check if param is included in `params`
        if (!params || params[param] === undefined || params[param] === null) {
          Logger.error(`RouteUrl: Missing param '${param}' in route: ${this.urlSegments.join('/')}`);
          Logger.error(`Given params: ${JSON.stringify(params)}`);
          return segment;
        }

        // Return the value that should replace the placeholder
        return params[param];
      }
      // Return the url segment if no placeholder
      return segment;
    });
  }

  private queryParamsToString(): string {
    if (Object.keys(this.queryParams).length === 0) {
      return '';
    }
    const values = Object.values(this.queryParams).map((value) =>
      value instanceof Array ? value.join(',') : value.toString()
    );
    return `?${Object.keys(this.queryParams)
      .map((key, index) => `${key}=${values[index]}`)
      .join('&')}`;
  }

  public static fromUrlWithQueryParameter(combinedUrl: string): RouteUrl {
    const [url, queryParamsString] = combinedUrl.split('?');
    let parsedQueryParameter: IQueryParameter;

    if (!queryParamsString) {
      parsedQueryParameter = {};
    } else {
      parsedQueryParameter = queryParamsString
        .split('&')
        .map((param) => param.split('='))
        .map(keyValueArrayToObjectMapper({ defaultValue: true }))
        .reduce(toMergedObject, {});
    }

    return new RouteUrl(url, parsedQueryParameter);
  }
}
