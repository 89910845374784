export interface IAppConfig {
  signUpUrl: string;
  activeTheme: string;
  isLegeartis: boolean;
  contactEmail: string;

  features: {
    orders: {
      uploadClientMetaData: boolean;
      bulkUploadClientMetaData: boolean;
      syncClientMetaData: boolean;
      routing: {
        // When Order Route is Not Found we walk in the graphPath until it
        // finds a route that it can access
        enableRouteRecovery: boolean;
      };
    };
  };
  [key: string]: unknown; // optional properties;
}

/**
 * Configuration specific for the application (theme dependant).
 */
export const appConfig: IAppConfig = {
  signUpUrl: 'https://www.kerberos-compliance.com/unternehmen/kontakt/',
  activeTheme: 'Kerberos',
  isLegeartis: false,
  contactEmail: 'kyc-checks@kerberos-cms.com',

  features: {
    orders: {
      uploadClientMetaData: true,
      bulkUploadClientMetaData: true,
      syncClientMetaData: true,
      routing: {
        // When Order Route is Not Found we walk in the graphPath until it
        // finds a route that it can access
        enableRouteRecovery: true,
      },
    },
  },
};

export const legeartisAppConfig: IAppConfig = {
  signUpUrl: 'https://legeartis-compliance.com/kontakt/',
  activeTheme: 'Legeartis',
  isLegeartis: true,
  contactEmail: 'kyc-checks@legeartis-compliance.com',

  features: {
    orders: {
      uploadClientMetaData: true,
      bulkUploadClientMetaData: true,
      syncClientMetaData: true,
      routing: {
        // When Order Route is Not Found we walk in the graphPath until it
        // finds a route that it can access
        enableRouteRecovery: true,
      },
    },
  },
};
