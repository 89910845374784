import { LocalStorageKeys } from '@models';
import { PUBLIC_URLS } from '@navUrls';
import { LocalStorageService } from '@utilityServices/local-storage.service';

/**
 * This service helps to optimize the user experience by navigating the user
 * to the last visited page before he was logged out due to an expired session
 */
export class ContinuityService {
  /**
   * Sets the url the user should be navigated to after successful login
   * @param url Current page url
   */
  public static setUrl(url: string): void {
    // If the current page is not the authentication page (could cause endless navigation looping),
    // store the current url to try to navigate back to this page after successful login
    if (!url.startsWith(PUBLIC_URLS.authentication.getJoinedUrl({ leadingSlash: true }))) {
      LocalStorageService.add(LocalStorageKeys.CONTINUITY_URL, url);
    }
  }

  /**
   * Returns the url the user should be navigated to after successful login.
   * Only internal urls are returned to prevent navigating to external pages.
   */
  public static getUrl(): string | null {
    const continuityUrl: string = LocalStorageService.get(LocalStorageKeys.CONTINUITY_URL);

    // Remove the url from the local storage
    LocalStorageService.remove(LocalStorageKeys.CONTINUITY_URL);

    // If the url does not start with a slash it is no internal URL -> block navigating to external URLs
    if (!continuityUrl || !continuityUrl.startsWith('/')) {
      return null;
    }

    return continuityUrl;
  }
}
