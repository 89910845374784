import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IGraphPathItem, IOrderFile } from '@models';
import { ORDER_URLS, PUBLIC_URLS, RouteUrl } from '@navUrls';
import { OrderService, UserService } from '@services';
import { OrderFileService } from '@utilityServices';
import { filter, take } from 'rxjs/operators';
import { ISharedGraphPath } from '@shared/models/order/order-meta-data.type';
import { OrderGraphPathResolver } from './utility/order-graph-path-resolver.service';

/**
 * Base guard that provides the general functions for the OrderGuard
 */
@Injectable()
export abstract class BaseOrderGuard {
  protected constructor(
    protected readonly orderFileService: OrderFileService,
    protected readonly orderService: OrderService,
    protected readonly userService: UserService,
    protected readonly router: Router,
    protected readonly graphPathResolver: OrderGraphPathResolver
  ) {}

  static previousOrderPageUrl: string = undefined;

  /**
   * Checks if the order with the orderId in the url exists and if the user has completed the previous pages/tasks that leads to the current page url
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   */
  protected async checkOrder(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.waitTillReady();
    const orderId: string = route.paramMap.get('orderId');
    const orderFile: IOrderFile = await this.orderFileService.setCurrentOrderId(orderId);
    this.orderService.setCurrentOrderId(orderId);

    if (!orderFile) {
      // If no matching order was found, redirect to the dashboard page
      this.router.navigate(PUBLIC_URLS.page404.getUrlSegments()).catch();
      return false;
    }

    if (BaseOrderGuard.isNewlyCreatedOrder(orderFile, state)) {
      return true;
    }

    const graphPath: IGraphPathItem[] = this.getGraphPath(orderFile);

    const graphPathItem: IGraphPathItem = graphPath
      ?.slice()
      .reverse()
      .find((pathItem) => pathItem.url === state.url);

    // Allow navigation if the current url is found in the GraphPath, and it is accessible
    if (graphPathItem?.accessible) {
      return true;
    }

    const lastIndexInGraphPath = (graphPath?.length || 1) - 1;
    let index = lastIndexInGraphPath;
    let lastGraphPathElement: IGraphPathItem;

    // Beginning from the end of the graph path, find the first page that can be reached via navigation
    while (index > 0) {
      const currentPathItem: IGraphPathItem = graphPath?.[index];

      // If the page can be reached via navigation, navigate to the page
      if (!currentPathItem.skipOnNavigation) {
        Logger.info('NAVIGATED TO THIS PATH:', currentPathItem.url);
        lastGraphPathElement = currentPathItem;
        break;
      }

      Logger.info('SKIPPED THIS PATH:', currentPathItem.url);

      // If the page should be skipped when navigated, decrease the index and try again with the page before the page at this index
      index -= 1;
    }

    const lastIndex = !lastGraphPathElement ? lastIndexInGraphPath : index;
    await this.safeNavigate(graphPath.slice(0, lastIndex + 1).reverse(), orderId);

    return false;
  }

  protected async safeNavigate(graphPath: IGraphPathItem[], orderId: string) {
    if (!graphPath) {
      const defaultRoute = this.toRouteUrl({ orderId });
      await this.router.navigate(defaultRoute.getUrlSegments());
      return;
    }

    const possibleUrls = graphPath.filter((g) => g.accessible);
    // eslint-disable-next-line no-restricted-syntax
    for (const nextPathItem of possibleUrls) {
      const route = this.toRouteUrl({ graphItem: nextPathItem, orderId });
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.router.navigate(route.getUrlSegments(), { queryParams: route.queryParams });
        return;
      } catch (error) {
        Logger.error(`Failed to safely navigate to ${route.toString()}`, error);
      }
    }
    await this.router.navigate(PUBLIC_URLS.page404.getUrlSegments()).catch();
  }

  protected toRouteUrl(options: { graphItem?: IGraphPathItem; orderId?: string }) {
    const { graphItem } = options;
    if (graphItem) {
      return RouteUrl.fromUrlWithQueryParameter(graphItem.url);
    }
    return new RouteUrl(ORDER_URLS.create.getJoinedUrl());
  }

  protected async waitTillReady(): Promise<void> {
    await this.userService.getCurrentUser();
    await this.orderFileService.ensureLoadedOrders();
  }

  protected getGraphPath(orderFile: IOrderFile): ISharedGraphPath[] {
    return this.graphPathResolver.resolveGraphPath(orderFile);
  }

  private static isNewlyCreatedOrder(orderFile: IOrderFile, currentRoute: RouterStateSnapshot) {
    const startingUrl = ORDER_URLS.create.getJoinedUrl({ leadingSlash: true });
    return orderFile.meta.graphPath.length === 0 && currentRoute.url === startingUrl;
  }
}
