import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { environment } from '@environment';
import { KebThemeService, Themes } from '@kerberos-compliance/kerberos-fe-lib';
import { AuthService } from '@services';
import { AppConfigService } from '@shared/config/app-config.service';
import { Observable } from 'rxjs';

/**
 * Service that handles the login of the user
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  /** Uri the user should be redirected to after successful login */
  private get redirectUri(): string {
    // Use the current url without query params as redirect url
    return window.location.href.split('?')[0];
  }

  get themeColor(): string {
    return this.themeService.active === Themes.Kerberos ? '#032264' : '#ffffff';
  }

  constructor(
    private readonly authService: AuthService,
    private readonly themeService: KebThemeService,
    private readonly appConfigService: AppConfigService
  ) {}

  /**
   * Navigates user to SSO login page
   * @param options Optional login options
   * @param options.inAppRedirectUrl In-app url the user should be navigated to after successful login
   */
  public async login(options?: { inAppRedirectUrl?: string }): Promise<void> {
    const queryParams = {
      theme: this.themeService.active,
      client_id: environment.sso.clientId,
      access_type: 'offline',
      scope: environment.sso.scope,
      response_type: 'code',
      redirect_uri: this.redirectUri,
    };

    const queryParamsEncoded = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    const { loginUrl } = this.appConfigService.config;
    const url = `${loginUrl}?${queryParamsEncoded}`;

    // If the app's platform is not an app navigate in the current browser instead of opening a new tab
    window.location.href = url;
  }

  public async signUp(): Promise<void> {
    const { signUpUrl } = this.appConfigService.config;

    await Browser.open({
      url: signUpUrl,
      toolbarColor: this.themeColor,
    });
  }

  /**
   * Exchanges the code from the SSO service with the BFF
   * @param ssoCode Code from the SSO service
   */
  public exchangeToken(ssoCode: string): Observable<void> {
    return this.authService.exchangeToken(ssoCode, this.redirectUri);
  }
}
