import { Injector } from '@angular/core';
import deepmerge from 'deepmerge';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { UserService } from '@services';
import { COMBINE_MERGE } from '../../data-merger/array.merger';
import { FileSystemService } from '../file-system.service';
import { ToolsService } from '../tools.service';

/**
 * Abstract service which provides handling with setting files for different use cases.
 * <T> stands for the available settings
 */
export abstract class SettingsService<T extends object> {
  /** Id of the user. Used to be able to store settings per user */
  private userId: string;

  /** Current settings */
  private readonly settings: BehaviorSubject<Partial<T>> = new BehaviorSubject<Partial<T>>(null);
  /** Current settings as an observable */
  public readonly settings$: Observable<Partial<T>> = this.settings.asObservable();

  /** User service to get the current user id */
  private readonly userService: UserService = this.injector.get(UserService);
  /** File system service to read and write files from and in the file system */
  private readonly fileSystemService: FileSystemService = this.injector.get(FileSystemService);

  /**
   * Abstract service which provides handling with setting files for different use cases.
   * @param options Options to configure the settings service
   * @param options.settingsName Name of the settings file (without file extension)
   * @param options.defaultSettings Object with default settings
   * @param options.clearOnAppStart Whether the settings should be cleared/reset to default on app start
   * @param injector Angular injector, used to get all required dependencies
   */
  protected constructor(
    protected readonly options: {
      settingsName: string;
      defaultSettings: Partial<T>;
      clearOnAppStart?: boolean;
    },
    protected readonly injector: Injector
  ) {
    if (!options) {
      throw new Error('ERROR in SettingsService: Please provide valid options!');
    }
    // Check if a valid settings name is provided, otherwise throw an error
    if (!options.settingsName) {
      throw new Error('ERROR in SettingsService: Please provide a settingsName!');
    }
    if (!options.settingsName.match(/^[a-zA-Z]+$/g)) {
      throw new Error(
        'ERROR in SettingsService: Please provide a proper `settingsName`. It may only contain letters (lower and upper case) and no numbers, spaces or special characters!'
      );
    }

    this.userService.user$.subscribe(async (user) => {
      // Reset settings if user is undefined or userId has changed
      if (!user || this.userId !== user.userId) {
        this.settings.next(undefined);
      }
      this.userId = user?.userId;

      if (!user?.userId) {
        return;
      }

      // If the app is started (or the user has changed), reset the settings to its default values by removing the current settings
      if (options.clearOnAppStart) {
        try {
          await this.fileSystemService.fileDelete({
            path: this.getFilePath(),
          });
          Logger.info(`SettingsService: Settings '${options.settingsName}' successfully deleted`);
        } catch (e) {
          Logger.error(`Settings service:`, e);
        }
      }

      await this.loadSettings();
    });
  }

  /**
   * Loads the settings from file
   */
  private async loadSettings(): Promise<Partial<T>> {
    if (!this.userId) {
      Logger.error(`The userId is not set, so loading settings is not possible!`);
      return null;
    }

    let data: { fileContent: string | object; filePath?: string };

    // Load settings from file
    try {
      data = await this.fileSystemService.fileRead({
        path: this.getFilePath(),
        ignoreErrors: true,
      });
    } catch (e) {
      Logger.error(`Settings service:`, e);
    }

    if (data && typeof data.fileContent === 'string') {
      // Parse the string to JSON (needed for iOS and Android)
      try {
        this.settings.next(JSON.parse(data.fileContent));
      } catch (e) {
        Logger.error('INVALID JSON. File will be deleted');
        this.fileSystemService.fileDelete({ path: this.getFilePath() }).catch();
      }
    } else if (data && typeof data.fileContent === 'object') {
      // Store settings directly to variable (in web browser)
      this.settings.next(data.fileContent);
    }

    // If no settings exist, save the default settings
    if (!this.settings.value) {
      const defaultSettings = this.options.defaultSettings || {};

      await this.writeFile(defaultSettings);
      this.settings.next(defaultSettings);
    }

    return JSON.parse(JSON.stringify(this.settings.value));
  }

  /**
   * Save new setting. Deep merges with existing settings, so you only need to specify the changed settings
   * @param settings Changed settings
   */
  public async saveSetting(settings: Partial<T>): Promise<T> {
    let currentSettings = this.options.defaultSettings;

    if (this.settings.value) {
      currentSettings = deepmerge(this.options.defaultSettings, this.settings.value, {
        arrayMerge: COMBINE_MERGE,
      });
    }

    const newSettings = deepmerge(currentSettings, settings, { arrayMerge: COMBINE_MERGE });

    // If any setting has changed, save it and update the settings observable
    if (!ToolsService.isEqual(currentSettings, newSettings)) {
      await this.writeFile(newSettings);

      this.settings.next(newSettings);
    }

    return JSON.parse(JSON.stringify(newSettings));
  }

  /**
   * Writes the given data to the settings file
   * @param settings Settings that should be saved
   */
  private async writeFile(settings: Partial<T>): Promise<void> {
    await this.fileSystemService.fileWrite({
      path: this.getFilePath(),
      data: settings,
    });
  }

  /**
   * Returns the path for the settings file
   */
  private getFilePath(): string {
    if (!this.userId) {
      Logger.error(`User id is currently not set!`);
    }
    return `user/${this.userId}/settings/${this.options.settingsName}.setting`;
  }

  /**
   * Returns the current settings
   */
  protected async getCurrentSettings(): Promise<Partial<T>> {
    return this.settings
      .pipe(
        filter((settings) => !!settings),
        take(1)
      )
      .toPromise();
  }
}
