import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LibConstants, Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { MIME_TYPE } from '@models';
import { IPreviewFile } from '@shared/components/document-preview-overlay/document-preview-file.interface';
import { IDocumentPreview } from '@shared/components/document-preview-overlay/document-preview.interface';
import { FileService } from '@utilityServices/file.service';

@Component({
  selector: 'app-document-preview-overlay',
  templateUrl: './document-preview-overlay.component.html',
  styleUrls: ['./document-preview-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewOverlayComponent implements OnInit {
  /** Whether files are currently loading */
  public isLoading: boolean = true;
  /** Whether the file loading caused an error */
  public showError: boolean = false;

  /** Number of files that need to be loaded */
  private filesToLoad: number = 0;
  /** Number of files that are currently loaded */
  private filesLoaded: number = 0;

  /**
   * Whether the PDF viewer should be positioned relative. See link for more information
   * @link https://github.com/VadimDez/ng2-pdf-viewer/issues/806
   */
  public positionPdfViewerRelative: boolean = false;

  /** Reference to MIME_TYPE to be able to use it in template */
  public MIME_TYPE: typeof MIME_TYPE = MIME_TYPE;

  @Input()
  public documentToPreview: IDocumentPreview;

  /** Event emitter for close action to parent */
  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  public isSaving = false;

  public hasEmptyFiles = false;

  constructor(public readonly LC: LibConstants, private readonly changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    // Since PDF files cannot be displayed/downloaded at the moment,
    // we exclude them from the files to load to be able to set the variable `isLoading` correctly
    this.filesToLoad = this.documentToPreview.documentFiles.filter((file) =>
      this.allowedMimeTypes.includes(file.fileMimeType)
    ).length;
    this.hasEmptyFiles = false;
    this.documentToPreview.documentFiles = this.documentToPreview.documentFiles.map((file) => {
      file.fileContent = file.fileURL;
      this.hasEmptyFiles = Boolean(!file.fileURL) || this.hasEmptyFiles;
      if (file.fileMimeType === MIME_TYPE.PDF) {
        file.fileURL = FileService.convertBase64ToObjectUrl(file.fileURL);
      }
      return file;
    });

    this.reload();
  }

  /**
   * Handles the loaded event of the PDF viewer to position the viewer relative after that
   */
  public handlePdfLoaded(): void {
    this.positionPdfViewerRelative = true;

    this.handleLoaded();
  }

  /**
   * Handles the load event of the image elements
   */
  public handleLoaded(): void {
    this.filesLoaded++;
    this.isLoading = this.filesLoaded < this.filesToLoad;
  }

  /**
   * Handles the error event of the image elements
   */
  public handleError(): void {
    this.isLoading = false;
    this.showError = true;
  }

  /**
   * Try to reload the images. By setting `showError` to false the <img> is re-added to the DOM and the browser loads the images again
   */
  public reload(): void {
    this.filesLoaded = 0;
    this.isLoading = this.filesToLoad > 0;
    this.showError = false;
  }

  public isImage(previewFile: IPreviewFile): boolean {
    return [MIME_TYPE.PNG, MIME_TYPE.JPG].includes(previewFile.fileMimeType);
  }

  public navigateBack(): void {
    this.close.emit();
  }

  public canSave() {
    return !this.isSaving && !this.hasEmptyFiles;
  }

  public async save() {
    if (!this.canSave()) {
      return;
    }
    this.isSaving = true;
    this.changeDetector.markForCheck();
    try {
      const promises = this.documentToPreview.documentFiles.map(async (file) => {
        this.downloadBase64File(file);
      });

      await Promise.all(promises);
    } catch (error) {
      Logger.error(DocumentPreviewOverlayComponent.name + 'error saving: ', error);
    } finally {
      this.isSaving = false;
      this.changeDetector.markForCheck();
    }
  }

  private downloadBase64File(file: IPreviewFile) {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = file.fileContent;
    downloadLink.target = '_self';
    downloadLink.download = file.fileName;
    downloadLink.click();
    downloadLink.parentNode.removeChild(downloadLink);
  }

  /**
   * The allowed mime types for the file input selector of this component.
   */
  private get allowedMimeTypes(): MIME_TYPE[] {
    return [MIME_TYPE.JPG, MIME_TYPE.PNG, MIME_TYPE.PDF];
  }
}
