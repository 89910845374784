import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { IActionSheet, IActionSheetResult, KebActionSheetComponent } from '@kerberos-compliance/kerberos-fe-lib';

/**
 * Service to deal with the action sheet overlay state
 */
@Injectable()
export class ActionSheetOverlayService {
  constructor(private readonly overlay: Overlay) {}

  public open(actionSheet: IActionSheet): Promise<IActionSheetResult> {
    return new Promise<IActionSheetResult>((resolve) => {
      // Returns an OverlayRef (which is a PortalHost)
      const overlayRef = this.overlay.create(ActionSheetOverlayService.getOverlayConfig(this.overlay));

      // Create ComponentPortal that can be attached to a PortalHost
      const filePreviewPortal = new ComponentPortal(KebActionSheetComponent);

      // Attach ComponentPortal to PortalHost
      const componentRef = overlayRef.attach(filePreviewPortal);
      componentRef.instance.title = actionSheet.title;
      componentRef.instance.options = actionSheet.options;
      overlayRef.backdropClick().subscribe((_) => {
        overlayRef.detach();
        resolve({ index: -1 });
      });

      componentRef.instance.optionClicked.subscribe((actionSheetResult: IActionSheetResult) => {
        overlayRef.detach();
        resolve(actionSheetResult);
      });
    });
  }

  private static getOverlayConfig(overlay: Overlay): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: new GlobalPositionStrategy(),
      disposeOnNavigation: true,
      hasBackdrop: true,
      scrollStrategy: overlay.scrollStrategies.block(),
      panelClass: 'keb-custom-action-sheet-overlay',
    });
  }
}
