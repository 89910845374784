import { Injectable } from '@angular/core';
import heic2any from 'heic2any';
import { MIME_TYPE } from '../../../shared/models';
import { FileService } from './file.service';

export interface ConversionResult {
  mimeType: string;
  data: Blob;
}

@Injectable({ providedIn: 'root' })
export class ImageConversionService {
  static async convertB64ImgToJpg(data: string, mimeType: MIME_TYPE): Promise<ConversionResult> {
    const blobData: Blob = FileService.b64toBlob(data, mimeType);
    return ImageConversionService.convertImgToJpg(blobData, mimeType);
  }

  static async convertImgToJpg(data: Blob, mimeType: MIME_TYPE): Promise<ConversionResult> {
    try {
      const convertibleTypes = [MIME_TYPE.HEIC, MIME_TYPE.HEIF];
      // this library only supports converting HEIC and HEIF to JPEG.
      // If the image is not one of those, we just return the original data, otherwise it will throw an error
      if (convertibleTypes.includes(mimeType)) {
        const converted = (await heic2any({
          blob: data,
          toType: MIME_TYPE.JPG,
        })) as Blob;
        // eslint-disable-next-line no-param-reassign
        mimeType = MIME_TYPE.JPG;
        return { mimeType: MIME_TYPE.JPG, data: converted };
      }
    } catch (error) {
      const nonIgnorableError = String(error.message).indexOf('already browser readable') === -1;
      if (nonIgnorableError) {
        throw error;
      }
    }
    return { data, mimeType };
  }
}
