<keb-header class="keb-document-viewer__header">
  <span *ngIf="documentToPreview.documentTitle" (click)="save()">
    <keb-icon *ngIf="canSave()" icon="download-L"></keb-icon>
    <keb-loader *ngIf="isSaving" class="keb-order-details__loading-icon"></keb-loader>
    {{ documentToPreview.documentTitle }}
  </span>
  <keb-icon icon="close-L" slot="end" (click)="navigateBack()"></keb-icon>
</keb-header>

<div class="keb-ge__page keb-document-preview__content">
  <div
    class="keb-document-preview__image-container"
    [class.keb-document-preview__image-container--hidden]="isLoading || showError"
  >
    <ng-container *ngFor="let documentFile of documentToPreview.documentFiles">
      <h4 *ngIf="documentFile.fileTitle" class="keb-document-preview__image-title">
        {{ documentFile.fileTitle }}
      </h4>
      <img
        *ngIf="!showError && isImage(documentFile)"
        class="keb-document-preview__image"
        [src]="documentFile.fileURL"
        [alt]="documentFile.fileAlt"
        (load)="handleLoaded()"
        (error)="handleError()"
      />

      <pdf-viewer
        *ngIf="documentFile.fileMimeType === MIME_TYPE.PDF"
        class="keb-document-preview__pdf-viewer"
        [class.keb-document-preview__pdf-viewer--relative]="positionPdfViewerRelative"
        [src]="documentFile.fileURL"
        [fit-to-page]="true"
        [show-all]="true"
        (after-load-complete)="handlePdfLoaded()"
        (error)="handleError()"
      ></pdf-viewer>
    </ng-container>
  </div>

  <ng-container *ngIf="showError">
    <div class="keb-document-preview__error-container">
      <keb-icon icon="{{ LC.ICON.name.ERROR_XL }}" size="extra-large"></keb-icon>

      <p class="keb-document-preview__error-text">{{ 'documentPreview.documentCouldNotBeLoaded' | transloco }}</p>

      <div class="keb-document-preview__error-actions">
        <button kebButton color="secondary" (click)="reload()">
          <keb-icon icon="{{ LC.ICON.name.RELOAD_S }}" slot="start"></keb-icon>
          {{ 'documentPreview.reloadDocument' | transloco }}
        </button>
        <button kebButton color="secondary" [disabled]="!canSave()" (click)="save()">
          <keb-icon icon="{{ LC.ICON.name.DOWNLOAD_L }}" slot="start"></keb-icon>
          {{ 'documentPreview.download' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
