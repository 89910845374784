import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@environment';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

@Pipe({
  name: 'part',
})
export class PartPipe implements PipeTransform {
  public transform(value: string, part: number): string {
    const parts = value.split('|');

    if (part < 0 || part >= parts.length) {
      Logger.error(`PART PIPE ERROR: Given value ${value} does not have a part ${part}!`);
      return environment.production ? '' : '[PART PIPE ERROR]';
    }

    return parts[part];
  }
}
