import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

/**
 * Guard that prevents direct access to an url. Forces user to access page via internal router logic
 */
@Injectable()
export class InternalGuard implements CanActivate, CanActivateChild {
  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    Logger.log(`:: InternalGuard :: canActivate :: \n[route]: ${route}, \n[state]: ${state}`);
    return this.checkInternalRoute(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    Logger.log(`:: InternalGuard :: canActivateChild :: \n[childRoute]: ${childRoute}, \n[state]: ${state}`);
    return this.checkInternalRoute(childRoute);
  }

  private checkInternalRoute(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (!route.data.internal) {
      return true;
    }
    return this.router.getCurrentNavigation()?.extras?.state?.internal ? true : this.router.parseUrl('');
  }
}
