import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { DocumentPreviewOverlayComponent } from '@shared/components/document-preview-overlay/document-preview-overlay.component';
import { DocumentPreviewOverlayService } from '@shared/components/document-preview-overlay/document-preview-overlay.service';
import { LibModule } from '@shared/lib.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  providers: [DocumentPreviewOverlayService],
  declarations: [DocumentPreviewOverlayComponent],
  imports: [CommonModule, LibModule, TranslocoModule, PdfViewerModule],
  exports: [DocumentPreviewOverlayComponent],
})
export class DocumentPreviewOverlayModule {}
