import { Injectable } from '@angular/core';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IFileMetaInformation, IGraphPathItem } from '@models';
import { ORDER_URLS, RouteUrl } from '@navUrls';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrderFileService } from './order-file.service';

/**
 * Service that handles the meta information of the order
 */
@Injectable({
  providedIn: 'root',
})
export class FileMetaService {
  /** Id of the current order */
  private currentOrderId: string;

  /** Current data as BehaviorSubject */
  protected readonly data: BehaviorSubject<IFileMetaInformation> = new BehaviorSubject<IFileMetaInformation>(null);

  /** Current data as Observable */
  public readonly data$: Observable<IFileMetaInformation> = this.data.asObservable();

  constructor(private readonly orderFileService: OrderFileService) {
    this.orderFileService.currentOrderFile$.subscribe((currentOrder) => {
      this.currentOrderId = currentOrder?.order?.orderId;
      this.data.next(currentOrder?.meta || null);
    });
  }

  /**
   * Updates the graph path of the current order
   * @param previousUrl Previously visited page url
   * @param currentUrl Currently visited page url
   */
  public async updateGraphPath(previousUrl: string, currentUrl: string): Promise<void> {
    // Do nothing if the currentOrderId is not set or the current url does not start with the order url
    if (
      !this.currentOrderId ||
      !currentUrl.startsWith(`/${ORDER_URLS.detail.getUrlSegments({ params: { orderId: '' } })[0]}/`)
    ) {
      Logger.error(`FileMetaService: currentOrderId is not set`);
      return;
    }

    // Get current graph as deep copy
    const oldGraphPath = (this.data?.value?.graphPath || []).map((x) => ({ ...x }));

    // If the current url is already in the graph path, there is no need to add it again
    if (this.graphContainsUrl(oldGraphPath, currentUrl)) {
      if (this.isAtEndOfGraph(oldGraphPath, previousUrl) && this.isAtEndOfGraph(oldGraphPath, currentUrl, -1)) {
        return;
      }
      if (!this.isAtEndOfGraph(oldGraphPath, previousUrl)) {
        return;
      }
      if (this.isAtEndOfGraph(oldGraphPath, currentUrl)) {
        return;
      }
    }

    // Get the last index of the previous url
    const indexPreviousUrl = this.getLastIndexOf(previousUrl, oldGraphPath);

    let newGraphPath: IGraphPathItem[] = [];

    if (indexPreviousUrl >= 0) {
      // If the previous url was found in the existing graph path -> user has changed the path
      // Remove all pages behind the previous page url
      newGraphPath = oldGraphPath.slice(0, indexPreviousUrl + 1);
    } else {
      newGraphPath = oldGraphPath;
    }

    // Add current url to the end of the array
    newGraphPath.push({ url: currentUrl, accessible: true });

    // Save new graph path
    await this.orderFileService.updateOrderData(
      this.currentOrderId,
      {
        meta: { ...this.data.value, graphPath: newGraphPath },
      },
      { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
    );
  }

  /**
   * Removes the given url and all urls that comes after the given url
   * @param url Url to be removed
   */
  public async removeGraphPathUrl(url: string): Promise<boolean> {
    // Do nothing if the currentOrderId is not set or the current url does not start with the order url
    if (!this.currentOrderId) {
      Logger.error(`FileMetaService: currentOrderId is not set`);
      return false;
    }

    if (!url) {
      return false;
    }

    // Get current graph as deep copy
    const oldGraphPath = [...this.data?.value?.graphPath] || [];

    const indexOfUrlToDelete = oldGraphPath.findIndex((pathItem) => pathItem.url === url);

    if (indexOfUrlToDelete === -1) {
      Logger.error(
        `Url cannot be removed from graph path, because url ${url} could not be found in the graphPath`,
        oldGraphPath
      );
      return false;
    }

    const newGraphPath = oldGraphPath.slice(0, indexOfUrlToDelete);

    // Save new graph path
    await this.updateOrderData(newGraphPath);

    return true;
  }

  /**
   * Removes the given url and all urls that comes after the given url
   * @param url Url to be removed
   */
  public async removeGraphPathUpToUrl(url: string): Promise<boolean> {
    // Do nothing if the currentOrderId is not set or the current url does not start with the order url
    if (!this.currentOrderId) {
      Logger.error(`FileMetaService: currentOrderId is not set`);
      return false;
    }

    if (!url) {
      return false;
    }

    // Get current graph as deep copy
    const oldGraphPath = [...this.data?.value?.graphPath] || [];

    const indexOfUrlToDelete = oldGraphPath.findIndex((pathItem) => pathItem.url === url);

    if (indexOfUrlToDelete === -1) {
      Logger.error(
        `Url cannot be removed from graph path, because url ${url} could not be found in the graphPath`,
        oldGraphPath
      );
      return false;
    }

    const newGraphPath = oldGraphPath.slice(0, indexOfUrlToDelete + 1);

    // Save new graph path
    await this.orderFileService.updateOrderData(
      this.currentOrderId,
      {
        meta: { ...this.data.value, graphPath: newGraphPath },
      },
      { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
    );

    return true;
  }

  /**
   * Makes previous graph path inaccessible, so the user cannot navigate back or jump to a certain page via URL
   * @param url The current url. All previous routes are made inaccessible
   */
  public async protectPreviousGraphPath(url: RouteUrl): Promise<void> {
    // Do nothing if the currentOrderId is not set
    if (!this.currentOrderId) {
      Logger.error(`FileMetaService: currentOrderId is not set`);
      return;
    }

    const graphPath = this.data?.value?.graphPath;

    const urlIndex = this.getIndexOfGraphPathItemOfUrlByBackwardsTraversal(url);

    // Mark all elements except the last graph path element as inaccessible
    const oldGraphPath: IGraphPathItem[] = graphPath?.map<IGraphPathItem>((pathItem, index, array) => ({
      url: pathItem.url,
      // Only update routes that are currently accessible
      accessible: pathItem.accessible ? index >= urlIndex : false,
    }));

    // Save new graph path
    await this.orderFileService.updateOrderData(
      this.currentOrderId,
      {
        meta: { ...this.data.value, graphPath: oldGraphPath },
      },
      { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
    );
  }

  /**
   * Makes future/next graph path accessible again, so the user can navigate through the steps again.
   * @param url The current url. All future/next routes are made accessible
   */
  public async unprotectNextGraphPath(url: RouteUrl): Promise<boolean> {
    // Do nothing if the currentOrderId is not set
    if (!this.currentOrderId) {
      Logger.error(`FileMetaService: currentOrderId is not set`);
      return false;
    }

    const graphPath = this.data?.value?.graphPath;

    const urlIndex = this.getIndexOfGraphPathItemOfUrlByBackwardsTraversal(url);

    // Mark all elements after the current one and the current one itself as accessible
    const oldGraphPath: IGraphPathItem[] =
      graphPath?.map<IGraphPathItem>((pathItem, index, array) => ({
        url: pathItem.url,
        // Only update routes that are currently inaccessible
        accessible: !pathItem.accessible ? index >= urlIndex : true,
      })) || [];

    // Save new graph path
    await this.orderFileService.updateOrderData(
      this.currentOrderId,
      {
        meta: { ...this.data.value, graphPath: oldGraphPath },
      },
      { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
    );
    return true;
  }

  /**
   * Marks the given url in the GraphPath as skippable on navigation
   * @param url Url that should be marked as skippable
   */
  public async skipUrlOnNavigation(url: RouteUrl): Promise<void> {
    // Duplicate graphPath without reference
    const graphPath = JSON.parse(JSON.stringify(this.data?.value?.graphPath));
    const index = this.getIndexOfGraphPathItemOfUrl(url);

    if (index < 0) {
      return;
    }
    const graphPathItem = graphPath[index];
    graphPathItem.skipOnNavigation = true;

    await this.updateOrderData(graphPath);
  }

  private async updateOrderData(graphPath: IGraphPathItem[]) {
    await this.orderFileService.updateOrderData(
      this.currentOrderId,
      {
        meta: { ...this.data.value, graphPath },
      },
      { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
    );
  }

  /**
   * Find the index of the latest occurence inside the graph of the given URL
   * @param url Url whose index should be returned
   */
  private getIndexOfGraphPathItemOfUrlByBackwardsTraversal(url: RouteUrl): number {
    const graphPaths = this.data?.value?.graphPath.map((item) => RouteUrl.fromUrlWithQueryParameter(item.url));
    const lastIndex = Math.max(graphPaths.length - 1, 0);
    return lastIndex - graphPaths.reverse().findIndex((item) => item.equals(url, true));
  }

  /**
   * Returns the index of the given url in the graphPath
   * @param url Url whose index should be returned
   */
  private getIndexOfGraphPathItemOfUrl(url: RouteUrl): number {
    const graphPaths = this.data?.value?.graphPath.map((item) => RouteUrl.fromUrlWithQueryParameter(item.url));
    return graphPaths.findIndex((item) => item.equals(url, true));
  }

  private graphContainsUrl(graph: IGraphPathItem[], url: string): boolean {
    return graph.some((pathItem) => pathItem.url === url);
  }

  private isAtEndOfGraph(graph: IGraphPathItem[], url: string, offset = 0): boolean {
    const graphIndex = graph.length - 1 + offset;
    if (graph.length === 0 || graphIndex < 0) {
      return false;
    }
    return graph[graphIndex].url === url;
  }

  private getLastIndexOf(urlToFind: string, graph: IGraphPathItem[]): number {
    const tailIndex = graph.length - 1;
    return (
      tailIndex -
      graph
        .slice()
        .reverse()
        .findIndex((pathItem) => pathItem.url === urlToFind)
    );
  }
}
