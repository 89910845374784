import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { FileExtensions, MIME_TYPE } from '@models';

/** We are exporting this from here to minimize different import sources */
export { CameraSource };

/**
 * Service for some general camera functions
 */
@Injectable({
  providedIn: 'root',
})
export class CameraService {
  /**
   * Retrieves an image from the native camera or image gallery and returns it as a base64 string
   * @param source Image source (camera or photo gallery)
   */
  public async getImage(
    source: Exclude<CameraSource, CameraSource.Prompt>
  ): Promise<{ base64: string; mimeType: MIME_TYPE }> {
    const image: Photo = await Camera.getPhoto({
      source,
      quality: 60,
      resultType: CameraResultType.DataUrl,
      webUseInput: source === CameraSource.Photos, // Force a file input in the web version. Otherwise the camera overlay would be displayed
    });

    let mimeType: MIME_TYPE;

    switch (image.format) {
      case FileExtensions.JPG:
      case FileExtensions.JPEG:
        mimeType = MIME_TYPE.JPG;
        break;
      case FileExtensions.PNG:
        mimeType = MIME_TYPE.PNG;
        break;
      case FileExtensions.GIF:
        mimeType = MIME_TYPE.GIF;
        break;
      case FileExtensions.HEIC:
        mimeType = MIME_TYPE.HEIC;
        break;
      case FileExtensions.HEIF:
        mimeType = MIME_TYPE.HEIF;
        break;
      default:
        Logger.error(`Format ${image.format} is not supported!`);
        return null;
    }

    return { base64: image.dataUrl, mimeType };
  }
}
