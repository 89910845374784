import { Platform } from '@angular/cdk/platform';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { GraphModule } from '@graph';

import {
  IS_MOBILE,
  KEB_ICON_HASH,
  KebCommonModule,
  KebIconModule,
  KebLoaderModule,
} from '@kerberos-compliance/kerberos-fe-lib';
import { KYC_APP_ICON_HASH } from '@models';
import { AppConfigModule } from '@shared/config/app-config.module';
import { SharedModule } from '@shared/shared.module';
import { Settings } from 'luxon';

import { AppComponent } from './app.component';
import { ConfigResolver } from './app.component.resolver';
import { CustomErrorHandler } from './app.error-handler';
import { appRoutes } from './app.routing';
import { GuardsModule } from './core/guards/guards.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { TranslocoRootModule } from './transloco-root.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// TODO: (Steffen) Replace `kebIconModule` with `KebIconModule.forRoot()` in the imports after https://github.com/angular/angular/issues/38376 is resolved
const kebIconModule = KebIconModule.forRoot({
  icons: [
    {
      id: 'KebIcon',
      pathToSprite: 'assets/keb-icons/sprite.svg',
      svgHash: KEB_ICON_HASH,
    },
    {
      id: 'KycAppIcon',
      pathToSprite: 'assets/icons/kyc-app-sprite.svg',
      svgHash: KYC_APP_ICON_HASH,
    },
  ],
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppConfigModule,
    BrowserAnimationsModule,
    appRoutes,
    HttpClientModule,
    TranslocoRootModule,
    GuardsModule,
    kebIconModule,
    GraphModule,
    KebCommonModule,
    SharedModule,
    KebLoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ConfigResolver,
    {
      provide: APP_BASE_HREF,
      useFactory: (pl: PlatformLocation) => pl.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: IS_MOBILE,
      useFactory: (pm: Platform) => pm.ANDROID || pm.IOS,
      deps: [Platform],
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'BrowserApi',
      useValue: Browser,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  /**
   * Sets the default locale for Luxon
   */
  constructor() {
    Settings.defaultLocale = 'de';
  }
}
