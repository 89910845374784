import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LibConstants, Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { IActorDocument, IDocumentFile, MIME_TYPE } from '@models';
import { FileService } from '@utilityServices/file.service';

/**
 * Extends the objects in the file array by an objectUrl value, that is needed for the PDF preview
 */
interface IExtendedActorDocument extends IActorDocument {
  files: (IDocumentFile & { objectUrl?: string })[];
}

/**
 * This component displays previews of all files of a given document
 */
@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewComponent {
  /** Document from which files are to be displayed */
  @Input()
  set actorDocument(document: IActorDocument) {
    this.extendedActorDocument = document;

    // Since PDF files cannot be displayed/downloaded at the moment,
    // we exclude them from the files to load to be able to set the variable `isLoading` correctly
    this.filesToLoad = this.extendedActorDocument?.files?.filter((file) =>
      [MIME_TYPE.JPG, MIME_TYPE.PNG, MIME_TYPE.GIF, MIME_TYPE.PDF].includes(file.mimeType)
    ).length;

    this.extendedActorDocument.files = this.extendedActorDocument.files.map((file) => {
      if (file.mimeType === MIME_TYPE.PDF) {
        file.objectUrl = FileService.convertBase64ToObjectUrl(file.base64);
      }
      return file;
    });

    this.reload();
  }
  /** Internal document reference */
  public extendedActorDocument: IExtendedActorDocument;

  /** Number of files that need to be loaded */
  private filesToLoad: number = 0;
  /** Number of files that are currently loaded */
  private filesLoaded: number = 0;
  /** Whether files are currently loading */
  public isLoading: boolean = true;

  /** Whether the file loading caused an error */
  public showError: boolean = false;

  /**
   * Whether the PDF viewer should be positioned relative. See link for more information
   * @link https://github.com/VadimDez/ng2-pdf-viewer/issues/806
   */
  public positionPdfViewerRelative: boolean = false;

  /** Reference to MIME_TYPE to be able to use it in template */
  public MIME_TYPE: typeof MIME_TYPE = MIME_TYPE;

  constructor(public readonly LC: LibConstants) {}

  /**
   * Handles the loaded event of the PDF viewer to position the viewer relative after that
   */
  public handlePdfLoaded(): void {
    this.positionPdfViewerRelative = true;

    this.handleLoaded();
  }

  /**
   * Handles the load event of the image elements
   */
  public handleLoaded(): void {
    this.filesLoaded++;
    this.isLoading = this.filesLoaded < this.filesToLoad;
  }

  /**
   * Handles the error event of the image elements
   */
  public handleError(error?: unknown): void {
    this.isLoading = false;
    this.showError = true;
    Logger.error(`Document preview error: ${error}`);
  }

  /**
   * Try to reload the images. By setting `showError` to false the <img> is re-added to the DOM and the browser loads the images again
   */
  public reload(): void {
    this.filesLoaded = 0;
    this.isLoading = this.filesToLoad > 0;
    this.showError = false;
  }
}
