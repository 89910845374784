import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { OrderService } from '@services';
import { IOrder, OrderStatus, OrderType } from '@models';
import { BaseResolver } from './base-resolver.guard';

@Injectable()
export class OrderResolver extends BaseResolver<IOrder> {
  constructor(router: Router, private readonly orderService: OrderService) {
    super(router, OrderResolver.name);
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IOrder> {
    Logger.log(`:: OrderResolver :: resolve :: \n[route]: ${route}, \n[state]: ${state}`);
    const orderId: string = route.paramMap.get('orderId');
    return this.orderService.get(orderId).then(
      (fulfilled) => {
        Logger.log(`:: OrderResolver :: resolve :: Order loaded: ${orderId}`);
        if (!OrderResolver.validateOrder(fulfilled, route)) {
          this.navigateToNotFound();
          return Promise.reject(new Error(`Invalid Order for request (check/status/actor).`));
        }
        return fulfilled;
      },
      (reason) => {
        this.handleError(reason);
        return Promise.reject(reason);
      }
    );
  }

  // ===============
  // Implementation
  // ===============
  /**
   * Validate `order` according to the current request.
   *
   * @param order
   * @param route
   * @private
   * @see validateCheck
   * @see validateActor
   * @see validateDocument
   * @see validateStatus
   */
  private static validateOrder(order: IOrder, route: ActivatedRouteSnapshot) {
    return (
      OrderResolver.validateCheck(order, route.paramMap.get('checkId')) &&
      OrderResolver.validateActor(order, route.paramMap.get('actorId'), route.paramMap.get('relatedActorId')) &&
      OrderResolver.validateDocument(order, route.paramMap.get('actorId'), route.paramMap.get('documentId')) &&
      OrderResolver.validateStatus(order, route.data?.status) &&
      OrderResolver.validateType(order, route.data?.type)
    );
  }

  /**
   * Check if checkId exists in current Order.
   *
   * **Note:** If `checkId` is `undefined` always return `true`.
   * @private
   */
  private static validateCheck(order: IOrder, checkId: string | undefined): boolean {
    const result = checkId ? !!order.checks.find((check) => check.checkId === checkId) : true;
    if (!result) {
      Logger.error(`:: OrderResolver :: validateCheck :: [checkId]: ${checkId} not found in Order.`);
    }
    return result;
  }

  /**
   * Check if actorId and relatedActorId is present in current Order.
   *
   * **Note:** If `actorId` and `relatedActorId` are `undefined` always return `true`.
   * @private
   */
  private static validateActor(order: IOrder, actorId: string, relatedActorId: string): boolean {
    if (!relatedActorId && !actorId) {
      return true;
    }

    if (relatedActorId && !actorId) {
      Logger.error(
        `:: OrderResolver :: validateActor :: [relatedActorId]: ${relatedActorId}, but no actorId is defined.`
      );
      return false;
    }

    const actorCheck = order.checks.find((check) => check.actor.actorId === actorId);
    if (!actorCheck) {
      Logger.error(`:: OrderResolver :: validateActor :: [actorId]: ${actorId} not found in Order.`);
      return false;
    }

    if (relatedActorId) {
      const relatedActor = actorCheck.actor.relatedActors.some(
        (actorToActor) => actorToActor.actor.actorId === relatedActorId
      );

      if (!relatedActor) {
        Logger.error(`:: OrderResolver :: validateActor :: [relatedActorId]: ${relatedActorId} not found in Order.`);
        return false;
      }
    }

    return true;
  }

  /**
   * Check if documentId is present in current Order.
   *
   * **Note:** If `documentId` is `undefined` always return `true`.
   * @private
   */
  private static validateDocument(order: IOrder, actorId: string, documentId: string): boolean {
    if (!documentId) {
      return true;
    }

    if (!actorId) {
      Logger.error(`:: OrderResolver :: validateDocument :: [documentId]: ${documentId}, but no actorId is defined.`);
      return false;
    }

    const actorCheck = order.checks.find((check) => check.actor.actorId === actorId);
    return actorCheck.documents?.some((document) => document.documentId === documentId);
  }

  /**
   * Check if Order `status` matches current Order.
   * **Note:** If `status` is `undefined` always return `true`.
   * @private
   */
  private static validateStatus(
    order: IOrder,
    status?: OrderStatus | [OrderStatus] | ((OrderStatus) => boolean)
  ): boolean {
    // Not defined, as such no restrictions
    if (!status) {
      return true;
    }

    if (typeof status === 'function') {
      return (status as (OrderStatus) => boolean)(order.status);
    }

    if (Array.isArray(status)) {
      return (status as [OrderStatus]).includes(order.status);
    }

    return order.status === status;
  }

  /**
   * Check if Order `type` matches current Order.
   * **Note:** If `type` is `undefined` always return `true`.
   * @private
   */
  private static validateType(order: IOrder, type?: OrderType | [OrderType] | ((OrderType) => boolean)): boolean {
    // Not defined, as such no restrictions
    if (!type) {
      return true;
    }

    if (typeof type === 'function') {
      return (type as (OrderStatus) => boolean)(order.type);
    }

    if (Array.isArray(type)) {
      return (type as [OrderType]).includes(order.type);
    }

    return order.type === type;
  }
}
