import { NgModule } from '@angular/core';
import { TRANSLOCO_CONFIG, translocoConfig, TranslocoModule } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { environment } from './../environments/environment';

@NgModule({
  imports: [TranslocoMessageFormatModule.init()],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: () =>
        translocoConfig({
          availableLangs: ['de', 'en', 'el'],
          defaultLang: 'de',
          fallbackLang: ['de'],
          reRenderOnLangChange: true,
          prodMode: environment.production,
          flatten: {
            aot: environment.flattenTranslations,
          },
          missingHandler: {
            allowEmpty: true,
          },
        }),
    },
  ],
})
export class TranslocoRootModule {}
