import { Injectable } from '@angular/core';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { ActorDocumentType, ICheck, IdentMethodType, IOrder, IOrderFile, OrderStatus, OrderType } from '@models';
import { ISharedGraphPath } from '@shared/models/order/order-meta-data.type';
import { CreateActorAs } from '@services';

@Injectable()
export class GraphPathService {
  /**
   * Generate graphPath of Order (without any graphPath).
   * Generated graphPath is  deduced solely on current Order data.
   * @param file Order file that will be used to deduce expected graphPath.
   */
  public generateGraphPath(file: IOrderFile): ISharedGraphPath[] | undefined {
    const { order, checks } = file;
    if (order.status !== OrderStatus.INCOMPLETE) {
      Logger.warn(`Order already is not incomplete.`);
      return undefined;
    }

    return GraphPathService.isCorporate(order)
      ? GraphPathService.generateCorporateOrderGraphPath(order, checks)
      : GraphPathService.generateIndividualOrderGraphPath(order, checks);
  }

  // ===============
  // Implementation
  // ===============
  private static generateIndividualOrderGraphPath(order: IOrder, checks: ICheck[]): ISharedGraphPath[] {
    if (GraphPathService.hasKYCFiles(order)) {
      Logger.log(`Order is in KYC files stage. [orderId]: ${order.orderId}.`);
      return GraphPathService.createAddDocumentsGraphPath(order);
    }

    if (GraphPathService.hasUbos(checks)) {
      Logger.log(`Order is UBO files stage. [orderId]: ${order.orderId}.`);
      return GraphPathService.createUboListGraphPath(order);
    }

    if (checks?.length > 0) {
      Logger.log(`Order has one or multiple (none is ubo) checks. [orderId]: ${order.orderId}.`);
      return GraphPathService.createIdentGraphPath(order, checks);
    }

    Logger.log(`Order has no checks. [orderId]: ${order.orderId}.`);
    return GraphPathService.createPreIdentGraphPath(order);
  }

  private static generateCorporateOrderGraphPath(order: IOrder, checks: ICheck[]): ISharedGraphPath[] | undefined {
    // corp orders cannot add KYCFiles so this should always return false
    if (GraphPathService.hasKYCFiles(order)) {
      Logger.error(
        `Corp order has KYC files which should not be possible, aborting migration for this order. [orderId]: ${order.orderId}.`
      );
      return undefined;
    }

    // corp orders cannot add ubos and therefore this should never happen
    if (GraphPathService.hasUbos(checks)) {
      Logger.error(
        `Corp order has ubo which should not be possible, aborting migration for this order. [orderId]: ${order.orderId}.`
      );
      return undefined;
    }

    // checks > 1 means that we have the initial corp check and the ident check
    if (checks?.length > 1) {
      Logger.log(`Order has multiple checks and therefore an ident. [orderId]: ${order.orderId}.`);
      return GraphPathService.createIdentGraphPath(order, checks);
    }

    if (checks?.length === 0) {
      // no checks
      Logger.log(`Order has no checks. [orderId]: ${order.orderId}.`);
      return GraphPathService.createCorpInitGraphPath(order.orderId);
    }

    Logger.log(`Order has one check. [orderId]: ${order.orderId}.`);
    // first check is corp itself, so the user added a corp so far
    const corpCheck = checks[0];
    if (corpCheck.actor.type !== 'CORPORATE') {
      Logger.error('actor is not corporate?');
      return undefined;
    }
    // legal reps
    const { relatedActors } = corpCheck.actor;

    if (relatedActors?.length > 0) {
      Logger.log(`Order has related actors. Therefore is on preIdent phase. [orderId]: ${order.orderId}.`);
      return GraphPathService.createPreIdentGraphPath(order);
    }

    if (GraphPathService.hasRegisterDocument(checks) || GraphPathService.hasCorporateDetails(checks)) {
      Logger.log(
        `Order has no related actors but register document or corporate details. Therefore on corporate register. [orderId]: ${order.orderId}.`
      );
      return GraphPathService.createCorpRegisterDataGraphPath(order.orderId);
    }

    Logger.log(
      `Order has no related actors and no corporate document or details. Therefore corporate registration available. [orderId]: ${order.orderId}.`
    );
    return GraphPathService.createCorpRegistrationAvailableGraphPath(order.orderId);
  }

  /**
   * Returns `true` if Order has any uploaded files.
   * @param order Order to very existing files.
   * @private
   */
  private static hasKYCFiles(order: IOrder): boolean {
    return order.kycFiles?.length > 0;
  }

  /**
   * Returns `true` if Order has any UBO Checks.
   * @param checks Order Checks to verify if any is related to an UBO.
   * @private
   */
  private static hasUbos(checks: ICheck[]): boolean {
    return checks?.some(GraphPathService.isUboCheck) ?? false;
  }

  private static hasRegisterDocument(checks: ICheck[]): boolean {
    return checks?.some(
      (check) =>
        check.documents?.find(
          (document) => document.type === ActorDocumentType.CORPORATE_COMMERCIAL_REGISTER_DOCUMENT
        ) !== undefined
    );
  }

  private static hasCorporateDetails(checks: ICheck[]): boolean {
    return !!checks?.find((check) => check.actor.corporateDetails !== null);
  }

  /**
   * Returns `true` if check:
   * * `isBeneficialOwner`: true,
   * * `isActualContractPartner`: false
   *
   * @param check Order's Check to verify if it's related to an UBO.
   * @private
   */
  private static isUboCheck(check: ICheck): boolean {
    const individualDetails = check.actor?.individualDetails;
    return individualDetails?.isBeneficialOwner && !individualDetails?.isActualContractPartner;
  }

  private static isCorporate(order: IOrder): boolean {
    return order.type === OrderType.CORPORATE;
  }

  private static createAddDocumentsGraphPath(order: IOrder): ISharedGraphPath[] {
    return GraphPathService.generateIndividualBeneficialOwnerListGraph(order.orderId, true).concat(
      GraphPathService.generateAdditionalDocumentsGraph(order.orderId, true)
    );
  }

  private static createUboListGraphPath(order: IOrder): ISharedGraphPath[] {
    return GraphPathService.generateIndividualBeneficialOwnerListGraph(order.orderId, true);
  }

  private static createIdentGraphPath(order: IOrder, checks: ICheck[]): ISharedGraphPath[] {
    const sortedChecks: ICheck[] = [];
    Object.assign(sortedChecks, checks);
    sortedChecks.sort((checkA, checkB) => new Date(checkB.createdAt).getTime() - new Date(checkA.createdAt).getTime());
    const latestCheck = sortedChecks[0];

    // Manual Ident
    if (!latestCheck.identMethodType) {
      return this.handleManualIdent(order, latestCheck);
    }

    return GraphPathService.generateIdentStatusGraph(order, latestCheck.checkId, latestCheck.identMethodType);
  }

  private static handleManualIdent(order: IOrder, latestCheck: ICheck) {
    if (latestCheck?.result === 'SUCCESS') {
      return GraphPathService.isCorporate(order)
        ? GraphPathService.generateSignatureGraph(order.orderId)
        : GraphPathService.generateIndividualContractualPartnerListGraph(order.orderId);
    }
    if (latestCheck?.documents?.length > 0) {
      return GraphPathService.generateExpiryGraph(order);
    }
    return GraphPathService.generateManualIdentSourceSelect(order);
  }

  // =====================
  // GraphPath Generators
  // =====================
  private static generateIndividualBeneficialOwnerListGraph(orderId: string, isAccessible = false): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/individual-beneficial-owner-list`,
        accessible: isAccessible,
      },
    ];
  }

  private static generateAdditionalDocumentsGraph(orderId: string, isAccessible = false): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/additional-documents-list`,
        accessible: isAccessible,
      },
    ];
  }

  private static generateIdentStatusGraph(
    order: IOrder,
    checkId: string,
    identType: IdentMethodType
  ): ISharedGraphPath[] {
    return [
      {
        url: `/order/${order.orderId}/ident/${
          identType === IdentMethodType.AUTO_IDENT ? 'auto' : 'video'
        }/check/${checkId}/status${GraphPathService.getIdentGetParam(order)}`,
        accessible: true,
      },
    ];
  }

  private static generateIdentGraph(order: IOrder): ISharedGraphPath[] {
    return [
      {
        url: `/order/${order.orderId}/ident${GraphPathService.getIdentGetParam(order)}`,
        accessible: true,
      },
    ];
  }

  private static generatePreIdentGraph(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/order-type`,
        accessible: true,
      },
    ];
  }

  private static generateExpiryGraph(order: IOrder): ISharedGraphPath[] {
    return [
      {
        url: `/order/${order.orderId}/ident-expiry-date${GraphPathService.getIdentGetParam(order)}`,
        accessible: true,
      },
    ];
  }

  private static generateIndividualContractualPartnerListGraph(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/individual-contractual-partner-list`,
        accessible: true,
      },
    ];
  }

  private static generateManualIdentSourceSelect(order: IOrder): ISharedGraphPath[] {
    return [
      {
        url: `/order/${order.orderId}/ident/manual/source-select${GraphPathService.getIdentGetParam(order)}`,
        accessible: true,
      },
    ];
  }

  private static createCorpRegisterDataGraphPath(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/corp-company-registration-data`,
        accessible: true,
      },
    ];
  }

  private static createCorpInitGraphPath(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/corp-base-data`,
        accessible: true,
      },
    ];
  }

  private static createCorpRegistrationAvailableGraphPath(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/corp-base-data`,
        accessible: true,
      },
      {
        url: `/order/${orderId}/corp-company-registration-available`,
        accessible: true,
      },
    ];
  }

  private static createPreIdentGraphPath(order: IOrder): ISharedGraphPath[] {
    return this.isCorporate(order)
      ? GraphPathService.generateIdentGraph(order)
      : GraphPathService.generatePreIdentGraph(order.orderId).concat(GraphPathService.generateIdentGraph(order));
  }

  private static generateSignatureGraph(orderId: string): ISharedGraphPath[] {
    return [
      {
        url: `/order/${orderId}/order-signature-confirmation`,
        accessible: true,
      },
    ];
  }

  /**
   * Returns the GET parameter depending on the isCorporate() state
   * Corporate flow does not use this GET parameter currently
   * @param order
   * @returns
   */
  private static getIdentGetParam(order: IOrder) {
    return GraphPathService.isCorporate(order) ? '' : `?createActorAs=${CreateActorAs.CONTRACTUAL_PARTNER}`;
  }
}
