import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '@shared/config/app-config.service';
import { BaseBackendService } from './base-backend.service';

/**
 * Backend service for requests to the Backend for Frontend
 */
@Injectable({
  providedIn: 'root',
})
export class BffService extends BaseBackendService {
  constructor(protected readonly http: HttpClient, protected appConfigService: AppConfigService) {
    super(appConfigService);
  }
}
