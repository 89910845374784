import { Injectable, Injector, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { initializeApp } from 'firebase/app';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
  RegistrationError,
} from '@capacitor/push-notifications';
import { BffService } from '@http/bff.service';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import {
  CapacitorPlatform,
  IApiResponse,
  IPushNotificationPayload,
  IPushNotificationSettings,
  ISOLanguageCodes,
  PushNotificationChannel,
} from '@models';
import { PushNotificationUrl } from '@serviceUrls';
// noinspection ES6PreferShortImport Prevents circular dependencies d

import { environment } from '@environment';
import { SettingsService } from '../settings/settings.service';
import { PushNotificationHandler } from './push-notification.handler';

/** Error message that is thrown on non-mobile platforms. Used to filter out this error message */
const NO_WEB_IMPLEMENTATION_ERROR = 'PushNotifications does not have web implementation.';

/**
 * This service handles the un-/registering for receiving push notifications and reacts on clicks on the notifications
 */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService extends SettingsService<IPushNotificationSettings> {
  /** Current FCM (Firebase Cloud Messaging) token. Needed to un-/register the device for receiving push notifications */
  private readonly fcmToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /** Whether push notifications can be received */
  private readonly permissionGranted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /** Whether push notifications can be received as observable */
  public readonly permissionGranted$: Observable<boolean> = this.permissionGranted.asObservable();

  constructor(
    protected readonly injector: Injector,
    private readonly bffService: BffService,
    private readonly translocoService: TranslocoService,
    private readonly pushNotificationHandler: PushNotificationHandler,
    private readonly zone: NgZone
  ) {
    super(
      {
        settingsName: 'pushNotifications',
        defaultSettings: {
          channels: { IDENT_STATUS_CHANGED: true, ORDER_STATUS_CHANGED: true },
          askedForPermission: false,
          preferredLanguage: ISOLanguageCodes.EN,
        },
        clearOnAppStart: false,
      },
      injector
    );

    this.initializeCapacitorPlugin();
  }

  private initializeCapacitorPlugin() {
    // Currently Capacitor push notifications only work in the native apps.
    if (!Capacitor.isNativePlatform()) {
      // for web we use firebase fcm native integration
      this.requestPermissionsBrowser();
      return;
    }

    this.updateChannelName().catch((e) => Logger.error(e));

    // Add several listeners for handling push notifications.
    // We have to `.bind(this)` to have the right context in the called function
    PushNotifications.addListener('registration', this.handleRegistration.bind(this));
    PushNotifications.addListener('pushNotificationReceived', this.pushNotificationReceived.bind(this));
    PushNotifications.addListener('pushNotificationActionPerformed', this.pushNotificationActionPerformed.bind(this));

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: RegistrationError) => {
      Logger.error(`Error on PushNotifications registration: ${JSON.stringify(error, null, 2)}`);
    });
  }

  /**
   * Requests permission to receive push notifications in the browser
   *  using the browser's native API and firebase intergrated messaging
   */
  private async requestPermissionsBrowser(): Promise<boolean> {
    initializeApp(environment.firebaseConfig);

    const messaging = getMessaging();

    let permissionGranted = false;

    try {
      // If a notification permission isn't already granted, this method asks the user for permission. The
      //  * returned promise rejects if the user does not allow the app to show notifications.
      const token = await getToken(messaging, {
        // @ts-ignore
        vapidKey: environment.firebaseConfig?.vapidKey,
      });

      if (token) {
        Logger.log('FCM token received!');
        this.handleRegistration({ value: token });
        permissionGranted = true;

        this.listenToBrowserNotifications();
      } else {
        this.unregisterFcmBrowser();
      }
    } catch (error) {
      Logger.error(`Error on PushNotifications registration: ${JSON.stringify(error, null, 2)}`);
      this.unregisterFcmBrowser();
    }

    return permissionGranted;
  }

  private unregisterFcmBrowser() {
    if (this.fcmToken.value) {
      this.unregister(this.fcmToken.value);
    }
  }

  private listenToBrowserNotifications() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      Logger.log('Message received. ', payload);

      const notification = {
        notification: payload.notification,
        data: payload.data,
        title: payload.notification.title,
        description: payload.notification.body,
      } as unknown as PushNotificationSchema;

      this.pushNotificationReceived(notification);
    });
  }

  /**
   * Requests permission to receive push notifications.
   * A modal to enable/disable notifications is shown when this function is called for the first time
   */
  public async requestPermission(): Promise<boolean> {
    let permissionGranted = false;

    if (Capacitor.isNativePlatform()) {
      try {
        const result = await PushNotifications.requestPermissions();
        permissionGranted = result.receive === 'granted';
      } catch (e) {
        if (e !== NO_WEB_IMPLEMENTATION_ERROR) {
          Logger.error(`Push Notification Error: Request permission has failed.`, e);
        }
      }
    } else {
      permissionGranted = await this.requestPermissionsBrowser();
    }

    this.permissionGranted.next(permissionGranted);

    const channels = this.getActivePushNotificationChannels((await this.getCurrentSettings())?.channels);

    // Register push notifications only if the user has granted the necessary permissions and
    // he has subscribed to at least one push notification channel
    if (permissionGranted && channels.length > 0) {
      try {
        await PushNotifications.register();
        // Wait until a FCM token is received
        await this.fcmToken
          .pipe(
            filter((token) => !!token),
            take(1)
          )
          .toPromise();
      } catch (e) {
        Logger.error('Push Notification Error: Registration of the device has failed.', e);
      }
    } else {
      if (!permissionGranted) {
        Logger.info('Permissions for push notifications not granted.');
      } else {
        Logger.info('User has not subscribed to any push notification channel.');
      }
      if (this.fcmToken.value) {
        try {
          await this.unregister(this.fcmToken.value);
        } catch (e) {
          Logger.error('Push Notification Error: Unregistration of the device has failed.', e);
        }
      }
    }

    if (!(await this.getCurrentSettings()).askedForPermission) {
      await this.saveSetting({ askedForPermission: true });
    }

    return permissionGranted;
  }

  /**
   * Saves the settings locally and in the backend
   * @param settings Settings that should be saved
   */
  public async saveSetting(settings: Partial<IPushNotificationSettings>): Promise<IPushNotificationSettings> {
    const currentSettings = await this.getCurrentSettings();
    const newSettings = await super.saveSetting(settings);
    const channels = this.getActivePushNotificationChannels(newSettings?.channels);

    // If the user has not activated at least one channel, we unregister the device completely
    if (channels.length === 0) {
      try {
        const fcmToken = await this.getFcmToken();

        if (fcmToken) {
          await this.unregister(fcmToken);
        } else {
          Logger.error(`Push Notification Error: No FCM token available`);
        }
      } catch (e) {
        // Reset settings to previous settings if the unregister request fails
        await super.saveSetting(currentSettings);
        throw e;
      }
      return newSettings;
    }
    try {
      const fcmToken = await this.getFcmToken();
      if (fcmToken) {
        await this.postSettings(channels, fcmToken);
      }
    } catch (e) {
      // Reset settings to previous settings if updating the settings fails
      await super.saveSetting(currentSettings);
      throw e;
    }

    return newSettings;
  }

  /**
   * Posts channel settings to the backend
   * @param channels Array of channels that should be subscribed
   * @param fcmToken FcmToken
   */
  private async postSettings(channels: PushNotificationChannel[], fcmToken: string): Promise<IApiResponse<unknown>> {
    return this.bffService
      .post({
        url: PushNotificationUrl.register(),
        body: {
          pushNotificationChannels: channels,
          fcmToken,
          preferredLanguage: this.translocoService.getActiveLang(),
        },
      })
      .toPromise();
  }

  /**
   * Returns the FCM token of this device
   */
  private async getFcmToken(): Promise<string> {
    if (this.fcmToken.value) {
      return this.fcmToken.value;
    }

    const permissionGranted = await this.requestPermission();

    if (!permissionGranted) {
      return null;
    }

    return this.fcmToken
      .pipe(
        filter((token) => !!token),
        take(1)
      )
      .toPromise();
  }

  /**
   * Unregisters the given FCM token from receiving push notifications
   * @param fcmToken The Firebase Cloud Messaging token that should be unregistered
   */
  private async unregister(fcmToken: string): Promise<void> {
    await this.bffService.delete({ url: PushNotificationUrl.unregister(fcmToken) }).toPromise();
    if (Capacitor.isNativePlatform()) {
      await PushNotifications.removeAllDeliveredNotifications();
    }
  }

  /**
   * Updates the name of the notification channel that is displayed in the system settings of the app
   */
  private async updateChannelName(): Promise<void> {
    // The channel feature is only available on android, so skip on other platforms
    if (Capacitor.getPlatform() !== CapacitorPlatform.ANDROID) {
      return;
    }

    try {
      // Delete the automatically created default channel
      await PushNotifications.deleteChannel({
        id: 'default',
      });
    } catch (e) {
      if (e !== NO_WEB_IMPLEMENTATION_ERROR) {
        Logger.error(e);
      }
    }

    // Wait for translations to be loaded to change the name of the notification channel according to the current language
    this.translocoService.events$
      .pipe(
        filter((event) => event.type === 'translationLoadSuccess'),
        take(1)
      )
      .subscribe(async () => {
        // Overwrite the channel name with the correct translation
        // This channel was already created in `android/app/src/main/java/com/kerberos/kyc/MainActivity.java`
        await PushNotifications.createChannel({
          id: 'notifications',
          name: this.translocoService.translate('general.pushNotifications.notificationChannelName'),
          importance: 5,
        });
      });
  }

  /**
   * Transforms the object structured channel settings into an array of channels the user wants to subscribe to
   * @param channels Push notification channels in key-value form
   */
  private getActivePushNotificationChannels(
    channels: {
      [key in keyof typeof PushNotificationChannel]?: boolean;
    } = {}
  ): PushNotificationChannel[] {
    try {
      return Object.entries(channels)
        .filter(([, value]) => value === true) // Only channels that are enabled
        .map(([key]) => key as PushNotificationChannel); // Only the name of the channel, not the enabled state
    } catch (e) {
      return [];
    }
  }

  /**
   * Method called when a FCM token is registered
   * @param token FCM token
   */
  private async handleRegistration(token: Token): Promise<void> {
    if (!token?.value) {
      Logger.error('Push registration not successful, received data token: ', JSON.stringify(token));
      return;
    }
    Logger.log(`Push registration success`);
    this.fcmToken.next(token.value);

    const channels = this.getActivePushNotificationChannels((await this.getCurrentSettings())?.channels);

    try {
      if (channels.length === 0) {
        await this.unregister(token.value);
      } else {
        await this.postSettings(channels, token.value);
      }
    } catch (e) {
      Logger.error(e);
    }
  }

  /**
   * Method called if the app is open when receiving a notification
   * @param notification Notification data
   */
  private async pushNotificationReceived(notification: PushNotificationSchema): Promise<void> {
    Logger.log(`Push received: ${JSON.stringify(notification)}`);

    const notificationPayloadString: string = notification?.data?.payload;
    if (!notificationPayloadString) {
      Logger.error('Push notification does not have any payload data');
      return;
    }

    try {
      const notificationPayload: IPushNotificationPayload = JSON.parse(notificationPayloadString);
      await this.zone.run(async () => {
        await this.pushNotificationHandler.handlePushNotificationReceive(notificationPayload);
      });
    } catch (e) {
      Logger.error(e);
    }
  }

  /**
   * Method called when tapping on a notification
   * @param notification Notification data
   */
  private async pushNotificationActionPerformed(notification: ActionPerformed): Promise<void> {
    Logger.log(`Push action performed: ${JSON.stringify(notification)}`);

    const notificationPayloadString: string = notification?.notification?.data?.payload;
    if (!notificationPayloadString) {
      Logger.error('Push notification does not have any payload data');
      return;
    }

    try {
      const notificationPayload: IPushNotificationPayload = JSON.parse(notificationPayloadString);
      await this.zone.run(async () => {
        await this.pushNotificationHandler.handlePushNotification(notificationPayload);
      });
    } catch (e) {
      Logger.error(e);
    }
  }
}
