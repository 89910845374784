<nav *transloco="let t; read: 'menuBar'" class="keb-navigation">
  <a
    *ngFor="let item of navItems"
    class="keb-navigation__item"
    [class.keb-navigation__item--disabled]="item.disabled && item.disabled()"
    [disabled]="item.disabled && item.disabled()"
    routerLink="{{ item.url }}"
    routerLinkActive="keb-navigation__item--active"
    (click)="item.clickFn && item.clickFn()"
  >
    <keb-icon
      class="keb-navigation__item-icon"
      icon="{{ item.icon }}"
      attr.data-icon="{{ item.icon }}"
      size="medium"
    ></keb-icon>
    <ng-container *ngIf="item.label">
      {{ t(item.label) }}
    </ng-container>
  </a>
</nav>
