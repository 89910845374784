import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ActionSheetOverlayService } from '@shared/components/action-sheet-overlay/action-sheet-overlay.service';

import { LibModule } from '@shared/lib.module';
import { KebPipeModule } from '../../pipes/pipes.module';
import { OrderListItemComponent } from './order-list-item.component';

@NgModule({
  declarations: [OrderListItemComponent],
  providers: [
    ActionSheetOverlayService,
    {
      provide: APP_BASE_HREF,
      useFactory: (pl: PlatformLocation) => pl.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
  ],
  imports: [CommonModule, LibModule, KebPipeModule, TranslocoModule],
  exports: [OrderListItemComponent],
})
export class OrderListItemModule {}
