import { PlatformLocation } from '@angular/common';
import { ApplicationRef, NgModule } from '@angular/core';
import { AppConfigService } from './app-config.service';

@NgModule({
  providers: [
    { provide: AppConfigService, useFactory: AppConfigService.singleton, deps: [ApplicationRef, PlatformLocation] },
  ],
})
export class AppConfigModule {}
