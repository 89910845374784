import { BackendService, BasicUrl, KebUrl } from './basic.url';

/**
 * Urls for authentication requests
 */
export class AuthUrl extends BasicUrl {
  /**
   * Exchange auth token
   */
  public static exchangeToken(): KebUrl {
    return this.getCompleteUrl(BackendService.AUTH, ['exchange']);
  }

  /**
   * Log out user
   */
  public static logout(): KebUrl {
    return this.getCompleteUrl(BackendService.AUTH, ['logout']);
  }

  /**
   * Make current session shareable
   */
  public static createSharedSession(): KebUrl {
    return this.getCompleteUrl(BackendService.AUTH, 'create-shared-session');
  }
}
