import { ComparableType, CompareFn, Dict, PropMapper } from '../types';

//
export function keyValueArrayToObjectMapper<V = string, DEFAULT_VALUE = V>(
  options: { defaultValue?: V } = {}
): (keyValue: unknown[]) => Dict<V | DEFAULT_VALUE> {
  const { defaultValue } = options;
  return (keyValue) => {
    const key = String(keyValue[0]);
    const value = keyValue[1] === undefined ? defaultValue : keyValue[1];
    return { [key]: value } as Dict<V>;
  };
}

export function sortByProp<T extends object>(
  propMapper: PropMapper<T, ComparableType>,
  ascending = true
): CompareFn<T> {
  return (left: T, right: T) => {
    const leftProp = propMapper(left);
    const rightProp = propMapper(right);
    const sortDirection = ascending ? 1 : -1;

    if (typeof leftProp === 'number' || typeof rightProp === 'number') {
      return (Number(leftProp) - Number(rightProp)) * sortDirection;
    } else if (typeof leftProp === 'string' || typeof rightProp === 'string') {
      return String(leftProp)?.localeCompare(String(rightProp)) * sortDirection;
    }

    if (leftProp < rightProp) {
      return -1 * sortDirection;
    }
    if (leftProp > rightProp) {
      return 1 * sortDirection;
    }
    return 0;
  };
}
