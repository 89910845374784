import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { PUBLIC_URLS } from '@navUrls';
import { AuthService, UserService } from '@services';
import { ContinuityService } from '@utilityServices';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

/**
 * Prevents user to access pages until user data is available
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly userService: UserService
  ) {}

  /**
   * Checks if user data is available = user is logged in
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    Logger.log(`:: AuthGuard :: canActivate :: \n[route]: ${route}, \n[state]: ${state}`);
    const user = await this.userService.getCurrentUser().catch(Logger.error);

    // If no user data is currently available, the user is not logged in -> no access to the current page
    if (!user) {
      ContinuityService.setUrl(state.url);

      this.navigateToLogin();
      return false;
    }
    return true;
  }

  /**
   * Navigates to the login page
   */
  private navigateToLogin(): void {
    this.router.navigate(PUBLIC_URLS.authentication.getUrlSegments()).catch();
  }
}
