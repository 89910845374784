import { RouteUrl } from './route.url';

/**
 * All modules urls
 */
export const MODULES_URLS = {
  dashboard: new RouteUrl(['dashboard']),
  orderList: new RouteUrl(['order', 'list']),
  public: new RouteUrl(['public']),
  welcomeScreen: new RouteUrl(['welcome-screen']),
  order: new RouteUrl(['order']),
};
