import { ISOLanguageCodes } from '@models';

/**
 * All available push notification channels.
 * These channels are used to provide granular settings for receiving different types of push notifications.
 */
export enum PushNotificationChannel {
  ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
  IDENT_STATUS_CHANGED = 'IDENT_STATUS_CHANGED',
}
/** Type that allows only key values of the PushNotificationChannel enum */
export type PushNotificationChannels = { [key in keyof typeof PushNotificationChannel]: boolean };

/**
 * Available settings for push notifications
 */
export interface IPushNotificationSettings {
  /** Channels and their activated state */
  channels?: PushNotificationChannels;
  /** Whether the user has already been asked for permissions */
  askedForPermission?: boolean;
  preferredLanguage: ISOLanguageCodes;
}

/**
 * Available types of notifications, not to be confused with the PushNotificationChannels,
 * as one channel can have several different PushNotificationTypes.
 */
export enum PushNotificationType {
  ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
  AUTOIDENT_COMPLETED = 'AUTOIDENT_COMPLETED',
  VIDEOIDENT_COMPLETED = 'VIDEOIDENT_COMPLETED',
  RELOAD_REMOTE_CONFIG = 'RELOAD_REMOTE_CONFIG',
}

/**
 * Data payload coming with a notification
 */
export interface IPushNotificationPayload {
  /** Id of the related order */
  orderId?: string;
  /** Type of the notification */
  pushNotificationType: PushNotificationType;
  /** Channel used for this notification */
  pushNotificationChannel: PushNotificationChannel;
}
