import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';
import { OrderGuard } from './order';
import { OrderGraphPathResolver } from './order/utility/order-graph-path-resolver.service';
import { GraphPathService } from './order/utility/graph-path.service';
import { InternalGuard } from './internal.guard';
import { OrderResolver } from './order/order-resolver.guard';

/**
 * List of guards
 */
const KEB_GUARDS = [AuthGuard, OrderGuard, InternalGuard, OrderResolver];

/**
 * Guards module
 */
@NgModule({
  imports: [CommonModule],
  providers: [...KEB_GUARDS, OrderGraphPathResolver, GraphPathService],
})
export class GuardsModule {}
