import { Injectable } from '@angular/core';
import { Directory, Encoding, Filesystem, ReaddirResult, StatResult } from '@capacitor/filesystem';

import { Logger } from '@kerberos-compliance/kerberos-fe-lib';

/** The default directory that should be used */
const DEFAULT_DIRECTORY = Directory.Data;
/** Whether reading and writing operations should be logged */
const DEBUG_MODE = false;

/**
 * Service that provides Capacitor's filesystem functions with a simplified interface
 */
@Injectable({
  providedIn: 'root',
})
export class FileSystemService {
  /**
   * Writes a file with given content
   * @param options File writing options
   * @param options.path Path where the file should be written to
   * @param options.data Data that should written in the file
   * @returns whether file writing was successful
   */
  public async fileWrite(options: { path: string; data: {} | string }): Promise<boolean> {
    try {
      const dataToWrite = typeof options?.data === 'string' ? options?.data : JSON.stringify(options?.data);
      const result = await Filesystem.writeFile({
        path: options?.path,
        data: dataToWrite,
        directory: DEFAULT_DIRECTORY,
        encoding: Encoding.UTF8,
        recursive: true, // Create missing folders
      });

      if (DEBUG_MODE) {
        Logger.log('WROTE FILE', result, options.data);
      }

      return true;
    } catch (e) {
      Logger.error('Unable to write file', e);
      return false;
    }
  }

  /**
   * Reads a file with given path
   * @param options File reading options
   * @param options.path Path of the file that should be read
   * @param options.ignoreErrors Whether to ignore errors while reading the file and not log them to the console
   * @returns path and content of the file
   */
  public async fileRead(options: {
    path: string;
    ignoreErrors?: boolean;
  }): Promise<{ filePath: string; fileContent: string | Blob }> {
    try {
      const contents = await Filesystem.readFile({
        path: options?.path,
        directory: DEFAULT_DIRECTORY,
        encoding: Encoding.UTF8,
      });

      if (DEBUG_MODE) {
        Logger.log('READ FILE', options.path);
      }

      return { filePath: options.path, fileContent: contents.data };
    } catch (e) {
      if (!options?.ignoreErrors) {
        Logger.error('Unable to read file', e);
      }

      return null;
    }
  }

  /**
   * Deletes a file with given path
   * @param options File delete options
   * @param options.path Path of the file that should be deleted
   */
  public async fileDelete(options: { path: string }): Promise<void> {
    await Filesystem.deleteFile({
      path: options?.path,
      directory: DEFAULT_DIRECTORY,
    });
  }

  /**
   * Creates a directory with given path
   * @param options Create directory options
   * @param options.path Path where the directory should be created
   * @param options.recursive Whether missing parent folders should be created
   */
  public async mkdir(options: { path: string; recursive?: boolean }): Promise<void> {
    try {
      if (DEBUG_MODE) {
        Logger.log('CREATE DIRECTORY', options.path);
      }

      return await Filesystem.mkdir({
        path: options?.path,
        directory: DEFAULT_DIRECTORY,
        recursive: !!options.recursive,
      });
    } catch (e) {
      if (options.recursive) {
        Logger.error('Unable to make directory', e);
      } else {
        Logger.error('Unable to make directory. Try again with recursive option enabled.', e);
      }
      return null;
    }
  }

  /**
   * Deletes a directory with given path
   * @param options Remove directory options
   * @param options.path Path of the directory that should be deleted
   */
  public async rmdir(options: { path: string }): Promise<void> {
    try {
      if (DEBUG_MODE) {
        Logger.log('REMOVE DIRECTORY', options.path);
      }

      return await Filesystem.rmdir({
        path: options?.path,
        directory: DEFAULT_DIRECTORY,
        recursive: true, // delete contents of the directory, too
      });
    } catch (e) {
      Logger.error('Unable to remove directory', e);
      return null;
    }
  }

  /**
   * Reads the directory with given path
   * @param options Read directory options
   * @param options.path Path of the directory
   * @returns List of file names inside the directory
   */
  public async readdir(options: { path: string }): Promise<ReaddirResult> {
    try {
      if (DEBUG_MODE) {
        Logger.log('READ DIRECTORY', options.path);
      }

      return await Filesystem.readdir({
        path: options?.path,
        directory: DEFAULT_DIRECTORY,
      });
    } catch (e) {
      Logger.warn('Unable to read dir', e);
      return null;
    }
  }

  /**
   * Reads the data about a file
   * @param options Stat options
   * @param options.path Path of the file
   * @returns information like creating & modifying timestamps, type and size
   */
  public async stat(options: { path: string }): Promise<StatResult> {
    try {
      return await Filesystem.stat({
        path: options?.path,
        directory: DEFAULT_DIRECTORY,
      });
    } catch (e) {
      Logger.error('Unable to stat file', e);
      return null;
    }
  }
}
