import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

import { Browser } from '@capacitor/browser';
import { KebActionModule } from '@graph/action.module';
import { DocumentPreviewOverlayModule } from '@shared/components/document-preview-overlay/document-preview-overlay.module';
import { DocumentPreviewModule } from './components/document-preview/document-preview.module';
import { MenuBarModule } from './components/menu-bar/menu-bar.module';
import { OrderListItemModule } from './components/order-list-item/order-list-item.module';
import { LibModule } from './lib.module';
import { KebPipeModule } from './pipes/pipes.module';

/**
 * List of external modules that should be provided
 */
const EXTERNAL_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule];

export const KEB_MODULES = [
  KebPipeModule,
  KebActionModule,
  MenuBarModule,
  OrderListItemModule,
  DocumentPreviewModule,
  DocumentPreviewOverlayModule,
];

@NgModule({
  imports: [
    ...EXTERNAL_MODULES,
    ...KEB_MODULES,
    LibModule,
    // Please do not add any modules here, but use the arrays above.
  ],
  exports: [
    ...EXTERNAL_MODULES,
    ...KEB_MODULES,
    LibModule,
    // Please do not add any modules, directives, components or pipes here, but use the arrays above.
  ],
  providers: [
    {
      provide: typeof Browser,
      useValue: Browser,
    },
  ],
})
export class SharedModule {}
