import { Pipe, PipeTransform } from '@angular/core';

import { ICheck, IOrderFile } from '@models';
import { ToolsService } from '@utilityServices';

/**
 * Returns the first matching check of the given order file
 */
@Pipe({
  name: 'orderCheck',
})
export class OrderCheckPipe implements PipeTransform {
  public transform(orderFile: IOrderFile): ICheck {
    return ToolsService.getMainCheckFromOrderFile(orderFile);
  }
}
