import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { environment } from '@environment';
import { BffService } from '@http/bff.service';
import {
  ActorType,
  IActor,
  IActorAddress,
  IActorDocument,
  ICheck,
  ICorporateActorDetails,
  IdentMethodType,
  IdentResultFinal,
  ISOCountryCode,
  NO_IDENT,
  OrderType,
} from '@models';
import { CheckUrl, OrderUrl } from '@serviceUrls';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreateActorAs, CreateCheckDto, CreateUboCheckDto } from './check.types';
import { UserService } from './user.service';
// noinspection ES6PreferShortImport
import { OrderFileService } from './utility-services/order-file.service';

/**
 * Options for debugging the ident process
 */

@Injectable({
  providedIn: 'root',
})
export class CheckService {
  /** The current check as BehaviorSubject */
  private readonly currentCheck: BehaviorSubject<ICheck> = new BehaviorSubject<ICheck>(null);

  /** Currently signed in users userId */
  private userId: string = null;

  /** Currently signed in company userId */
  private companyId: string = null;

  /** Currently active orderId */
  private orderId: string = null;

  /** Order type of the current order */
  private orderType: OrderType = null;

  private currentQueryParameters: ParamMap;

  constructor(
    private readonly router: Router,
    private readonly bffService: BffService,
    private readonly userService: UserService,
    private readonly orderFileService: OrderFileService
  ) {
    this.userService.user$.subscribe((user) => {
      this.userId = user?.userId;
      this.companyId = user?.company?.companyId;
    });
    this.orderFileService.currentOrderFile$.subscribe((orderFile) => {
      this.orderId = orderFile?.order?.orderId || null;
      this.orderType = orderFile?.order?.type || null;
    });

    this.router.routerState.root.queryParamMap.subscribe((queryMap) => (this.currentQueryParameters = queryMap));
  }

  /**
   * Delete Check belonging to an Order.
   * @param orderId Order unique identifier.
   * @param checkId Check unique identifier.
   */
  public async delete(orderId: string, checkId: string): Promise<ICheck> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .delete<ICheck>({ url: CheckUrl.deleteCheck(user.company.companyId, orderId, checkId) })
      .pipe(map((response) => response?.data))
      .toPromise();
  }

  /**
   * Create Ident Check.
   * @param data creation data for new Check.
   * @see createCheckAndIdent
   */
  public async create(data: CreateCheckDto): Promise<ICheck> {
    const body = this.buildCreateRequestBody(data);
    const params = CheckService.buildCreateRequestParams(data);
    const user = await this.userService.getCurrentUser();

    return this.bffService
      .post<ICheck>({
        url: CheckUrl.createCheck(user.company.companyId, data.orderId),
        body,
        params,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * TODO: Create in single request (KYC-1311).
   *
   * Create Corporate check
   *
   * @param orderId Order to be added new Corporate check.
   * @param details Corporate actor details.
   * @param countryCode Corporate actor address country code.
   */
  public async createCorporate(
    orderId: string,
    details: ICorporateActorDetails,
    countryCode: ISOCountryCode
  ): Promise<[ICheck, IActor, IActorAddress]> {
    const check = await this.create({ type: NO_IDENT, actorType: ActorType.CORPORATE, orderId });

    check.actor.corporateDetails = details;
    check.actorAddress.countryCode = countryCode;

    return Promise.all([
      check,
      this.updateActor(check.actor),
      this.updateActorAddress(orderId, check.checkId, check.actorAddress),
    ]);
  }

  /**
   * TODO: Create in single request (KYC-1311).
   *
   * Create UBO check.
   *
   * @param data create ubo data.
   */
  public async createUbo(data: CreateUboCheckDto): Promise<ICheck> {
    const check = await this.create({
      orderId: data.orderId,
      type: NO_IDENT,
      actorType: ActorType.INDIVIDUAL,
      createActorAs: [CreateActorAs.BENEFICIAL_OWNER],
    });
    check.result = IdentResultFinal.SUCCESS;
    check.actor.individualDetails = { ...check.actor.individualDetails, ...data.actorDetails };
    check.actorAddress = { ...check.actorAddress, ...data.actorAddress };
    await this.update(data.orderId, check);
    return check;
  }

  /**
   * TODO: Update in single request (KYC-1311).
   *
   * Update Check result, actor & actorAddress.
   * **Note:** Unfortunately back-end is not setup to handle the update in one single request, as such this method will
   * make multiple requests to the back-end.
   * This makes the update non-atomic (with its wonderful drawbacks) and makes it less efficient.
   * @param orderId Order unique identifier.
   * @param check Check to be updated (result, actor & actorAddress).
   */
  public async update(orderId: string, check: ICheck): Promise<[ICheck, IActor, IActorAddress]> {
    return Promise.all([
      this.updateResult(orderId, check),
      this.updateActor(check.actor),
      this.updateActorAddress(orderId, check.checkId, check.actorAddress),
    ]);
  }

  /**
   * Update Actor.
   *
   * @param actor Actor to be updated
   */
  public async updateActor(actor: IActor): Promise<IActor> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .patch<IActor>({ url: OrderUrl.updateActor(user.company.companyId, actor.actorId), body: actor })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Provide a document for a given check.
   * @param orderId Order unique identifier (the Check belongs to).
   * @param checkId Check unique identifier (the Document should be added to).
   * @param actorDocument Document to be added to Check.
   */
  public async addDocument(orderId: string, checkId: string, actorDocument: IActorDocument): Promise<IActorDocument> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .post<IActorDocument>({
        url: OrderUrl.provideDocuments(user.company.companyId, orderId, checkId),
        body: actorDocument,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Creates check in backend and updates currentCheck observable. Resolves with created Check.
   */
  public async createCheckAndIdent(data: CreateCheckDto): Promise<ICheck> {
    return new Promise((resolve, reject) => {
      const body = this.buildCreateRequestBody(data);
      const params = CheckService.buildCreateRequestParams(data);

      this.bffService
        .post<ICheck>({
          url: CheckUrl.createCheck(this.companyId, data.orderId || this.orderId),
          body,
          params,
        })
        .pipe(
          catchError((err) => this.handleError(err, reject)),
          map((response) => response.data)
        )
        .subscribe((check) => {
          // Check if this new check is the main check of the order
          if (this.orderType === OrderType.INDIVIDUAL) {
            // Only update the observable if it is Auto/Video ident or the checked actor is an individual
            if (data.type !== NO_IDENT || data.actorType === ActorType.INDIVIDUAL) {
              this.currentCheck.next(check);
            }
          } else {
            // Only update the observable if it is no ident and the checked actor is a corporate
            if (data.type === NO_IDENT && data.actorType === ActorType.CORPORATE) {
              this.currentCheck.next(check);
            }
          }

          resolve(check);
        });
    });
  }

  /**
   * Resolves with Check and updates it as new currentCheck
   */
  public async getCheck(checkId: string, orderId?: string): Promise<ICheck> {
    return new Promise((resolve, reject) => {
      this.bffService
        .get<ICheck>({ url: CheckUrl.getCheck(this.companyId, orderId || this.orderId, checkId) })
        .pipe(
          catchError((err) => this.handleError(err, reject)),
          map((response) => response?.data)
        )
        .subscribe((check) => {
          this.currentCheck.next(check);
          resolve(check);
        });
    });
  }

  private getCreateActorAs(data?: CreateActorAs[]): CreateActorAs[] {
    data = data || [];
    if (!this.currentQueryParameters.has('createActorAs')) {
      return data;
    }
    const queryArgs = this.currentQueryParameters
      .get('createActorAs')
      .split(',')
      .filter((value) => value in CreateActorAs)
      .map((value) => value as CreateActorAs);
    return queryArgs.slice().concat(data);
  }

  get currentCheckId(): string | null {
    return this.currentCheck?.value?.checkId;
  }

  // ===============
  // Implementation
  // ===============
  private static buildCreateRequestParams(data: CreateCheckDto) {
    const params: {
      type?: string;
      debugging?: string;
      path?: string;
      mock?: string;
    } = data?.type === NO_IDENT ? {} : { type: data.type };

    // If the current environment is a development environment and debugging options are provided
    if (
      !environment.production &&
      (data.type === IdentMethodType.AUTO_IDENT || data.type === IdentMethodType.VIDEO_IDENT) &&
      data?.debuggingOptions?.path
    ) {
      params.debugging = 'true';
      params.path = data.debuggingOptions.path;
      params.mock = data.debuggingOptions.mock ? 'true' : 'false';
    }
    return params;
  }

  private buildCreateRequestBody(data: CreateCheckDto): object {
    let body: object = { createActorAs: this.getCreateActorAs(data.createActorAs) };
    if (data.type === IdentMethodType.VIDEO_IDENT) {
      body = { ...body, mail: data.mail, companyName: data.companyName };
    } else if (data.type === NO_IDENT) {
      body = { ...body, actorType: data.actorType };
    }
    return body;
  }

  private async updateResult(orderId: string, check: ICheck): Promise<ICheck> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .patch<ICheck>({
        url: CheckUrl.checkResult(user.company.companyId, orderId, check.checkId),
        body: { result: check.result },
      })
      .pipe(map((result) => result.data))
      .toPromise();
  }

  private async updateActorAddress(
    orderId: string,
    checkId: string,
    actorAddress: IActorAddress
  ): Promise<IActorAddress> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .patch<IActorAddress>({
        url: OrderUrl.updateActorAddress(user.company.companyId, orderId, checkId, actorAddress.actorAddressId),
        body: actorAddress,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  private handleError(err, reject: (reason?: any) => void) {
    this.currentCheck.next(err);
    reject(err);
    return throwError(err);
  }
}
