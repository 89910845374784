import { Graph } from './graph';
import { GraphNodeId, IGraphNodeData } from './graph.types';

/**
 * Node in a graph
 */
export class GraphNode {
  /**
   * @param id Id of the node
   * @param data Data stored in this node
   * @param graph Reference to the graph
   */
  constructor(public readonly id: GraphNodeId, public readonly data: IGraphNodeData, private readonly graph: Graph) {}
}
