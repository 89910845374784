import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { KebIcon, LibConstants } from '@kerberos-compliance/kerberos-fe-lib';
import { ACCOUNT_URLS, MODULES_URLS, ORDER_URLS, RouteUrl } from '@navUrls';
import { OrderService } from '@services';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBarComponent {
  /** Available navigation items */
  public navItems: {
    /** Displayed label text */
    label?: string;
    /** Icon of item */
    icon: KebIcon;
    /** Whether menu item is disabled */
    disabled?: () => boolean;
    /** Url of linked page */
    url: string | RouteUrl;
    /** Function that should be triggered on click */
    clickFn?: () => void;
  }[] = [
    {
      label: 'home',
      icon: KebIcon.HOME_M,
      url: MODULES_URLS.dashboard.getJoinedUrl({ leadingSlash: true }),
    },
    {
      label: 'orders',
      icon: KebIcon.REPORTS_M,
      url: MODULES_URLS.orderList.getJoinedUrl({ leadingSlash: true }),
    },
    {
      icon: KebIcon.NEW_REPORT_M,
      url: '',
      clickFn: this.newOrder.bind(this),
      disabled: () => this.orderService.ordersAreLoading,
    },
    {
      label: 'notifications',
      icon: KebIcon.NOTIFICATIONS_M,
      disabled: () => true,
      // TODO: Mitteilungen Url
      url: null,
    },
    {
      label: 'account',
      icon: KebIcon.ACCOUNT_M,
      url: ACCOUNT_URLS.account.getJoinedUrl({ leadingSlash: true }),
    },
  ];

  constructor(
    public readonly LC: LibConstants,
    public readonly orderService: OrderService,
    private readonly router: Router,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.orderService.$ordersAreLoading.subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  /**
   * Creates a new Order
   */
  private async newOrder(): Promise<void> {
    await this.router.navigate(ORDER_URLS.create.getUrlSegments(), { state: { cancelUrl: this.router.url } });
  }
}
