import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { Graph } from '@graph/graph';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { GraphActionResult } from '@models';
import { IRouteParams, RouteUrl } from '@navUrls';
import { AtLeastOne } from '@types';
import { FileMetaService } from '@utilityServices/file-meta.service';
import { OrderFileService } from '@utilityServices/order-file.service';

/**
 * Graph action interface
 */
type IGraphFunctionAction = {
  fn: string | (() => GraphActionResult | Promise<GraphActionResult>);
};

type IGraphUrlAction = {
  url: RouteUrl;
};

export type IGraphAction<T> = {
  /** Name of the action */
  name: T;
  forwardQueryParams?: boolean;
} & AtLeastOne<IGraphFunctionAction, IGraphUrlAction>;

/**
 * Graph config interface
 */
export interface IGraphConfig<T extends string> {
  /** Label of the page. Only for debugging purposes */
  label?: string;
  /** Urls of this page */
  urls: RouteUrl[];
  /** Possible actions for this page */
  actions: IGraphAction<T>[];
  /** Whether the previous graph path should be protected to prevent navigating back */
  protectPreviousGraphPath?: boolean;
  /** Whether a graph path item should be skipped */
  skipOnNavigation?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  /** Graph data */
  public readonly graph: Graph = new Graph();

  /** Graph config of the active page */
  private activePageConfig: IGraphConfig<string>;

  private currentQueryParams: Params = {};

  constructor(
    private readonly router: Router,
    private readonly orderFileService: OrderFileService,
    private readonly fileMetaService: FileMetaService
  ) {
    router.routerState.root.queryParams.subscribe((params) => (this.currentQueryParams = params));
  }

  /**
   * Sets the config of the current page
   * @param config Graph config of the current page
   */
  public setActivePageConfig(config: IGraphConfig<string>): void {
    this.activePageConfig = config;

    this.activePageConfig.urls.forEach((url) => {
      if (this.activePageConfig.protectPreviousGraphPath) {
        this.fileMetaService.protectPreviousGraphPath(url).catch();
      }

      if (this.activePageConfig.skipOnNavigation) {
        this.fileMetaService.skipUrlOnNavigation(url).catch();
      }
    });
  }

  /**
   * Returns the graph config of the active page
   */
  public getActivePageConfig(): IGraphConfig<string> {
    // Return the config immutable
    return { ...this.activePageConfig };
  }

  /**
   * Triggers action of the current page
   * @param actionName Name of the action
   * @param urlParams Optional parameters for the RouteUrl
   */
  public async triggerAction(actionName: string, urlParams?: IRouteParams): Promise<void> {
    if (!this.activePageConfig) {
      Logger.error(`Could not find a valid page config! Action name: ${actionName}`);
    }

    const action: IGraphAction<string> = this.activePageConfig.actions.find((item) => item.name === actionName);

    if (!action) {
      Logger.error(
        `Action '${actionName}' is not valid. Valid actions are:`,
        this.activePageConfig.actions.map((item) => item.name)
      );
      return;
    }

    // Trigger the function if exists
    if (action.fn && typeof action.fn === 'function') {
      const actionResult = await action.fn();

      if (actionResult === false) {
        // If function returns false, stop here and don't navigate
        return;
      } else if (typeof actionResult === 'object') {
        // If function returns and RouteUrl, the action.url is updated,
        // such that navigation will take place in the if-statement below
        if (actionResult.url) {
          action.url = actionResult.url;
        }
        if (actionResult.urlParams) {
          urlParams = actionResult.urlParams;
        }
      }
    }

    if (!action.url) {
      return;
    }

    // Navigate if a url is provided
    const url = (action.url as RouteUrl<{}>).getUrlSegments({
      params: { orderId: this.orderFileService.currentOrderId, ...urlParams },
    });

    let queryParams = action.url.queryParams || {};
    if (action.forwardQueryParams) {
      queryParams = { ...this.currentQueryParams, ...queryParams };
    }

    await this.router.navigate(url, { queryParams });
  }
}
