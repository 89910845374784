import { IdentMethodType } from '@models';
import { RouteUrl } from './route.url';

/** Base ident url */
const identUrl = ['order', ':orderId', 'ident'];
const identStartUrl = [...identUrl, 'start'];

/** Params for ident urls */
type IDENT_PARAMS = {
  orderId: string;
};

/** All ident urls */
export const IDENT_URLS = {
  type: new RouteUrl<IDENT_PARAMS>([...identUrl, 'type']),
  auto: new RouteUrl<IDENT_PARAMS>([...identStartUrl, 'auto']),
  manual: new RouteUrl<IDENT_PARAMS>([...identStartUrl, 'manual']),
  video: new RouteUrl<IDENT_PARAMS>([...identStartUrl, 'video']),
  videoWithMail: new RouteUrl<IDENT_PARAMS & { mail?: string }>([...identStartUrl, 'video', ':mail']),
  actorData: new RouteUrl<IDENT_PARAMS & { checkId: string }>([...identUrl, ':checkId', 'actor-data']),
  list: new RouteUrl<IDENT_PARAMS>([...identUrl, 'list']),
};

/** All auto ident urls */
export const AUTO_IDENT_URLS = {
  status: new RouteUrl<IDENT_PARAMS & { checkId: string }>([...identUrl, 'auto', 'check', ':checkId', 'status']),
};

export const VIDEO_IDENT_URLS = {
  status: new RouteUrl<IDENT_PARAMS & { checkId: string }>([...identUrl, 'video', 'check', ':checkId', 'status']),
};

export function getIdentStartURl(type: IdentMethodType): RouteUrl<IDENT_PARAMS> {
  switch (type) {
    case IdentMethodType.AUTO_IDENT:
      return IDENT_URLS.auto;
    case IdentMethodType.VIDEO_IDENT:
      return IDENT_URLS.video;
    default:
      return IDENT_URLS.manual;
  }
}
