import { ErrorHandler, Injectable } from '@angular/core';

import { ApiError } from '@models';

/**
 * Custom error handler that prevents logging of API errors
 */
@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  /** Handles error and ignores ApiErrors */
  // tslint:disable-next-line:no-any
  public handleError(error: any): void {
    // Ignore API errors
    if (error instanceof ApiError) {
      return;
    }

    // For all other error types use the standard error logger
    super.handleError(error);
  }
}
