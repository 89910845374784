/**
 * List of all possible test paths for the Auto Ident IDnow API
 *
 * To test the different results, one of the enum values
 * can be passed to the createIdent method as path.
 */
export enum AutoIdentDebuggingParam {
  NONE = 'NONE',
  X_AUTOTEST_HAPPYPATH = 'X-AUTOTEST-HAPPYPATH',
  X_AUTOTEST_FRAUDIDENT = 'X-AUTOTEST-FRAUDIDENT',
  X_AUTOTEST_CANCELED = 'X-AUTOTEST-CANCELED',
  X_AUTOTEST_CHECKPENDING = 'X-AUTOTEST-CHECKPENDING',
}

/**
 * List of all possible test paths for the Video Ident IDnow API
 *
 * To test the different results, one of the enum values
 * can be passed to the createIdent method as path.
 */
export enum VideoIdentDebuggingParam {
  NONE = 'NONE',
  X_AUTOTEST_HAPPYPATH = 'X-AUTOTEST-HAPPYPATH',
  X_AUTOTEST_ABORTIDENT = 'X-AUTOTEST-ABORTIDENT',
  X_AUTOTEST_FRAUDIDENT = 'X-AUTOTEST-FRAUDIDENT',
  X_AUTOTEST_CANCELED = 'X-AUTOTEST-CANCELED',
}
