/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { BffService } from '@http/bff.service';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import {
  ActorDocument,
  ActorDocumentType,
  ActorType,
  DocumentFile,
  IActor,
  IActorAddress,
  IActorDocument,
  ICheck,
  ICorporateActorDetails,
  IDeleteOrderDto,
  IDocumentFile,
  IIndividualActorDetails,
  IKycFile,
  IOrder,
  IOrderFile,
  IUser,
  IUserMailInfoDto,
  KycFile,
  KycFileType,
  LOCAL_ID_PREFIX,
  MIME_TYPE,
  Order,
  OrderType,
} from '@models';
import { TranslocoService } from '@ngneat/transloco';
import { OrderUrl } from '@serviceUrls';
import { CheckService } from '@services/check.service';
import { AppConfigService } from '@shared/config/app-config.service';
import { IFirebaseRemoteConfig } from '@shared/config/app-config.types';
import { sortByProp } from '@shared/utils';
import { FileService, OrderFileService, ToolsService } from '@utilityServices';
import { ImageConversionService } from '@utilityServices/image-conversion.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';

/** Time in seconds the get-all-orders request should be debounced */
const DEBOUNCE_TIME_SECONDS = 20;

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  /** Currently selected order as behaviour subject */
  private readonly currentOrder: BehaviorSubject<IOrder> = new BehaviorSubject<IOrder>(null);

  /** Id of the current order as behaviour subject */
  private readonly currentOrderId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /** Id of the current order as observable */
  public readonly currentOrderId$: Observable<string> = this.currentOrderId.asObservable();

  private readonly ordersLoadingSubject = new BehaviorSubject(false);

  public get ordersAreLoading() {
    return this.ordersLoadingSubject.value;
  }

  public get $ordersAreLoading() {
    return this.ordersLoadingSubject.asObservable();
  }

  /** Currently signed in users userId */
  private userId: string = null;

  /** Currently signed in company userId */
  private companyId: string = null;

  /** ISO Timestamp of the last loading of orders. Used for debouncing the request */
  private lastLoadedTimestamp: DateTime;

  private appConfig: IFirebaseRemoteConfig;

  constructor(
    private readonly userService: UserService,
    private readonly bffService: BffService,
    private readonly orderFileService: OrderFileService,
    private readonly translocoService: TranslocoService,
    private readonly appConfigService: AppConfigService,
    private readonly checkService: CheckService
  ) {
    this.appConfigService.config$.subscribe((config) => {
      this.appConfig = config;
    });

    this.userService.user$.subscribe((user) => {
      // Reset the lastLoadedTimestamp if the user changes -> immediately loading of orders
      if (this.userId !== user?.userId) {
        this.lastLoadedTimestamp = null;
      }

      this.userId = user?.userId;
      this.companyId = user?.company?.companyId;
    });
    this.orderFileService.fileSaved.subscribe((file) => this.onOrderFileSaved(file));
  }

  /**
   * Retrieve Order by id.
   * @param orderId Order unique identifier.
   */
  public async get(orderId: string): Promise<IOrder> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .get<IOrder>({ url: OrderUrl.getOrder(user.company.companyId, orderId) })
      .pipe(map((response) => response?.data))
      .toPromise();
  }

  /**
   * Creates an incomplete order of given type
   * @param type Order type.
   * @param title Order title.
   */
  public async create(type: OrderType, title: string): Promise<IOrder> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .post<IOrder>({
        url: OrderUrl.createIncompleteOrder(user.company.companyId),
        body: {
          type,
          title,
          createdByUserId: user.userId,
        },
      })
      .pipe(map((response) => response?.data))
      .toPromise();
  }

  /**
   * TODO: Update in single request (KYC-1311).
   * TODO: (Main) Check should be defined in back-end, no need to expose such logic to front-end.
   *
   * Adds signature to Order's main Check (if present) and updates Order status to submitted for review.
   */
  public async submitToReview(order: IOrder, signatureFile: DocumentFile | undefined): Promise<IOrder> {
    await this.uploadSignature(order, signatureFile);
    const { orderId } = order;
    return this.submitOrderForReview(orderId);
  }

  /**
   * Updates Order last viewed date.
   */
  public async markAsViewed(orderId: string): Promise<IOrder> {
    const user = await this.userService.getCurrentUser();
    return this.bffService
      .patch<IOrder>({ url: OrderUrl.updateOrderViewedDate(user.company.companyId, orderId) })
      .pipe(map((response) => response?.data))
      .toPromise();
  }

  /**
   * Loads orders from API. Notice that this request is debounced to reduce unnecessary requests
   * @param forceReload If true, will force reloading all orders and ignore debounce
   */
  public async loadOrders(forceReload: boolean = false): Promise<void> {
    // Make GET reerrquest only maximum every X seconds -> reduce unnecessary requests

    if (
      !forceReload &&
      this.lastLoadedTimestamp &&
      DateTime.local().minus({ seconds: DEBOUNCE_TIME_SECONDS }) < this.lastLoadedTimestamp &&
      forceReload === false
    ) {
      return;
    }

    try {
      this.ordersLoadingSubject.next(true);
      const user: IUser = await this.userService.getCurrentUser();

      const orders: IOrder[] = await this.bffService
        .get<IOrder[]>({ url: OrderUrl.getAll(user?.company?.companyId) })
        .pipe(
          map((response) => response?.data),
          map((orderData) =>
            orderData.map((order) => {
              order.decisions?.sort(sortByProp((d) => d.createdAt, true));
              return order;
            })
          )
        )
        .toPromise();

      if (!orders) {
        Logger.log('NO ORDERS FOUND');
      }

      this.lastLoadedTimestamp = DateTime.local();

      await this.orderFileService.mergeOrdersFromBackend(orders);
    } finally {
      this.ordersLoadingSubject.next(false);
    }
  }

  public async deleteOrder(orderId: string): Promise<IDeleteOrderDto> {
    const user: IUser = await this.userService.getCurrentUser();

    const deletedOrder = await this.bffService
      .delete<IDeleteOrderDto>({ url: OrderUrl.getOrder(user?.company?.companyId, orderId) })
      .pipe(map((response) => response?.data))
      .toPromise();

    // Only delete locally if it was deleted in the back end
    if (deletedOrder.wasDeleted) {
      await this.orderFileService.deleteOrderFile(orderId);
    }

    return deletedOrder;
  }

  /**
   * Sets the id of the current order
   * @param id Id of the order
   */
  public setCurrentOrderId(id: string): void {
    this.currentOrderId.next(id);
  }

  /**
   * Updates an existing actor
   * @param actor Actor that should be updated
   */
  private async updateActor(actor: IActor): Promise<IActor> {
    return this.bffService
      .patch<IActor>({ url: OrderUrl.updateActor(this.companyId, actor.actorId), body: actor })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Updates the address of an actor
   * @param checkId Id of the check
   * @param actorAddress The address that should be updated
   */
  private async updateActorAddress(checkId: string, actorAddress: IActorAddress): Promise<IActor> {
    return this.bffService
      .patch<IActor>({
        url: OrderUrl.updateActorAddress(
          this.companyId,
          this.currentOrderId.value,
          checkId,
          actorAddress.actorAddressId
        ),
        body: actorAddress,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Send all checks, including actors, actor addresses and documents to the backend
   * @param orderFile Order file whose content should be sent to the backend
   * @todo refactor into single http request to app bff
   */
  public async updateOrder(orderFile: IOrderFile): Promise<void> {
    const updatedChecks: Partial<ICheck>[] = [];

    for (const check of orderFile.checks) {
      await this.updateActor(check.actor);
      await this.updateActorAddress(check.checkId, check.actorAddress);

      const updatedDocuments: IActorDocument[] = [];

      for (const actorDocument of check.documents || []) {
        // Send only documents that have not been sent before
        if (actorDocument.documentId.startsWith(LOCAL_ID_PREFIX)) {
          const savedDocument = await this.checkService.addDocument(
            orderFile.order.orderId,
            check.checkId,
            actorDocument
          );
          // TODO: When we have a DMS up and running we could remove this code and use the URL sent by the BE, this is,
          // the BE will send the base64 property with an URL
          savedDocument.files.forEach((savedDocumentFile, index) => {
            savedDocumentFile.base64 = actorDocument.files[index].base64;
          });
          updatedDocuments.push(savedDocument);
        } else {
          updatedDocuments.push(actorDocument);
        }
      }

      updatedChecks.push({ ...check, checkId: check.checkId, documents: updatedDocuments });
    }

    // Update all checks of the order in the local storage
    await this.orderFileService.updateOrderData(
      orderFile.order.orderId,
      {
        checks: updatedChecks,
      },
      { deepMerge: true }
    );
  }

  /**
   * Update properties of order (not the nested objects).
   * Corresponding endpoint currently allows these properties to be updated:
   * orderId: string;
   * clientMetaData?: IOrderClientMetaData;
   * typeOfPartnership?: Partnership;
   * Everything else transmitted will be ignored
   */
  public async patchOrderData(order: IOrder): Promise<void> {
    const { companyId, orderId } = order;
    await this.bffService
      .patch<IOrder>({
        url: OrderUrl.patchOrder(companyId, orderId),
        body: order,
      })
      .pipe(
        catchError((error) => {
          Logger.error(`Failed to patchOrderData ${error}`);
          return throwError(error);
        })
      )
      .toPromise();
  }

  /**
   * Loads all files of the given document
   * @param companyId Id of the company
   * @param orderId Id of the order
   * @param checkId Id of the check
   * @param documentId Id of the document
   */
  public async getFilesOfDocuments(
    companyId: string,
    orderId: string,
    checkId: string,
    documentId: string
  ): Promise<IDocumentFile[]> {
    return this.bffService
      .get<IDocumentFile[]>({
        url: OrderUrl.getAllFilesOfDocument(companyId, orderId, checkId, documentId),
        params: { include: [MIME_TYPE.GIF, MIME_TYPE.JPG, MIME_TYPE.PNG, MIME_TYPE.PDF] },
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Adds a file to the order in the back end
   */
  public async addFileToOrder(companyId: string, orderId: string, fileToAdd: KycFile): Promise<IKycFile> {
    const blobData: Blob = FileService.b64toBlob(fileToAdd.fileURL, fileToAdd.mimeType);

    const result = await ImageConversionService.convertImgToJpg(blobData, fileToAdd.mimeType);
    if (result.mimeType === MIME_TYPE.JPG) {
      const fileNameSplit = fileToAdd.fileName.split('.');

      if (fileNameSplit.length > 1) {
        fileToAdd.fileName = fileToAdd.fileName.replace(`${fileToAdd.fileName.split('.').reverse()[0]}`, 'jpg');
      } else {
        fileToAdd.fileName = `${fileToAdd.fileName.replace(/ /g, '_')}-${new Date().toISOString()}.jpg`;
      }
    }

    const formData = new FormData();
    formData.append('file', result.data);
    formData.append('fileType', fileToAdd.fileType);
    formData.append('mimeType', result.mimeType);
    formData.append('fileName', fileToAdd.fileName);

    return this.bffService
      .post<IKycFile>({
        url: OrderUrl.createKycFile(companyId, orderId),
        body: formData,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  public async getKycFile(
    companyId: string,
    orderId: string,
    kycFileId: string,
    kycFileType: KycFileType
  ): Promise<IKycFile> {
    return this.bffService
      .get({
        url: OrderUrl.getKycFile(companyId, orderId, kycFileId),
        params: {
          kycFileType,
        },
      })
      .pipe(map((response) => response.data as IKycFile))
      .toPromise();
  }

  public async archiveOrder(orderId: string): Promise<Order> {
    const user: IUser = await this.userService.getCurrentUser();

    return this.bffService
      .patch<Order>({ url: OrderUrl.archiveOrder(user?.company?.companyId, orderId) })
      .pipe(map((response) => response?.data))
      .toPromise();
  }

  /**
   * @see getOrderTitle
   */
  public getOrderTitleFromOrderFile(orderFile: IOrderFile): string | undefined {
    return this.getOrderTitle(orderFile.order, orderFile.checks);
  }

  /**
   * @see getOrderTitle
   */
  public getOrderTitleFromOrder(order: IOrder): string | undefined {
    return this.getOrderTitle(order, order.checks);
  }

  // ===============
  // Implementation
  // ===============
  private onOrderFileSaved(updatedOrderFile: IOrderFile) {
    const order = updatedOrderFile?.order;
    if (!order) {
      return;
    }
    const { orderId, companyId, clientMetaData } = order;
    const { uploadClientMetaData } = this.appConfig.features.orders;

    if (!uploadClientMetaData) {
      return;
    }

    if (this.currentOrderId.value !== orderId || !companyId || !clientMetaData) {
      return;
    }
    this.patchOrderData(order);
  }

  /**
   * Submits the current order to review.
   * @param orderId Identifier of Order to be updated.
   */
  private async submitOrderForReview(orderId: string): Promise<IOrder> {
    const user = await this.userService.getCurrentUser();
    const mailInfoDto: IUserMailInfoDto = {
      companyName: user.company.name,
      userEmail: user.email,
      userFirstName: user.firstName,
      userLastName: user.lastName,
    };
    return this.bffService
      .patch<IOrder>({
        url: OrderUrl.submitOrderForReview(user.company.companyId, orderId),
        body: mailInfoDto,
      })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Upload signature file as actor document into Order's main Check.
   * @param order Order where signature document belongs to.
   * @param signatureFile (optional) signature file to be uploaded.
   */
  private async uploadSignature(order: IOrder, signatureFile?: DocumentFile) {
    if (!signatureFile) {
      return;
    }
    const signatureActorDocument = new ActorDocument(ActorDocumentType.SIGNATURE);
    signatureActorDocument.files = [signatureFile];

    const { orderId } = order;
    const mainCheckId = ToolsService.getMainCheck(order.type, order.checks).checkId;
    await this.checkService.addDocument(orderId, mainCheckId, signatureActorDocument);
  }

  /**
   * Returns order title (if not set it uses main actor details).
   *
   * &nbsp;
   *
   * TODO: The reason we have separate input for order & checks is because of backward compatibility.
   * `IOrderFile` keeps order in separate checks fields (for some reason). Once we remove usage of `IOrderFile` change input to `IOrder`.
   *
   * TODO: This should be inside domain object (once we no longer use `IOrderFile` is should be straight-forward)
   *
   * @param order Current Order.
   * @param checks Checks belonging to `order`.
   * @see getOrderFileName
   */
  private getOrderTitle(order: IOrder, checks: ICheck[]): string | undefined {
    if (order.title) {
      return order.title;
    }

    const mainActor = ToolsService.getMainActor(order.type, checks);
    let title: string;

    switch (mainActor?.type) {
      case ActorType.INDIVIDUAL: {
        const actorDetails: IIndividualActorDetails = mainActor?.individualDetails;
        if (actorDetails?.firstName) {
          title = `${actorDetails.firstName} ${actorDetails.lastName}`;
        }
        break;
      }
      case ActorType.CORPORATE: {
        const actorDetails: ICorporateActorDetails = mainActor?.corporateDetails;
        title = actorDetails?.name;
        break;
      }
      default:
        title = undefined;
    }

    if (title) {
      return title;
    }

    return order.type
      ? this.translocoService.translate(`orderType.${order.type.toLowerCase()}`)
      : this.translocoService.translate('dashboard.newOrder');
  }
}
