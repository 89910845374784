<ng-container *transloco="let t">
  <keb-list-item [ngSwitch]="orderStatus" [marked]="isUnread">
    <div>
      <span *ngIf="isOrderIncomplete">{{ t('dashboard.draft') }}:</span>
      {{ orderFileName }}
    </div>

    <ng-container slot="subheading">
      <ng-container *ngIf="(orderFile | orderCheck)?.actorAddress?.zipCode; else noAddressTmpl">
        {{ (orderFile | orderCheck)?.actorAddress | address }}
      </ng-container>

      <ng-template #noAddressTmpl>
        <em>{{ t('order.noAddressProvided') }}</em>
      </ng-template>
    </ng-container>

    <ng-container slot="info">
      {{ orderFile?.order?.createdAt | luxon: { inputFormat: 'iso', outputFormat: { preset: 'DATE_FULL' } } }}
      · {{ t('order.kycQuickCheck') }}
    </ng-container>

    <keb-icon slot="icon" icon="{{ LC.ICON.name.MENU_L }}" size="large" (click)="showActions($event)"></keb-icon>

    <!-- COMPLETED CHIP -->
    <keb-chip *ngSwitchCase="OrderListItemStatus.COMPLETED" color="success">
      <keb-icon slot="icon" icon="{{ LC.ICON.name.CONFIRMED_CIRCLE_S }}" size="xs"></keb-icon>
      {{ t('order.status.' + orderStatus) }}
    </keb-chip>

    <!-- DRAFT CHIP -->
    <keb-chip *ngSwitchCase="OrderListItemStatus.DRAFT" color="primary">
      <keb-icon slot="icon" icon="{{ LC.ICON.name.EDIT_LINE_S }}" size="xs"></keb-icon>
      {{ t('order.status.' + orderStatus) }}
    </keb-chip>

    <!-- PENDING CHIP -->
    <keb-chip *ngSwitchCase="OrderListItemStatus.PENDING" color="secondary">
      <keb-icon slot="icon" icon="{{ LC.ICON.name.SHOW_S }}" size="xs"></keb-icon>
      {{ t('order.status.' + orderStatus) }}
    </keb-chip>

    <!-- PROCESSING CHIP -->
    <keb-chip *ngSwitchCase="OrderListItemStatus.PROCESSING" color="secondary">
      <keb-icon slot="icon" icon="{{ LC.ICON.name.VALIDATE_S }}" size="xs"></keb-icon>
      {{ t('order.status.' + orderStatus) }}
    </keb-chip>

    <!-- ARCHIVED CHIP -->
    <keb-chip *ngSwitchCase="OrderListItemStatus.ARCHIVED" color="secondary">
      <keb-icon slot="icon" icon="{{ LC.ICON.name.ARCHIVE_C }}" size="xs"></keb-icon>
      {{ t('order.status.' + orderStatus) }}
    </keb-chip>
  </keb-list-item>
</ng-container>
