import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { KebIconModule } from '@kerberos-compliance/kerberos-fe-lib';
import { MenuBarComponent } from '@shared/components/menu-bar/menu-bar.component';

@NgModule({
  declarations: [MenuBarComponent],
  imports: [CommonModule, RouterModule, KebIconModule, TranslocoModule],
  exports: [MenuBarComponent],
})
export class MenuBarModule {}
