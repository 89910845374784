import { Graph } from './graph';
import { GraphNodeId, IGraphEdgeData } from './graph.types';

/**
 * Edge in a graph
 */
export class GraphEdge {
  /**
   * @param startId Id of the node the edge is starting
   * @param endId Id of the node the edge is ending
   * @param data Data stored in this edge
   * @param graph Reference to the graph
   */
  constructor(
    public readonly startId: GraphNodeId,
    public readonly endId: GraphNodeId,
    public readonly data: IGraphEdgeData,
    private readonly graph: Graph
  ) {}
}
