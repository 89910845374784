/**
 * Contains the mime types and respective file extensions
 * @see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
 */
import { FileExtensions } from './file-extensions';
import { MIME_TYPE } from './mime-type';

export const mimeTypesFileExtensions: { [key in MIME_TYPE]: FileExtensions[] } = {
  [MIME_TYPE.PDF]: [FileExtensions.PDF],
  [MIME_TYPE.GIF]: [FileExtensions.GIF],
  [MIME_TYPE.JPG]: [FileExtensions.JPG, FileExtensions.JPE, FileExtensions.JPEG],
  [MIME_TYPE.PNG]: [FileExtensions.PNG],
  [MIME_TYPE.CSV]: [FileExtensions.CSV],
  [MIME_TYPE.PLAIN_TEXT]: [FileExtensions.TXT],
  [MIME_TYPE.MP4]: [FileExtensions.MP4],
  [MIME_TYPE.XML]: [FileExtensions.XML],
  [MIME_TYPE.ZIP]: [FileExtensions.ZIP],
  [MIME_TYPE.HEIC]: [FileExtensions.HEIC],
  [MIME_TYPE.HEIF]: [FileExtensions.HEIF],
};
