import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, takeUntil } from 'rxjs/operators';

import { environment } from '@environment';
import { Logger, UnsubscribeService } from '@kerberos-compliance/kerberos-fe-lib';
import { ApiError, IApiResponse } from '@models';
import { KebUrl } from '@serviceUrls';
import { AppConfigService } from '@shared/config/app-config.service';

/**
 * Request types
 */
export enum RequestType {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
}

/** Type definition for http requests */
type Params = HttpParams | { [param: string]: string | string[] };

/**
 * Service that handles the requests to the API
 */
export abstract class BaseBackendService extends UnsubscribeService {
  /** Http client (set by class that extends this base backend service) */
  protected abstract http: HttpClient;
  private backendUrl: string;
  /**
   * Constructor
   */
  protected constructor(protected appConfigService: AppConfigService) {
    super();

    this.appConfigService.config$
      .pipe(takeUntil(this.destroyed))
      .subscribe((config) => this.updateBackendUrl(config.backendUrl));
  }

  private updateBackendUrl(newUrl: string): void {
    this.backendUrl = newUrl;
    if (this.backendUrl.endsWith('/')) {
      return;
    }
    Logger.error('Backend url has to end with a slash', newUrl);

    if (environment.production) {
      Logger.warn(
        'The backend URL was automatically corrected to not break the production environment. Please check the backend url in the environment file!'
      );
      this.backendUrl += '/';
    }
  }

  /**
   * Makes a server request
   * @param method Request method
   * @param serviceUrl Service url
   * @param body Request body
   * @param params Request params
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   */
  private serverRequest<T>({
    method,
    url,
    headers,
    body = {},
    params = {},
    withCredentials = true,
    retries = 0,
  }: {
    method: RequestType;
    url: string;
    headers?: HttpHeaders;
    // tslint:disable-next-line:no-any
    body?: any;
    params?: Params;
    withCredentials: boolean;
    retries: number;
  }): Observable<IApiResponse<T>> {
    // Trim all values in the body
    if (body) {
      Object.entries(body).forEach(([key, value]) => {
        body[key] = this.trimStrings(value);
      });
    }

    let request: Observable<T | IApiResponse<T>>;

    if (!headers) {
      headers = new HttpHeaders();
    }

    switch (method) {
      case RequestType.GET:
        request = this.http.get<T | IApiResponse<T>>(url, { headers, params, withCredentials }).pipe(retry(+retries));
        break;
      case RequestType.POST:
        request = this.http
          .post<T | IApiResponse<T>>(url, body, { headers, params, withCredentials })
          .pipe(retry(+retries));
        break;
      case RequestType.PUT:
        request = this.http
          .put<T | IApiResponse<T>>(url, body, { headers, params, withCredentials })
          .pipe(retry(+retries));
        break;
      case RequestType.PATCH:
        request = this.http
          .patch<T | IApiResponse<T>>(url, body, { headers, params, withCredentials })
          .pipe(retry(+retries));
        break;
      case RequestType.DELETE:
        request = this.http
          .delete<T | IApiResponse<T>>(url, { headers, params, withCredentials })
          .pipe(retry(+retries));
        break;
      default:
        break;
    }

    return this.extendRequest<T>(request);
  }

  /**
   * Extends given request by error catching and reducing the number of loading requests
   * @param request Request
   * @returns Request
   */
  private extendRequest<T>(request: Observable<T | IApiResponse<T>>): Observable<IApiResponse<T>> {
    return request.pipe(
      map((response) => {
        // TODO: (Steffen) Remove when backend always returns an ApiResponse object
        if (response?.hasOwnProperty('data')) {
          return response as IApiResponse<T>;
        } else {
          return { data: response } as IApiResponse<T>;
        }
      }),
      catchError((err) => {
        if (err?.status === 401) {
          Logger.error('Unauthorized');
        }

        return throwError(new ApiError(err));
      })
    );
  }

  /**
   * Get request
   * @param url URL
   * @param headers Http headers
   * @param params Params
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public get<T>({
    url,
    headers,
    params = {},
    withCredentials = true,
    retries = 0,
  }: {
    url: string | KebUrl;
    headers?: HttpHeaders;
    params?: Params;
    withCredentials?: boolean;
    retries?: number;
  }): Observable<IApiResponse<T>> {
    return this.serverRequest<T>({
      method: RequestType.GET,
      url: this.joinUrl(url),
      headers,
      params,
      withCredentials,
      retries,
    });
  }

  /**
   * Post request
   * @param url URL
   * @param headers Http headers
   * @param body Body of request
   * @param params Params of request
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public post<T>({
    url,
    headers,
    body = {},
    params,
    withCredentials = true,
    retries = 0,
  }: {
    url: string | KebUrl;
    // tslint:disable-next-line:no-any
    body?: any;
    headers?: HttpHeaders;
    params?: Params;
    withCredentials?: boolean;
    retries?: number;
  }): Observable<IApiResponse<T>> {
    return this.serverRequest<T>({
      method: RequestType.POST,
      url: this.joinUrl(url),
      headers,
      body,
      params,
      withCredentials,
      retries,
    });
  }

  /**
   * Put request
   * @param url URL
   * @param headers Http headers
   * @param body Body of request
   * @param params Params of request
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public put<T>({
    url,
    headers,
    body = {},
    params = {},
    withCredentials = true,
    retries = 0,
  }: {
    url: string | KebUrl;
    headers?: HttpHeaders;
    // tslint:disable-next-line:no-any
    body?: any;
    params?: Params;
    withCredentials?: boolean;
    retries?: number;
  }): Observable<IApiResponse<T>> {
    return this.serverRequest<T>({
      method: RequestType.PUT,
      url: this.joinUrl(url),
      headers,
      body,
      params,
      withCredentials,
      retries,
    });
  }

  /**
   * Patch request
   * @param url URL
   * @param headers Http headers
   * @param body Body of request
   * @param params Params of request
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public patch<T>({
    url,
    headers,
    body = {},
    params = {},
    withCredentials = true,
    retries = 0,
  }: {
    url: string | KebUrl;
    headers?: HttpHeaders;
    // tslint:disable-next-line:no-any
    body?: any;
    params?: Params;
    withCredentials?: boolean;
    retries?: number;
  }): Observable<IApiResponse<T>> {
    return this.serverRequest<T>({
      method: RequestType.PATCH,
      url: this.joinUrl(url),
      headers,
      body,
      params,
      withCredentials,
      retries,
    });
  }

  /**
   * Delete request
   * @param url URL
   * @param headers Http headers
   * @param params Params
   * @param withCredentials Whether the request should include the credentials
   * @param retries Number of retries
   * @returns Response as Observable
   */
  public delete<T>({
    url,
    headers,
    params = {},
    withCredentials = true,
    retries = 0,
  }: {
    url: string | KebUrl;
    headers?: HttpHeaders;
    params?: Params;
    withCredentials?: boolean;
    retries?: number;
  }): Observable<IApiResponse<T>> {
    return this.serverRequest<T>({
      method: RequestType.DELETE,
      url: this.joinUrl(url),
      headers,
      params,
      withCredentials,
      retries,
    });
  }

  /**
   * Joins url if provided as string and adds the backend url to the url
   * @param url Url in string or array format
   * @returns Url as string
   */
  private joinUrl(url: string | KebUrl): string {
    if (Array.isArray(url)) {
      return `${this.backendUrl}api/${url.join('/')}`;
    } else {
      return `${this.backendUrl}api/${url}`;
    }
  }

  /**
   * Trims strings if given data is a string. Will perform a recursively check if it is an array or object.
   * @param data Given data that should be checked and trimmed if it is a string
   */
  // tslint:disable-next-line:no-any
  private trimStrings(data: any): any {
    switch (typeof data) {
      case 'string':
        return data.trim();
      case 'object':
        if (data !== null && data !== undefined) {
          Object.entries(data).forEach(([key, value]) => {
            data[key] = this.trimStrings(value);
          });
        }
        return data;
    }

    if (Array.isArray(data)) {
      return data.map((item) => this.trimStrings(item));
    }
    return data;
  }
}
