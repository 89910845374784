import { Injectable } from '@angular/core';
import { BffService } from '@http/bff.service';
import { ISOLanguageCodes, IUser } from '@models';
import { UserUrl } from '@serviceUrls';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly userSubject = new BehaviorSubject<IUser>(null);

  /** User data as observable */
  public user$: Observable<IUser> = this.userSubject.asObservable();

  private cachedRequest$: Observable<IUser>;

  constructor(private readonly bffService: BffService) {}

  /**
   * Returns the user object once. To get any user object change use the `user observable`
   * @param forceReload If true, user will be loaded from server. Otherwise, cached response is returned
   * @returns User as Promise
   */
  public async getCurrentUser(forceReload: boolean = false): Promise<IUser> {
    if (forceReload || !this.userSubject.value) {
      this.cachedRequest$ = this.callBackendToGetUser();
    }
    return this.cachedRequest$.pipe(take(1)).toPromise();
  }

  public callBackendToGetUser() {
    return this.bffService.get<IUser>({ url: UserUrl.getMe() }).pipe(
      shareReplay(1),
      map((response) => response?.data),
      catchError((error) => {
        this.userSubject.error(error);
        return throwError(error);
      }),
      tap((user) => this.userSubject.next(user))
    );
  }

  public async getLoggedInUserFromBackend(): Promise<void> {
    this.callBackendToGetUser().toPromise();
  }

  public async getUser(userId: string): Promise<IUser> {
    return this.bffService
      .get<IUser>({ url: UserUrl.getSlimUser(userId) })
      .pipe(map((response) => response.data))
      .toPromise();
  }

  /**
   * Returns the user object once. To get any user object change use the `user observable`
   * @returns User as Promise
   */
  public async updateUserSettings(userId: string, language: ISOLanguageCodes): Promise<void> {
    return this.bffService
      .patch<void>({ url: UserUrl.updateUser(userId), body: { preferredLanguage: language } })
      .pipe(
        map((response) => response?.data),
        tap(() => this.getCurrentUser(true))
      )
      .toPromise();
  }

  /**
   * Invalidates the user data
   */
  public invalidateUser(): void {
    this.userSubject.next(null);
    this.cachedRequest$ = null;
  }
}
