import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environment';
import { Logger, UnsubscribeService } from '@kerberos-compliance/kerberos-fe-lib';
import { ActorType, DeepPartial, ICheck, IOrder, IOrderFile, LOCAL_ID_PREFIX } from '@models';
import { TranslocoService } from '@ngneat/transloco';
import { OrderMerger } from '@services/data-merger/order.merger';
import { AppConfigService } from '@shared/config/app-config.service';
import { IFirebaseRemoteConfig } from '@shared/config/app-config.types';
import { checkByActorType, sortByProp } from '@shared/utils';
import { ToolsService } from '@utilityServices/tools.service';
import deepmerge from 'deepmerge';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
// noinspection ES6PreferShortImport
import { UserService } from '../user.service';
import { FileSystemService } from './file-system.service';

const BASE_PATH = 'user';
/** Base folder path of the files */
const ORDERS_PATH = 'orders';
/** Suffix for order files */
const orderFileSuffix = 'order';

/**
 * Options for file updates
 */
interface IFileUpdateOptions {
  /** Whether to update the `updatedAt` timestamp */
  updateLastChangeTimestamp?: boolean;
  /**
   * Whether given data should be deep merged.
   * If true, only the changed values must be specified in the nested object
   */
  deepMerge: boolean;
  /**
   * Whether the graph path should be updated (default = false)
   * Should only be true if the graphPath is changed and it is safe to update the path and not overwrite a newer state.
   */
  updateGraphPath?: boolean;
}

/** Interface for the file queue */
interface IFileQueueItem {
  /** Promise, that is resolved when the file saving is completed */
  promiseResolver: () => void;
  /** Id of the order */
  orderId: string;
  /** Partial file content (is merged with existing content on the highest level) */
  data: DeepPartial<IOrderFile>;
  /** Options for file updates */
  options?: IFileUpdateOptions;
}

@Injectable({
  providedIn: 'root',
})
export class OrderFileService extends UnsubscribeService {
  public fileSaved = new EventEmitter<IOrderFile>(true);

  /** Whether currently the files are loading */
  private orderFilesLoading: boolean = false;

  /** Id of the currently logged in user */
  private userId: string;

  /** Company id of the currently logged in user */
  private companyId: string;

  /** The currently active order as BehaviorSubject */
  private readonly currentOrderFile: BehaviorSubject<IOrderFile> = new BehaviorSubject<IOrderFile>(null);

  /** The currently active order as Observable */
  public readonly currentOrderFile$: Observable<IOrderFile> = this.currentOrderFile.asObservable();

  /** All order files as BehaviorSubject */
  private readonly orderFiles: BehaviorSubject<IOrderFile[]> = new BehaviorSubject<IOrderFile[]>([]);

  /** All order files as Observable */
  public readonly orderFiles$: Observable<IOrderFile[]> = this.orderFiles.asObservable();

  private readonly isReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Whether the initial data merge has happened */
  private initialDataMergeHappened: boolean = false;

  /** List of orders that should merged after the local order files are loaded */
  private loadedOrdersToMerge: IOrder[];

  private unpublishedOrderFiles: IOrderFile[] = [];

  /**
   * Returns the current order id
   */
  get currentOrderId(): string {
    return this.currentOrderFile?.value?.order?.orderId;
  }

  /** Queue for collecting file changes one after another to prevent data loss */
  private readonly updatingFileQueue: IFileQueueItem[] = [];

  /** Whether a file is currently updating */
  private currentlyUpdatingFile: boolean = false;

  private hasLoadedFilesBefore: boolean = false;

  private appConfig: IFirebaseRemoteConfig;

  private isMergeInProgress = false;

  constructor(
    private readonly fileSystemService: FileSystemService,
    private readonly userService: UserService,
    private readonly translocoService: TranslocoService,
    private readonly appConfigService: AppConfigService
  ) {
    super();

    this.appConfigService.config$.subscribe((appConfig) => {
      this.appConfig = appConfig;
    });

    this.userService.user$.pipe(takeUntil(this.destroyed)).subscribe((user) => {
      if (this.userId === user?.userId) {
        return;
      }

      this.userId = user?.userId;
      this.companyId = user?.company?.companyId;

      if (!!this.userId && !!this.companyId) {
        this.loadInitialFiles();
      }
    });
  }

  public get whenReady(): Promise<boolean> {
    return this.isReadySubject
      .asObservable()
      .pipe(
        filter((state) => state),
        take(1)
      )
      .toPromise();
  }

  /**
   * Returns an immutable state of the current order
   */
  get currentOrderStatic(): IOrderFile {
    if (typeof this.currentOrderFile.value === 'object') {
      return JSON.parse(JSON.stringify(this.currentOrderFile.value));
    }
    return this.currentOrderFile.value;
  }

  /**
   * Returns all checks of the current order besides the main individual and main corporate check of the same order.
   * `mainIndividualCheck` and `mainCorporateCheck` are referenced in the checks array,
   * so changing the data of `mainIndividualCheck` or `mainCorporateCheck` will change the data in the `checks` array as well.
   */
  get linkedChecksAndMainChecks(): {
    checks: ICheck[];
    mainIndividualCheck: ICheck;
    mainCorporateCheck: ICheck;
  } {
    const checks = this.currentOrderStatic?.checks || [];
    const mainIndividualCheck = checks.find((check) => check.actor?.type === ActorType.INDIVIDUAL);
    const mainCorporateCheck = checks.find((check) => check.actor?.type === ActorType.CORPORATE);

    return {
      checks,
      mainIndividualCheck,
      mainCorporateCheck,
    };
  }

  /**
   * Returns the check matching to the order type or null if not available
   */
  get checkStatic(): ICheck | null {
    return ToolsService.getMainCheckFromOrderFile(this.currentOrderFile?.value);
  }

  get individualChecks(): ICheck[] | null {
    return this.currentOrderFile?.value?.checks?.filter((check) => check?.actor?.type === ActorType.INDIVIDUAL) || null;
  }

  /**
   * Returns the check of an individual, regardless of the order type
   */
  get individualCheckStatic(): ICheck | null {
    return this.currentOrderFile?.value?.checks?.find((check) => check?.actor?.type === ActorType.INDIVIDUAL) || null;
  }

  get lastManualCheckStatic(): ICheck {
    const orderFile = this.currentOrderStatic;
    return orderFile.checks
      .filter(checkByActorType(ActorType.INDIVIDUAL))
      .filter((check) => check.result === null)
      .pop();
  }

  /**
   * Returns the check of an corporate, regardless of the order type
   */
  get corporateCheckStatic(): ICheck | null {
    return this.currentOrderFile?.value?.checks?.find((check) => check?.actor?.type === ActorType.CORPORATE) || null;
  }

  /**
   * Sets the new currently active order
   * @param orderId Id of the current order
   */
  public async setCurrentOrderId(orderId: string): Promise<IOrderFile | undefined> {
    if (this.currentOrderFile.value?.order?.orderId === orderId) {
      return this.currentOrderFile.value;
    }

    const currentFile: IOrderFile = await this.getOrderFile(orderId);

    this.currentOrderFile.next(currentFile);

    return currentFile;
  }

  public ensureLoadedOrders(): Promise<unknown> {
    if (!this.hasLoadedFilesBefore) {
      this.loadInitialFiles();
    }
    return this.whenReady;
  }

  /**
   * Merges the given orders with the locally existent order files
   * @param ordersFromBackend Orders that should be merged with the local orders
   */
  public async mergeOrdersFromBackend(ordersFromBackend: IOrder[]): Promise<void> {
    // If orders are not provided
    if (!ordersFromBackend || !Array.isArray(ordersFromBackend)) {
      return;
    }
    this.isMergeInProgress = true;
    // If local order files are not loaded yet, save orders that should be merged and stop merge process
    if (!this.orderFiles.value) {
      this.loadedOrdersToMerge = ordersFromBackend;
      return;
    }

    this.initialDataMergeHappened = true;
    // Get a deep copy of the order files

    // Get all order ids that not longer exist in the backend -> delete these orders afterwards
    const localOrderIdsToDelete: string[] = this.orderFiles.value
      .filter((orderFile) => {
        const { orderId } = orderFile.order;

        // Ignore not submitted orders that are only available locally
        if (orderId.startsWith(LOCAL_ID_PREFIX)) {
          return false;
        }

        // Return true if order id can not be found in the orders array, otherwise return false -> get filtered list of not existent orders
        return !ordersFromBackend.some((order) => order.orderId === orderId);
      })
      // Map to an array of order ids
      .map((orderFile) => orderFile.order.orderId);

    try {
      await Promise.all(
        ordersFromBackend.map(async (orderFromBackend) => {
          const existingOrderFile = this.orderFiles.value.find(
            (orderFile) => orderFile?.order?.orderId === orderFromBackend?.orderId
          );

          const { syncClientMetaData } = this.appConfig.features.orders;
          const hasClientMetaData = orderFromBackend?.clientMetaData && existingOrderFile;

          if (syncClientMetaData && hasClientMetaData) {
            const { graphPaths } = orderFromBackend.clientMetaData;
            existingOrderFile.order.clientMetaData = orderFromBackend.clientMetaData;
            existingOrderFile.meta.graphPath = graphPaths || existingOrderFile.meta.graphPath;
          }

          // check if local file, create one if none created before
          if (!existingOrderFile) {
            await this.saveToDisk(ToolsService.orderToOrderFile(orderFromBackend), false);
            return true;
          }

          const { data: mergedOrder, success: orderMergeSuccess } = OrderMerger.mergeOrder(
            existingOrderFile.order,
            orderFromBackend
          );

          if (!orderMergeSuccess) {
            Logger.warn(`Merge of order was not successful`, existingOrderFile.order, orderFromBackend);
            return true;
          }

          try {
            delete mergedOrder.checks;
          } catch (e) {
            Logger.error(`error deleting mergedOrder.checks: ${e}`);
          }

          const { data: mergedChecks, success: checkMergeSuccess } = OrderMerger.mergeChecks(
            existingOrderFile.checks ?? [],
            orderFromBackend.checks ?? []
          );

          if (!checkMergeSuccess) {
            Logger.warn(`Merge of checks was not successful`, existingOrderFile.checks, orderFromBackend.checks);
            return true;
          }

          const dataIsEqual: boolean =
            JSON.stringify(existingOrderFile.order) === JSON.stringify(mergedOrder) &&
            JSON.stringify(existingOrderFile.checks) === JSON.stringify(mergedChecks);

          if (!dataIsEqual) {
            existingOrderFile.meta.syncedTime = DateTime.local().toISO();
          }

          try {
            await this.updateOrderData(
              orderFromBackend.orderId,
              {
                meta: { ...existingOrderFile.meta },
                order: mergedOrder,
                checks: mergedChecks,
              },
              { updateLastChangeTimestamp: false, deepMerge: false, updateGraphPath: true }
            );
          } catch (e) {
            Logger.error(`error while updatingOrderData: ${e}`);
          }

          return true;
        })
      );
    } catch (error) {
      Logger.error(`error in for loop: ${JSON.stringify(error)}`);
    }

    try {
      await this.deleteOrderFiles(localOrderIdsToDelete);
    } catch (e) {
      Logger.error('DeleteOrderFile for orders failed', { e });
    }

    this.updateOrderFileSubject();
  }

  public async saveToDisk(orderFile: IOrderFile, autoPublish = true): Promise<IOrderFile> {
    if (!this.userId) {
      Logger.error('saveToDisk: USER NOT LOGGED IN');
      throw Error('User is not logged in!');
    }
    const { order } = orderFile;
    try {
      await this.fileSystemService.fileWrite({
        path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}/${order.orderId}.${orderFileSuffix}`,
        data: orderFile,
      });
    } catch (error) {
      Logger.error('Save to disk', { error });
    }
    this.unpublishedOrderFiles.push(orderFile);

    if (autoPublish) {
      this.updateOrderFileSubject();
    }

    return orderFile;
  }

  public updateOrderFileSubject(): void {
    this.isMergeInProgress = false;
    const publishedOrderFiles = this.orderFiles.value || [];
    const allOrders = publishedOrderFiles.concat(this.unpublishedOrderFiles);
    this.orderFiles.next(allOrders.sort(sortByProp((o) => o.order.statusUpdatedAt, false)));
    this.getOrderFile(this.currentOrderId).then((orderFile) => this.currentOrderFile.next(orderFile));
    this.unpublishedOrderFiles = [];
  }

  public async deleteOrderFiles(orderIds: string[]) {
    if (!this.userId || !orderIds) {
      return;
    }
    await Promise.all(orderIds.map((orderId) => this.deleteOrderFile(orderId)));

    const newOrderFiles = this.orderFiles.value.filter((orderFile) => {
      const foundMatchingOrder = orderIds.find((orderId) => orderFile.order.orderId === orderId);
      return foundMatchingOrder === undefined;
    });
    this.orderFiles.next(newOrderFiles);
  }

  /**
   * Deletes orderFile and updates orderFiles Observable accordingly.
   */
  public async deleteOrderFile(orderId: string): Promise<void> {
    if (!this.userId || !orderId) {
      return;
    }

    await this.fileSystemService.fileDelete({
      path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}/${orderId}.${orderFileSuffix}`,
    });
  }

  /**
   * Renames existing orderFile and updates it with provided order and checks.
   */
  public async renameAndUpdateOrderFile(options: {
    fromOrderId: string;
    toOrderId: string;
    order: IOrder;
    checks: ICheck[];
  }): Promise<void> {
    const oldOrderFileValue = this.orderFiles.value.find((f) => f.order.orderId === options.fromOrderId);

    if (!oldOrderFileValue) {
      throw new Error(`Cannot find orderFile with id '${options.fromOrderId}'.`);
    }

    try {
      await this.saveToDisk(ToolsService.orderToOrderFile(options.order), true);
    } catch (error) {
      throw new Error(`Cannot create new orderFile.`);
    }

    await this.deleteOrderFiles([options.fromOrderId]);

    const newGraphPath =
      oldOrderFileValue.meta?.graphPath?.map((graphEntry) => {
        // eslint-disable-next-line no-param-reassign
        graphEntry.url = graphEntry.url.replace(options.fromOrderId, options.toOrderId);
        return graphEntry;
      }) || [];
    await this.updateOrderData(
      options.toOrderId,
      { meta: { graphPath: newGraphPath }, checks: options.checks },
      { deepMerge: true, updateGraphPath: true }
    );
    this.updateOrderFileSubject();
  }

  /**
   * Reloads all order files from disk
   */
  public async reloadOrderFiles(): Promise<void> {
    if (!this.userId) {
      return;
    }

    const promises: Promise<{ filePath: string; fileContent: string | Blob }>[] = [];

    try {
      // Get the folder of the orders and the order files in it
      const folder = await this.fileSystemService.readdir({ path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}` });
      const orderFiles = folder.files.filter((fileInfo) => fileInfo.name.endsWith(`.${orderFileSuffix}`));

      orderFiles.forEach((file) => {
        promises.push(this.fileSystemService.fileRead({ path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}/${file}` }));
      });
    } catch (e) {
      Logger.error(`[reloadOrderFiles()] error in getting the order files: ${e}`);
      // If the folder does not exists yet, create it
      await this.fileSystemService.mkdir({ path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}`, recursive: true });
    }

    // Wait until all files are loaded
    const ordersData: { filePath: string; fileContent: string | Blob }[] = await Promise.all(promises);

    await this.parseOrderData(ordersData);
  }

  /**
   * Parses the file content in string format to JSON
   * @param ordersData Data of the orders that should be parsed
   */
  private async parseOrderData(ordersData: { filePath: string; fileContent: string | Blob }[]): Promise<void> {
    const parsedOrdersData: IOrderFile[] = [];

    // Convert the string values into objects
    ordersData.forEach((data) => {
      if (typeof data.fileContent === 'string') {
        try {
          parsedOrdersData.push(JSON.parse(data.fileContent));
        } catch (e) {
          Logger.error('INVALID JSON. File will be deleted');
          this.fileSystemService.fileDelete({ path: data.filePath }).catch();
        }
      } else if (typeof data.fileContent === 'object') {
        parsedOrdersData.push(data.fileContent as unknown as IOrderFile);
      }
    });

    this.orderFiles.next(parsedOrdersData.sort(sortByProp((item) => item.order.orderUpdatedAt)));

    if (this.currentOrderId) {
      this.currentOrderFile.next(await this.getOrderFile(this.currentOrderId));
    }

    // Merge orders from the backend if the merge has not yet taken place
    if (!this.initialDataMergeHappened && this.loadedOrdersToMerge) {
      await this.mergeOrdersFromBackend(this.loadedOrdersToMerge);
    }
    this.isReadySubject.next(true);
  }

  /**
   * Updates the order with the given id
   * @param orderId Id of the order that should be updated
   * @param data Data that should be changed. This data is merged with the existing data, so only the changed values needs to be provided
   * @param options Update options
   */
  public async updateOrderData(
    orderId: string,
    data: DeepPartial<IOrderFile>,
    options: IFileUpdateOptions
  ): Promise<void> {
    let resolveFn: () => void;

    // Create a promise that can be resolved from another function
    const promise = new Promise<void>((resolve) => {
      resolveFn = resolve;
    });

    // noinspection JSUnusedAssignment
    await this.performUpdate({
      promiseResolver: resolveFn,
      orderId,
      options,
      data,
    });

    await promise;
  }

  /**
   * Performs the actual file update/writing
   * @param item Queue item with all necessary information
   */
  private async performUpdate(item: IFileQueueItem): Promise<void> {
    const { promiseResolver, orderId } = item;

    if (!this.userId) {
      Logger.error(`performUpdate, userId not defined`);
      promiseResolver();
      return;
    }

    if (this.currentlyUpdatingFile) {
      // Add the changes to the queue if currently updating the file
      this.updatingFileQueue.push(item);
      return;
    }

    // Block further updates until the file is saved
    this.currentlyUpdatingFile = true;

    const data: IOrderFile = item.data as IOrderFile; // `as IOrderFile` needed because incoming data is only DeepPartial<IOrderFile>
    const options: IFileUpdateOptions = {
      updateLastChangeTimestamp: true,
      ...item.options,
    };

    if (!orderId) {
      this.completeFileUpdate(promiseResolver);
      Logger.error(`ORDER FILE SERVICE ERROR: OrderId is not set`);
      return;
    }

    const currentFileContent: IOrderFile = this.orderFiles.value?.find((file) => file.order.orderId === orderId);

    if (!currentFileContent) {
      Logger.error(`ORDER FILE SERVICE ERROR: No order with the id '${orderId}' found.`);
      this.completeFileUpdate(promiseResolver);
      return;
    }

    let newFileContent: IOrderFile;

    if (options.deepMerge) {
      // Perform a deep merge if it is enabled via options
      newFileContent = deepmerge(currentFileContent, data, {
        // Replace the old array with the new array
        arrayMerge: (target, source) => source,
      });
    } else {
      // Otherwise do a simple merge on the highest level
      newFileContent = {
        ...currentFileContent,
        ...data,
      };
    }

    if (options.updateLastChangeTimestamp && newFileContent?.order) {
      newFileContent.order.updatedAt = DateTime.local().toISO();
    }

    // If the graph path should not be updated, replace it with the current one.
    // This is necessary because it can happen very rarely (e.g. fast e2e tests) that between the
    // request of the update of the order file [`updateOrderData()`] and the actual execution [`performUpdate()`]
    // the GraphPath changes and then a following update would reset the graphPath to an old state.
    if (!options.updateGraphPath) {
      newFileContent.meta.graphPath = currentFileContent.meta.graphPath;
    }

    // Do nothing if the given data do not change anything at all
    if (JSON.stringify(currentFileContent) === JSON.stringify(newFileContent)) {
      this.completeFileUpdate(promiseResolver, true);
      return;
    }

    // Update the last change timestamp of the whole file
    newFileContent = { ...newFileContent, ...{ meta: newFileContent.meta } };
    newFileContent.order.clientMetaData = {
      appVersion: environment.version,
      graphPaths: newFileContent.meta.graphPath,
    };

    try {
      // Write the actual file with the new, merged content
      await this.fileSystemService.fileWrite({
        path: `${BASE_PATH}/${this.userId}/${ORDERS_PATH}/${orderId}.${orderFileSuffix}`,
        data: newFileContent,
      });

      const currentOrderFiles = this.orderFiles.value;
      const index = currentOrderFiles.findIndex((file) => file.order.orderId === orderId);

      if (index === -1) {
        await this.reloadOrderFiles();
        this.completeFileUpdate(promiseResolver);
        Logger.error(`ORDER FILE SERVICE ERROR: File with id '${orderId}' could not be found.`);
        return;
      }

      // Update the file content in the files array
      currentOrderFiles[index] = newFileContent;

      if (!this.isMergeInProgress) {
        this.orderFiles.next(currentOrderFiles);
        this.currentOrderFile.next(newFileContent);
      }

      this.fileSaved.emit(newFileContent);
      this.completeFileUpdate(promiseResolver, true);
    } catch (err) {
      Logger.error(`ERROR: ${JSON.stringify(err)}`);
    }
  }

  /**
   * Completes the file update by changing file update statue, resolving the promise and triggering the file update for the next file in the queue
   * @param promiseResolver Resolve function of the promise of the queue item. Used to complete the async function.
   * @param skipReloadingOrderFiles Whether reloading the order files should be skipped
   */
  private completeFileUpdate(promiseResolver: () => void, skipReloadingOrderFiles: boolean = false): void {
    this.currentlyUpdatingFile = false;
    promiseResolver();

    if (this.updatingFileQueue.length > 0) {
      /** Perform the file update with the next data in the queue */
      this.performUpdate(this.updatingFileQueue.shift()).catch((err) => {
        Logger.error(`error performUpdate: ${err}`);
      });
    } else if (!skipReloadingOrderFiles) {
      this.reloadOrderFiles().catch((err) => {
        Logger.error(`error reloadOrderFiles: ${err}`);
      });
    }
  }

  /**
   * Initially loads all order files
   */
  private loadInitialFiles(): void {
    if (!this.orderFilesLoading) {
      this.orderFilesLoading = true;
      this.reloadOrderFiles()
        .catch()
        .then(() => {
          this.hasLoadedFilesBefore = true;
        });
    }
  }

  /**
   * Returns the order file from the order files array with the matching orderId
   * @param orderId Id of the order that should be returned
   */
  private async getOrderFile(orderId: string): Promise<IOrderFile | undefined> {
    return this.orderFiles$
      .pipe(
        filter((files) => !!files),
        take(1),
        map<IOrderFile[], IOrderFile>((files) => files.find((file) => file.order.orderId === orderId))
      )
      .toPromise();
  }

  public getCheckWithId(checkId: string): ICheck | null {
    return this.currentOrderFile?.value?.checks?.find((check) => check?.checkId === checkId) || null;
  }

  public async updateOrderCheck(orderId: string, checkToUpdate: ICheck): Promise<void> {
    try {
      const existingOrderFile = this.currentOrderStatic;
      const checkIndex = existingOrderFile.checks.findIndex((check) => check?.checkId === checkToUpdate.checkId);
      existingOrderFile.checks[checkIndex] = checkToUpdate;

      await this.updateOrderData(
        existingOrderFile.order.orderId,
        {
          meta: { ...existingOrderFile.meta },
          checks: existingOrderFile.checks,
        },
        { deepMerge: true }
      );
    } catch (e) {
      Logger.error(`error updateOrderCheck(): ${e}`);
    }
  }
}
