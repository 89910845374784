/**
 * ISO-639-1
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export enum ISOLanguageCodes {
  EN = 'en',
  DE = 'de',
  // Greek (el)
  EL = 'el',
}
