import { ICheck, IOrderFile } from '@models';
import { Injectable } from '@angular/core';
import { ISharedGraphPath } from '@shared/models/order/order-meta-data.type';
import { Logger } from '@kerberos-compliance/kerberos-fe-lib';
import { VIDEO_IDENT_URLS } from '@navUrls';
import { GraphPathService } from './graph-path.service';

/**
 * Current Graph path implementation is ridden with erratic behaviour and setup in a way that it's nearly impossible to
 * predict. As such, this class contains proactive methods to fix such issues.
 *
 * This is merely a band-aid on a major issue.
 */
@Injectable()
export class OrderGraphPathResolver {
  constructor(private readonly graphPathService: GraphPathService) {}

  VIDEO_IDENT_STATUS_REGEX = VIDEO_IDENT_URLS.status
    .getJoinedUrl({
      params: { orderId: '(?<orderId>(.*))', checkId: '(?<checkId>(.*))' },
    })
    .replace(/\//g, '\\/');

  /**
   * Return current Order graphPah.
   *
   * **Note:** in case of an erroneous graphPah, method will update/regenerate graphPath (this will cause an update in file object).
   * @param file Local storage Order file.
   */
  resolveGraphPath(file: IOrderFile): ISharedGraphPath[] {
    if (!file.order) {
      return file?.meta?.graphPath;
    }
    const backendGraphPath = file?.order?.clientMetaData?.graphPaths || [];
    const localGraphPath = file?.meta?.graphPath || [];
    const resolvedGraphPath = backendGraphPath.length > localGraphPath.length ? backendGraphPath : localGraphPath;

    if (this.hasErrors(file, resolvedGraphPath)) {
      const newGraphPath = this.graphPathService.generateGraphPath(file) ?? resolvedGraphPath;
      resolvedGraphPath.splice(0, resolvedGraphPath.length, ...newGraphPath);
    }
    return resolvedGraphPath;
  }

  // ===============
  // Implementation
  // ===============
  /**
   * Returns `true` if graphPath has any error.
   *
   * Currently, checks for errors:
   * * Empty/null graphPath.
   * * GraphPath contains any non-existing Check.
   *
   * @param file Current Order file.
   * @param graphPath Current Order graphPath.
   * @private
   */
  private hasErrors(file: IOrderFile, graphPath: ISharedGraphPath[]): boolean {
    if (!graphPath || graphPath.length === 0) {
      Logger.warn('GraphPath is empty.');
      return true;
    }

    return this.hasMissingVideoIdentChecks(file.checks ?? [], this.extractCheckIds(graphPath));
  }

  /**
   * Return `true`  if any `checkId` is not present in `checks`.
   * @param checks Current Order checks.
   * @param checkIds Check ids to verify existence in `checks`.
   * @private
   */
  private hasMissingVideoIdentChecks(checks: ICheck[], checkIds: string[]): boolean {
    if (checks.length === 0 && checkIds.length > 0) {
      return true;
    }
    return checkIds.some((checkId) => !checks.map((check) => check.checkId).includes(checkId));
  }

  /**
   * Extract all checkIds from graphPath for video ident status page.
   * @param graphPath Current Order graphPath.
   * @private
   */
  private extractCheckIds(graphPath: ISharedGraphPath[]): string[] {
    return (
      graphPath
        .map((pathItem) => pathItem.url.match(this.VIDEO_IDENT_STATUS_REGEX))
        .map((match) => match?.groups?.checkId)
        .filter((checkId) => checkId) ?? []
    );
  }
}
