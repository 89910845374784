import { Pipe, PipeTransform } from '@angular/core';

import { IActorAddress } from '@models';

/**
 * Transforms a given address object to readable string
 */
@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  public transform(address: IActorAddress): string {
    if (!address) {
      return '';
    }
    return `${address.additional ? address.additional + ', ' : ''}${address.street} ${address.houseNumber}, ${
      address.zipCode
    } ${address.city}`;
  }
}
